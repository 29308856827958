import React,{useRef,useState,useEffect} from 'react'
import { Formik } from 'formik'
import Swal from 'sweetalert2';
import ErrorIcon from '../../../images/images/error.jpeg';
import axios from 'axios';
import Loading from '../../../components/Loading';
import ImageDropzone from '../../../components/ImageDropzone';
import Checkout from '../../../components/Checkout';
import SearchableSelect from '../../../components/SearchableSelect';
import useApp from '../../../store/useApp';
import _  from 'lodash'
import './PhotoPrintingForm.scss';
import LoadingProgress from '../../../components/LoadingProgress';

const PhotoPrintingForm = () => {
  const {tryCreateToken,getAreaSet,areas,photoPrintingPrices} = useApp();
  const formRef = useRef();
  const [showLoading,setShowLoading] = useState(false);
  const [percentageUploaded, setPercentageUploaded] = useState(0)
  const [price,setPrice] = useState(40);
  const [areaOptions,setAreaOptions] = useState([]);
  const [orderValue,setOrderValue] = useState(0);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const [files4x6,setFiles4x6] = useState([]);
  const [files5x7,setFiles5x7] = useState([]);
  const [filesA5,setFilesA5] = useState([]);
  const [files8x10, setFiles8x10] = useState([]);
  const [filesA4, setFilesA4] = useState([]);
  const [files12x12, setFiles12x12] = useState([]);
  const [filesA3, setFilesA3] = useState([]);
  const [files4x6ValidSize, setFiles4x6ValidSize] = useState(true)
  const [files5x7ValidSize, setFiles5x7ValidSize] = useState(true)
  const [files8x10ValidSize, setFiles8x10ValidSize] = useState(true)
  const [filesA5ValidSize, setFilesA5ValidSize] = useState(true)
  const [filesA4ValidSize, setFilesA4ValidSize] = useState(true);
  const [files12x12ValidSize, setFiles12x12ValidSize] = useState(true);
  const [filesA3ValidSize, setFilesA3ValidSize] = useState(true);

  const initialValues = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    address: '',
    area: '',
    comments: '',
    img4x6: false,
    img5x7: false,
    imgA5: false,
    img8x10: false,
    imgA4: false,
    img12x12: false,
    imgA3: false
  }

  const checkFilesSizeValid = function(){
    let files4x6isValid = true;
    if(files4x6 && files4x6.length){
        files4x6.map((file)=>{
            if(file.size > (10 * 1024 * 1024)){
                files4x6isValid = false
            }
        })
    }
    files4x6isValid ? setFiles4x6ValidSize(true) : setFiles4x6ValidSize(false)

    let files5x7isValid = true;
    if(files5x7 && files5x7.length){
        files5x7.map((file)=>{
            if(file.size > (10 * 1024 * 1024)){
                files5x7isValid = false
            }
        })
    }
    files5x7isValid ? setFiles5x7ValidSize(true) : setFiles5x7ValidSize(false)

    let filesA5isValid = true;
    if(filesA5 && filesA5.length){
        filesA5.map((file)=>{
            if(file.size > (10 * 1024 * 1024)){
                filesA5isValid = false
            }
        })
    }
    filesA5isValid ? setFilesA5ValidSize(true) : setFilesA5ValidSize(false)

    let files8x10isValid = true;
    if(files8x10 && files8x10.length){
        files8x10.map((file)=>{
            if(file.size > (10 * 1024 * 1024)){
                files8x10isValid = false
            }
        })
    }
    files8x10isValid ? setFiles8x10ValidSize(true) : setFiles8x10ValidSize(false)

    let filesA4isValid = true;
    if(filesA4 && filesA4.length){
        filesA4.map((file)=>{
            if(file.size > (10 * 1024 * 1024)){
                filesA4isValid = false
            }
        })
    }
    filesA4isValid ? setFilesA4ValidSize(true) : setFilesA4ValidSize(false)

    let files12x12isValid = true;
    if(files12x12 && files12x12.length){
        files12x12.map((file)=>{
            if(file.size > (10 * 1024 * 1024)){
                files12x12isValid = false
            }
        })
    }
    files12x12isValid ? setFiles12x12ValidSize(true) : setFiles12x12ValidSize(false)
  }

  const validator = function(values){
    const errors = {};
    if(!values.firstname) errors.firstname = 'Required';
    if(!values.lastname) errors.lastname = 'Required';
    if(!values.phone) errors.phone = 'Required';
    if(!values.address) errors.address = 'Required';
    if(!values.area) errors.area = 'Required';
    if (!values.email) {
        errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Invalid email address';
    }
    console.log("Errors",errors)
    return errors;
  }

  const getFormikValues = () => {
    const formikValues = formRef.current?.values
     return formikValues
 }

  function calculatePrice() {
    setTimeout(()=>{
        console.log("calculating price")
        setPrice(0);
        setOrderValue(0);
        let newPrice = 0;
        let newOrderValue = 0;
        let newDeliveryCharges = 0;
        const {area,img4x6,img5x7,imgA5,img8x10,imgA4,img12x12,imgA3} = getFormikValues();
        let areaSet = getAreaSet(area);

        let priceObj = photoPrintingPrices?.filter((item)=>{
            return item.areaset === areaSet
        })
        if(priceObj.length > 0){
            setDeliveryCharges(priceObj[0]?.price);
            newDeliveryCharges = priceObj[0]?.price;
        }else{
            setDeliveryCharges(0)
            newDeliveryCharges = 0
        }

        // if (areaSet === 2) {
        // setDeliveryCharges(5);
        // newDeliveryCharges = 5;
        // } else if (areaSet === 3) {
        // setDeliveryCharges(8);
        // newDeliveryCharges = 8
        // } else {
        // setDeliveryCharges(0);
        // newDeliveryCharges = 0;
        // }

        if (img4x6) {
            let copies = files4x6?.map((item)=>parseInt(item.count))
            let value = newOrderValue + (_.sum(copies) * 6);
            setOrderValue(value)
            newOrderValue = value
        } if (img5x7) {
            let copies = files5x7?.map((item)=>parseInt(item.count))
            let value = newOrderValue + (_.sum(copies)  * 8)
            setOrderValue(value)
            newOrderValue = value
        } if (imgA5) {
            let copies = filesA5?.map((item)=>parseInt(item.count))
            let value = newOrderValue + (_.sum(copies) * 9);
            setOrderValue(value)
            newOrderValue = value
        } if (img8x10) {
            let files8x10Copies = files8x10?.map((item)=>parseInt(item.count))
            let filesA4Copies = filesA4?.map((item)=>parseInt(item.count))
            let value = newOrderValue + (_.sum(files8x10Copies) * 10) + (_.sum(filesA4Copies) * 11);
            setOrderValue(value)
            newOrderValue = value
        } if (imgA4) {
            let copies = filesA4?.map((item)=>parseInt(item.count))
            let value = newOrderValue + (_.sum(copies) * 11)
            setOrderValue(value)
            newOrderValue = value
        } if (img12x12) {
            let copies = files12x12?.map((item)=>parseInt(item.count))
            let value = newOrderValue + (_.sum(copies) * 25);
            setOrderValue(value)
            newOrderValue = value
        } if (imgA3) {
            let copies = filesA3?.map((item)=>parseInt(item.count))
            let value = newOrderValue + (_.sum(copies) * 27)
            setOrderValue(value)
            newOrderValue = value
        }
        
        if (newOrderValue < 40) {
            setOrderValue(40);
            newOrderValue = 40
        }
        setPrice(newOrderValue + newDeliveryCharges);
    },100);
  }

  function onSubmit() {
    const {firstname,lastname,email,phone,address,area,
            comments,img4x6,img5x7,imgA5,img8x10,imgA4,img12x12,imgA3} = getFormikValues();
    if (files4x6.length === 0 && files5x7.length === 0 && filesA4.length === 0 && filesA5.length === 0 && files8x10.length === 0 && files12x12.length === 0 && filesA3.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          text: 'Please upload picture!',
        })
        return;
      }

    setShowLoading(true);
    tryCreateToken().then(async token => {
        if(token === undefined || token === ''){
            console.log("Error while getting token");
            setShowLoading(false);
        }else{
            const formData = new FormData();
            let counts = []
            // 1
            if (img4x6) {
              files4x6.forEach((file) => {
                const extension = file.name.split('.').pop();
                const filename = `${file.name.split('.').slice(0, -1).join('.')}_4x6(${file.count}).${extension}`;
                formData.append('files', file, filename);
                counts.push(file.count)
              });
            }
  
            // 2
            if (img5x7) {
              files5x7.forEach((file) => {
                const extension = file.name.split('.').pop();
                const filename = `${file.name.split('.').slice(0, -1).join('.')}_5x7(${file.count}).${extension}`;
                formData.append('files', file, filename);
                counts.push(file.count)
              });
            }
  
  
            // 3
            if (imgA5) {
              filesA5.forEach((file) => {
                const extension = file.name.split('.').pop();
                const filename = `${file.name.split('.').slice(0, -1).join('.')}_A5(${file.count}).${extension}`;
                formData.append('files', file, filename);
                counts.push(file.count)
              });
            }
  
            // 4
            if (img8x10) {
              files8x10.forEach((file) => {
                const extension = file.name.split('.').pop();
                const filename = `${file.name.split('.').slice(0, -1).join('.')}_8x10(${file.count}).${extension}`;
                formData.append('files', file, filename);
                counts.push(file.count)
              });
            }
  
  
            // 5
            if (imgA4) {
              filesA4.forEach((file) => {
                const extension = file.name.split('.').pop();
                const filename = `${file.name.split('.').slice(0, -1).join('.')}_A4(${file.count}).${extension}`;
                formData.append('files', file, filename);
                counts.push(file.count)
              });
            }
  
            // 6
            if (img12x12) {
              files12x12.forEach((file) => {
                const extension = file.name.split('.').pop();
                const filename = `${file.name.split('.').slice(0, -1).join('.')}_12x12(${file.count}).${extension}`;
                formData.append('files', file, filename);
                counts.push(file.count)
              });
            }
            
            // 7
            if (imgA3) {
              filesA3.forEach((file) => {
                const extension = file.name.split('.').pop();
                const filename = `${file.name.split('.').slice(0, -1).join('.')}_A3(${file.count}).${extension}`;
                formData.append('files', file, filename);
                counts.push(file.count)
              });
            }
            
            formData.append("firstname", firstname);
            formData.append("lastname", lastname);
            formData.append("email", email);
            formData.append("phone", phone);
            formData.append("address", address);
            formData.append("area", area);
            formData.append("comments", comments);
            formData.append("count",JSON.stringify(counts));
  
            formData.append("price", price);
            formData.append("token", token.id);

            try {
                const {data} = await axios({
                    data:formData,
                    method:'post',
                    url: process.env.REACT_APP_API_URL + '/api/createOrder/print',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: progressEvent => {
                      console.log(progressEvent)
                      const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                      setPercentageUploaded(progress)
                    }
                  })
                  const {order_id,customer_id} = data

                  axios.post(process.env.REACT_APP_API_URL + "/api/stripe/print-pay",{
                    order_id,customer_id,price})
                  .then((response) => {
                    if (response.data.status) {
                      setShowLoading(false);
                      Swal.fire("Order Placed!", "Please check Your Email!", "success");
                    } else {
                      setShowLoading(false);
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: response?.data?.message || "Failed to collect your payment.",
                      });
                    }
                  })
                  .catch((err) => {
                    setShowLoading(false);
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: err?.data?.message || "Failed to collect your payment.",
                    });
                  });
            } catch (error) {
                setShowLoading(false);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: error?.data?.message || "Something went wrong while uploading your files. Please make sure files are in proper format and size. If error presist please contact our support.",
                });
            }
        }
    });
  }

  async function setValueCalculatePrice(type,value){
    formRef.current.setFieldValue(type,value)
    await Promise.resolve()
    calculatePrice();
  }

  useEffect(()=>{
    let options = areas.map((area)=>{
        return {value: area.name,label: area.name}
    })
    setAreaOptions(options)
  },[areas])

  useEffect(()=>{
    calculatePrice()
    checkFilesSizeValid()
  },[files4x6,files5x7,filesA5,files8x10,filesA4,files12x12,filesA3])

  return (
    <div className='photo-printing-form--wrapper'>
    {showLoading && <LoadingProgress loaded={percentageUploaded}/>}
    <Formik 
    innerRef={formRef}
    initialValues={initialValues}
    validate={validator}
    onSubmit={(values,errors) => {
        onSubmit();
        // openButton.current.click()
    }}>{({values,errors,touched,isValid,handleSubmit,handleChange,setFieldValue,validateForm})=>(
        <form class="order-form container" onSubmit={(event)=>{event.preventDefault();validateForm().then((errors)=>{
            if(errors && Object.keys(errors).length !== 0){
                Swal.fire({
                    icon: "error",
                    title: "Validation Error",
                    text: "Please fill all fields!",
                  });
                  handleSubmit();
            }else{
                if(!files4x6ValidSize || !files5x7ValidSize || !filesA5ValidSize || !files8x10ValidSize || !filesA4ValidSize || !files12x12ValidSize || !filesA3ValidSize ) return;
                handleSubmit();
            }})}}>
    <div class="contactSection" id='photos'>
        <p class="content"> Please fill the form below and upload the photos you want to be printed and delivered.
            <br/>
            Kindly note that the <b>minimum order value</b> is <b>40 dirhams only</b>.
        </p>
        <div class="formContainer">
            <div class="formControll">
                <label for="firstname">First Name</label>
                <input formControlName="firstname" type='text' value={values.firstname} onChange={(e)=>{handleChange(e)}}
                    placeholder="Please Write Your First Name" name="firstname"
                    id="firstname"/>
                {errors.firstname && touched.firstname && <div
                    class="inputError">
                    <div class="arrow-up"></div>
                    <span>
                        <img class="errorImage" src={ErrorIcon}/>
                    </span> Please enter your first name
                </div>}
            </div>

            <div class="formControll">
                <label for="lastname">Last Name</label>
                <input formControlName="lastname" type='text' value={values.lastname} onChange={(e)=>{handleChange(e)}} placeholder="Please Write Your Last Name" name="lastname"
                    id="lastname"/>
                {errors.lastname && touched.lastname && <div 
                    class="inputError">
                    <div class="arrow-up"></div>
                    <span>
                        <img class="errorImage" src={ErrorIcon}/>
                    </span> Please enter your last name
                </div>}
            </div>

            <div class="formControll">
                <label for="email">Email</label>
                <input formControlName="email" type="email" id="email" value={values.email} onChange={(e)=>{handleChange(e)}} placeholder="Please Write Your Email"
                    name="email"/>
                {errors.email && touched.email && <div class="inputError">
                    <div class="arrow-up"></div>
                    <span>
                        <img class="errorImage" src={ErrorIcon}/>
                    </span> Please enter your valid email address
                </div>}
            </div> 

            <div class="formControll">
                <label for="pnumber">Phone Number</label>
                <input formControlName="phone" type="tel" id="pnumber" placeholder="Please Write Your Phone Number"
                    name="phone" value={values.phone} onChange={(e)=>{handleChange(e)}}/>
                {errors.phone && touched.phone && <div class="inputError">
                    <div class="arrow-up"></div>
                    <span>
                        <img class="errorImage" src={ErrorIcon}/>
                    </span> Please enter your phone number
                </div>}
            </div>
            <div class="formControll">
                <label for="address">Address</label>
                <input formControlName="address" type="text" value={values.address} onChange={(e)=>{handleChange(e)}} id="address"
                    name="address" placeholder="Please Write Your Address"/>
                {errors.address && touched.address && <div  class="inputError">
                    <div class="arrow-up"></div>
                    <span>
                        <img class="errorImage" src={ErrorIcon} />
                    </span> Please enter your address
                </div>}
            </div>
            <div class="formControll">
                <label for="area">Area</label>
                <SearchableSelect value={values.area} type="area" onChangeHandler={setValueCalculatePrice} options={areaOptions} />
                 {errors.area && touched.area && <div class="inputError">
                     <div class="arrow-up"></div>
                     <span>
                         <img class="errorImage" src={ErrorIcon}/>
                     </span> Please select your area
                 </div>}
            </div>
          </div>

          <br />
          <br />
          <div class="formControll">
            <span>
              <h6>
                Please choose the sizes below you would like to upload and print
              </h6>
            </span>
          </div>

          <div class="formControll">
            <div class=" checkbox-container">
                <input checked={values.img4x6} onChange={()=>{setFieldValue('img4x6',!values.img4x6);calculatePrice()}}
                    type="checkbox" name="img-bg"/>
                <label class="margin-left-15" for="img-bg">4’’x6’’ Inches (10cmx15cm) </label><br/>
            </div>
            {values.img4x6 && <><div>
                <label>Upload Photos 4’’x6’’ Inches (10cmx15cm) to be Printed </label>
                <ImageDropzone files={files4x6} setFiles={setFiles4x6} showCounters={true} />
                {!files4x6ValidSize && <div className="inputError">
                    <div className="arrow-up"></div>
                    <span>
                        <img className="errorImage" src={ErrorIcon} />
                    </span> Per file size should not exceed 10MB.
                </div>}
            </div>
            <br/></>}
            <div class=" checkbox-container">
                <input checked={values.img5x7} onChange={()=>{setFieldValue('img5x7',!values.img5x7);calculatePrice()}}
                    type="checkbox" name="img-bg"/>
                <label class="margin-left-15" for="img-bg">5’’x7’’ inches (13cmx18cm)</label><br/>
            </div>
            {values.img5x7 && <><div>
                <label>Upload Photos 5’’x7’’ inches (13cmx18cm) to be Printed </label>
                <ImageDropzone files={files5x7} setFiles={setFiles5x7} showCounters={true} />
                {!files5x7ValidSize && <div className="inputError">
                    <div className="arrow-up"></div>
                    <span>
                        <img className="errorImage" src={ErrorIcon} />
                    </span> Per file size should not exceed 10MB.
                </div>}
            </div>
            <br/></>}
            <div class=" checkbox-container">
                <input checked={values.imgA5} onChange={()=>{setFieldValue('imgA5',!values.imgA5);calculatePrice()}}
                    type="checkbox" name="img-bg"/>
                <label class="margin-left-15" for="img-bg">5.8’’x8.3’’ inches (14.8cmx21cm) - A5 Size</label>
            </div>
            {values.imgA5 && <><div>
                <label>Upload Photos 5.8’’x8.3’’ inches (14.8cmx21cm) - A5 Size to be Printed </label>
                <ImageDropzone files={filesA5} setFiles={setFilesA5} showCounters={true} />
                {!filesA5ValidSize && <div className="inputError">
                    <div className="arrow-up"></div>
                    <span>
                        <img className="errorImage" src={ErrorIcon} />
                    </span> Per file size should not exceed 10MB.
                </div>}
            </div>
            <br/></>}
            <div class=" checkbox-container">
                <input checked={values.img8x10} onChange={()=>{setFieldValue('img8x10',!values.img8x10);calculatePrice()}}
                    type="checkbox" name="img-bg"/>
                <label class="margin-left-15" for="img-bg">8.0’’x10.0’’ inches (20.32cmx25.4cm)</label><br/>
            </div>
            {values.img8x10 &&<> <div>
                <label>Upload Photos 8.0’’x10.0’’ inches (20.32cmx25.4cm) Size to be Printed </label>
                <ImageDropzone files={files8x10} setFiles={setFiles8x10} showCounters={true} />
                {!files8x10ValidSize && <div className="inputError">
                    <div className="arrow-up"></div>
                    <span>
                        <img className="errorImage" src={ErrorIcon} />
                    </span> Per file size should not exceed 10MB.
                </div>}
            </div>
            <br/></>}
            <div class=" checkbox-container">
                <input checked={values.imgA4} onChange={()=>{setFieldValue('imgA4',!values.imgA4);calculatePrice()}}
                    type="checkbox" name="img-bg"/>
                <label class="margin-left-15" for="img-bg">8.3’’x11.7’’ inches (21cmx29.7cm) - A4
                    Size</label><br/>
            </div>
            {values.imgA4 && <><div>
                <label>Upload Photos 8.3’’x11.7’’ inches (21cmx29.7cm) - A4 Size to be Printed </label>
                <ImageDropzone files={filesA4} setFiles={setFilesA4} showCounters={true} />
                {!filesA4ValidSize && <div className="inputError">
                    <div className="arrow-up"></div>
                    <span>
                        <img className="errorImage" src={ErrorIcon} />
                    </span> Per file size should not exceed 10MB.
                </div>}
            </div>
            <br/></>}
            <div class=" checkbox-container">
                <input checked={values.img12x12} onChange={()=>{setFieldValue('img12x12',!values.img12x12);calculatePrice()}}
                    type="checkbox" name="img-bg"/>
                <label class="margin-left-15" for="img-bg">12.0’’x12.0’’ inches (30.48cmx30.48cm)</label><br/>
            </div>
            {values.img12x12 && <><div>
                <label>Upload Photos 12.0’’x12.0’’ inches (30.48cmx30.48cm) Size to be Printed </label>
                <ImageDropzone files={files12x12} setFiles={setFiles12x12} showCounters={true} />
                {!files12x12ValidSize && <div className="inputError">
                    <div className="arrow-up"></div>
                    <span>
                        <img className="errorImage" src={ErrorIcon} />
                    </span> Per file size should not exceed 10MB.
                </div>}
            </div>
            <br/></>}
            <div class=" checkbox-container">
                <input checked={values.imgA3} onChange={()=>{setFieldValue('imgA3',!values.imgA3);calculatePrice()}}
                    type="checkbox" name="img-bg"/>
                <label class="margin-left-15" for="img-bg">11.7’’x16.5’’ inches (29.7cmx42.0cm) - A3
                    Size</label><br/>
            </div>
            {values.imgA3 && <><div>
                <label>Upload Photos 11.7’’x16.5’’ inches (29.7cmx42.0cm) - A3 Size to be Printed </label>
                <ImageDropzone files={filesA3} setFiles={setFilesA3} showCounters={true} />
                {!filesA3ValidSize && <div className="inputError">
                    <div className="arrow-up"></div>
                    <span>
                        <img className="errorImage" src={ErrorIcon} />
                    </span> Per file size should not exceed 10MB.
                </div>}
            </div>
            </>}
          </div>
          <br />


        <br/>
        <div class="formControll">
            <div class="formControll any-comments">
                <label for="people">Any Comments</label>
                <textarea formControlName="comments" type="textarea" value={values.comments} onChange={handleChange} id="comments" name="comments"> </textarea>
            </div>
            <label for="people">Price</label>
            <input readOnly formControlName="price" value={price} type="number" id="people" name="people"/>
            <div class="order-summary">
                {values.img4x6 && <p> 4’’ X 6’’ Photos: {_.sum(files4x6.map(item=>parseInt(item.count)))} Photos x 6 AED = {_.sum(files4x6.map(item=>parseInt(item.count))) * 6} AED
                </p>}
                {values.img5x7 && <p>5’’ X 7’’ Photos: {_.sum(files5x7.map(item=>parseInt(item.count)))} Photos x 8 AED = {_.sum(files5x7.map(item=>parseInt(item.count))) * 8} AED</p>}
                {values.imgA5 && <p> A5 Photos:  {_.sum(filesA5.map(item=>parseInt(item.count)))} Photos x 9 AED = {_.sum(filesA5.map(item=>parseInt(item.count)))  * 9} AED </p>}
                {values.img8x10 && <p> 8’’ X 10’’ Photos: {_.sum(files8x10.map(item=>parseInt(item.count)))} Photos x 10 AED = {_.sum(files8x10.map(item=>parseInt(item.count))) * 10}
                    AED </p>}
                {values.imgA4 && <p> A4 Photos: {_.sum(filesA4.map(item=>parseInt(item.count)))} Photos x 11 AED = {_.sum(filesA4.map(item=>parseInt(item.count))) * 11} AED </p>}
                {values.img12x12 && <p> 12’’ X 12’’ Photos: {_.sum(files12x12.map(item=>parseInt(item.count)))} Photos x 25 AED = {_.sum(files12x12.map(item=>parseInt(item.count))) * 25} AED </p>}
                {values.imgA3 && <p> A3 Photos: {_.sum(filesA3.map(item=>parseInt(item.count)))} Photos x 27 AED = {_.sum(filesA3.map(item=>parseInt(item.count))) * 27} AED </p>}
                <p className='mt-2'> Delivery Charges: {deliveryCharges} AED </p>
            </div>
        </div>
    </div>

        <div id="form-container" class="formControll payment-form">
          {/* <div id="element-container"></div>
        <div id="error-handler" role="alert">{{errorElement.textContent}}</div>
        <div id="success" style=" display: none;;position: relative;float: left;">
            Success! Your token is <span id="token"></span>
        </div> */}
        <Checkout/>
        <div class="submit-button">
            <button type="submit" id="tap-btn">Submit</button>
        </div>
    </div>
    
        </form>
    )}
    </Formik>
    </div>
  );
};

export default PhotoPrintingForm;
