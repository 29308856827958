import BannerImg from '../../images/images/photo-retouching/cover-photo-photo-retouching-min.png';
import ToolImg1 from '../../images/images/photo-retouching/icons/Photoshop-3d-icon.png';
import ToolImg2 from '../../images/images/photo-retouching/icons/Adobe-id-3d-icon.png';
import ToolImg3 from '../../images/images/photo-retouching/icons/Adobe-illustrator.png';
import ToolImg4 from '../../images/images/photo-retouching/icons/Figma-3d-icon.png';
import "./style.scss"
import React, { useRef,useEffect } from 'react';
import { scroll } from '../../utils';
import RetouchingForm from './RetouchingForm';

const Banner = () => {
    
    const scrollToForm = () => {
      const formRef = document.getElementById('retouching-form');
      scroll(formRef);
    }

    useEffect(()=>{
      window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
    },[])
  return (
    // <!-- Photo Retouching Start-->
      <div class="bg-full-right">
        <section class="photo-retouching">
          <div class="fluid">
            <div class="d-md-flex">
              <div class="col-md-6 text-center hero-area-text-retouching" >
                <h2 class="cover-photo-heading">Photo Retouching</h2>
                <p class="cover-photo-content">
                  Upload your images, choose the type of retouch<br />
                  and we’ll edit with great finesse within 24 hours
                </p>
                {/* <button onClick={scrollToForm} class="retouching-btn hero-area-btn retouching-btnn">
                  Go to Form
                </button> */}
              </div>
              <div class="col-md-6">
              
                <img src={BannerImg} alt="BannerImg" />
              </div>
            </div>
          </div>
        </section>
        <section class="services-tittle">
          <div class="container-retouching">
            <h2 class="txt-center">Tools We Use</h2>
          </div>
        </section>
        <section class="tools">
          <div class="container-retouching">
            <div class="tools-img">
              <img src={ToolImg1} alt="ToolImg1" />
              <img src={ToolImg2} alt="ToolImg2" />
              <img src={ToolImg3} alt="ToolImg3" />
              <img src={ToolImg4} alt="ToolImg4" />
            </div>
          </div>
        </section>

        {/* <!-- Photo Retouching End--> */}

        {/* <!-- Retouching Services Start--> */}

        <section class="services-tittle">
          <div class="container-retouching">
            <h2 class="txt-center">Photo Retouching Services We Provide</h2>
          </div>
        </section>
        <section class="retouching-services">
          <div class="container-retouching">
            <div class="row">
              <div class="col-md-6">
                <div class="retouching-sec-left">
                  <h3 class="txt-center">Image Background Removal</h3>
                  {/* <h3 class="txt-center">35 AED Per Picture</h3> */}
                  <p class="txt-center">
                    Image background removal is the process of isolating the subject
                    of a photograph from the rest of the things in the image,
                    resulting in a clean image focused on the subject. Product images
                    with white or other contrasting backgrounds are becoming the
                    industry standard, and several prominent online marketplaces need
                    them. Hence , it's one of Ozelu Studio's most popular photoshop
                    services, and practically every contemporary business, including
                    E-commerce Stores, Online Fashion Businesses, Real Estate
                    Companies, and Post Production Companies, use our image background
                    removal services.
                  </p>
                  <a href="https://wa.me/971545679479"><button class="retouching-btn positioning2">WhatsApp to Book</button></a>
                  {/* <button onClick={scrollToForm} class="retouching-btn positioning">
                    Go to Form
                  </button> */}
                </div>
              </div>
              <div class="col-md-6">
                <div class="retouching-sec-right">
                  <h3 class="txt-center">Clipping Path Service</h3>
                  {/* <h3 class="txt-center">35 AED Per Picture</h3> */}
                  <p class="txt-center">
                    Clipping path service is the first stage in separating a product
                    from a picture. It aids in picture distribution by eliminating
                    undesirable items and backgrounds. It is also used to add or
                    combine graphic information, do color correction, and create a
                    transparent environment. The Photoshop pen tool is widely used to
                    clip images. We have skilled graphic designers who’ll be able to
                    seperate the subject of your choice from almost any image.
                  </p>
                  <a href="https://wa.me/971545679479"><button class="retouching-btn positioning2">WhatsApp to Book</button></a>
                  {/* <button onClick={scrollToForm} class="retouching-btn positioning">
                    Go to Form
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="retouching-services">
          <div class="container-retouching">
            <div class="row">
              <div class="col-md-6">
                <div class="retouching-sec-left">
                  <h3 class="txt-center">Photography Post Production Editing</h3>
                  {/* <h3 class="txt-center">35 AED Per Picture</h3> */}
                  <p class="txt-center">
                    Photography post-production is a critical step in achieving the
                    best possible output from a photograph. Typically, the usual first
                    step in post-processing is to import the raw photos of the
                    shoot into a software used for processing the photos. The
                    photographs are then sharpened with software by post-production
                    professionals who use their expertise to meet the demands of the
                    clients. Ozelu's post-production specialists are always ready to
                    help you with the utmost attention.
                  </p>
                  <a href="https://wa.me/971545679479"><button class="retouching-btn positioning2">WhatsApp to Book</button></a>
                  {/* <button onClick={scrollToForm} class="retouching-btn positioning">
                    Go to Form
                  </button> */}
                </div>
              </div>
              <div class="col-md-6">
                <div class="retouching-sec-right">
                  <h3 class="txt-center">Image Retouching Services</h3>
                  {/* <h3 class="txt-center">35 AED Per Picture</h3> */}
                  <p class="txt-center">
                    Visual attractiveness is the only factor that matters in
                    photography. Color, exposure, and shadow impact are only a few of
                    the variables that affect this aesthetic appeal. By focusing on
                    all the essential visual features, we increase the beauty of
                    photographs with our image retouching services. Restoration
                    procedures are frequently needed for photos that are cracked,
                    torn, or discolored.
                  </p>
                  <a href="https://wa.me/971545679479"><button class="retouching-btn positioning2">WhatsApp to Book</button></a>
                  {/* <button onClick={scrollToForm} class="retouching-btn positioning">
                    Go to Form
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="retouching-services">
          <div class="container-retouching">
            <div class="row">
              <div class="col-md-6">
                <div class="retouching-sec-left">
                  <h3 class="txt-center">Image Masking Services</h3>
                  {/* <h3 class="txt-center">35 AED Per Picture</h3> */}
                  <p class="txt-center">
                    To remove the background of complicated photos to be used in
                    advertisements in e-commerce websites etc, Photoshop image masking
                    is used with clipping path techniques. When clipping path alone is
                    insufficient to Photoshop image masking comes handy, specially
                    when dealing with translucent images involving glasses, smoke,
                    lighting etc.
                  </p>
                  <a href="https://wa.me/971545679479"><button class="retouching-btn positioning2">WhatsApp to Book</button></a>
                  {/* <button onClick={scrollToForm} class="retouching-btn positioning">
                    Go to Form
                  </button> */}
                </div>
              </div>
              <div class="col-md-6">
                <div class="retouching-sec-right">
                  <h3 class="txt-center">Image Restoration Services</h3>
                  {/* <h3 class="txt-center">35 AED Per Picture</h3> */}
                  <p class="txt-center">
                    Digital photographs can be kept in soft copy for as many years as
                    you like but in printed form they will always deteriorate. This is
                    mainly because of the ink and chemicals used to print the
                    photographs that with time loses it's quality . We can repair and
                    renew old, damaged, distorted, and even torn images with the help
                    of our image restoration service using a variety of photo
                    retouching technologies.
                  </p>
                  <a href="https://wa.me/971545679479"><button class="retouching-btn positioning2">WhatsApp to Book</button></a>
                  {/* <button onClick={scrollToForm} class="retouching-btn positioning">
                    Go to Form
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="retouching-services">
          <div class="container-retouching">
            <div class="row">
              <div class="col-md-6">
                <div class="retouching-sec-left">
                  <h3 class="txt-center">Jewelry Photo Editing</h3>
                  {/* <h3 class="txt-center">35 AED Per Picture</h3> */}
                  <p class="txt-center">
                    Retouching jewelry images adds flavor and mostly boosts jewelry
                    product sales because of their pristine look. We provide gold
                    picture editing, diamond and silver photo editing, gems photo
                    retouching, ring & chain photo editing, and many other services
                    with our excellent jewelry image editing service. We clear out the
                    background from images, correct any color problems, remove
                    defects, enhance the glossy look, perform focus stacking, and do
                    other image corrections to get the perfect result.
                  </p>
                  <a href="https://wa.me/971545679479"><button class="retouching-btn positioning2">WhatsApp to Book</button></a>
                  {/* <button onClick={scrollToForm} class="retouching-btn positioning">
                    Go to Form
                  </button> */}
                </div>
              </div>
              <div class="col-md-6">
                <div class="retouching-sec-right">
                  <h3 class="txt-center">Ghost Mannequin Service</h3>
                  {/* <h3 class="txt-center">35 AED Per Picture</h3> */}
                  <p class="txt-center">
                    The Ghost Mannequin or invisible mannequin service is one of the
                    many image altering and photo editing services we provide. Three
                    images are taken from the front, back, and interior viewpoints to
                    carry it out. The final ghostly perspective is then produced by
                    digitally stitching together the inner and exterior images. The
                    majority of the time, it refers to clothing like t-shirts, shirts,
                    dresses etc. and the service is widely used by e-commerce
                    companies and fashion brands that are selling products online.
                  </p>
                  <a href="https://wa.me/971545679479"><button class="retouching-btn positioning2">WhatsApp to Book</button></a>
                  {/* <button onClick={scrollToForm} class="retouching-btn positioning">
                    Go to Form
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="retouching-services">
          <div class="container-retouching">
            <div class="row">
              <div class="col-md-6">
                <div class="retouching-sec-left">
                  <h3 class="txt-center">
                    Photoshop Color Correction & Editing Service
                  </h3>
                  {/* <h3 class="txt-center">35 AED Per Picture</h3> */}
                  <p class="txt-center">
                    Photoshop color correction is one of the most widely used photo
                    editing service, the reason being that color mismatches between an
                    image's subject, background, and props occur frequently. Hence, we
                    fix this by our color correction service and of course, resulting
                    in a much better looking image. This can be used in many
                    photography areas like product photography, fashion photography
                    and probably now a days mostly for online based selling platforms.
                  </p>
                  <a href="https://wa.me/971545679479"><button class="retouching-btn positioning2">WhatsApp to Book</button></a>
                  {/* <button onClick={scrollToForm} class="retouching-btn positioning">
                    Go to Form
                  </button> */}
                </div>
              </div>
              <div class="col-md-6">
                <div class="retouching-sec-right">
                  <h3 class="txt-center">Automotive Photo Editing</h3>
                  {/* <h3 class="txt-center">35 AED Per Picture</h3> */}
                  <p class="txt-center">
                    Automotive photo editing has gained popularity recently because
                    it's proven to catch customers eye and increase leads and
                    therefore sales. We offer a variety of automotive photo retouching
                    services, such as editing photos of cars, buses, motorcycles,
                    choppers, and more. Like any other photo retouching service, we
                    will remove image backgrounds, fix color problems, crop and resize
                    images, remove stains, edit used car photos, and more.
                  </p>
                  <a href="https://wa.me/971545679479"><button class="retouching-btn positioning2">WhatsApp to Book</button></a>
                  {/* <button onClick={scrollToForm} class="retouching-btn positioning">
                    Go to Form
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Retouching Form Start--> */}

        {/* <section class="services-tittle">
          <div class="container">
            <h2 class="txt-center">Fill the Form Below</h2>
          </div>
        </section>
          <RetouchingForm/> */}
      </div>
// <!-- Retouching Form End-->

  )
}




export default Banner