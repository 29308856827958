import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Banner from './Banner'

const WeddingPhotography = () => {
  useEffect(()=>{
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  },[])
  return (
    <div>
      <Helmet>
        <title>Wedding Photography Dubai UAE – Ozelu Studio Photography</title>
        <meta name='description' content='Ozelu Studio have continuously provided the best wedding photographers in Dubai, UAE. Our approach is to capture your candid moments and real emotions, making sure your special day can be felt any time in your life.' />
      </Helmet>
        <Banner/>
    </div>
  )
}

export default WeddingPhotography