import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Banner from './Banner'

const PortraitHeadshots = () => {
  useEffect(()=>{
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  },[])
  return (
    <div>
      <Helmet>
        <title>Portrait CV Photography Home Service Dubai – Ozelu Online Studio</title>
        <meta name='description' content='Ozelu provides stunning portrait photography at your home, that can be used for your CV, website or any other professional or personal needs. Call us to book a slot and have a unique experience from the comfort of your home.' />
      </Helmet>
      <Banner/>
   </div>
  )
}

export default PortraitHeadshots