import React from "react";
import "./style.scss";
import { PassportSizePhotos } from "../../../utils";
import { DefaultPassportSizePhotos } from "../../../utils";
import { useState } from "react";
import { selectStyles } from "../../../components/SearchableSelect";
import Select from "react-select";

const PassportSizesChart = () => {
  const [selectedObject, setSelectedObject] = useState(null);
  const handleChange = (selectedOption) => {
    const selectedValue = selectedOption.value;
    
    const selectedObjects = PassportSizePhotos.filter(
      (item) => item.value === selectedValue
    );
    // console.log(selectedObjects)
    setSelectedObject(selectedObjects);
  };

  return (
    <div className="container margin-bottom" >
      <div className="formControll">
        <h2 className="headingSizeChart playfair-heading-800">
          {" "}
          Passport Photo Size Chart
        </h2>
        <div className="formControll">
          <Select
            options={PassportSizePhotos.map((item) => item.value)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((value) => {
                return { value: value, label: value };
              })}
            onChange={handleChange}
            placeholder="Please Select Your Country"
            className="selectOptionsCustom-chart"
            styles={selectStyles}
          />
        </div>
      </div>

      <div className="photoPrintingSizesChart">
        <table className="photoSize content">
          <tr>
            <th>Country</th>
            <th>Photo Type</th>
            <th>Size in mm</th>
            <th>Background</th>
          </tr>

          {!selectedObject &&
            DefaultPassportSizePhotos.map((item,i) => {
              return (
                <tr key={i}>
                  <td>{item.country}</td>
                  <td>{item.type}</td>
                  <td>{item.dimensions}</td>
                  <td>{item.background}</td>
                </tr>
              );
            })}
          {selectedObject &&
            selectedObject.map((item,i) => {
              return (
                <tr key={i}>
                  <td>{item.country}</td>
                  <td>{item.type}</td>
                  <td>{item.dimensions}</td>
                  <td>{item.background}</td>
                </tr>
              );
            })}
        </table>
      </div>
    </div>
  );
};

export default PassportSizesChart;
