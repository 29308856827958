import React from "react";
import yes from "../../../images/images/logo-designing/yes.png"

const textData = [
  { heading1:"HEIGHT-QUALITY SERVICE",
    text1:
      "We create innovative and appealing logo designs with the goal of providing high- quality services. You would be achieving the maximum design results that your brand deserves to appear as a Brand!",
      icon:<img src={yes} alt="yes-icon" style={{width:"50px"}} />
  },
  { heading1:"WE ARE CREATIVE GENIUSES",
    text1:
      "Valuable service requires time, thought, and creativity. We are exceptionally good at listening to the needs of our clients and coming back to them with most effective, reliable and creative solutions and put every effort into making sure you will be happy with the final results.",
      icon:<img src={yes} alt="yes-icon" style={{width:"50px"}} />
  },
  { heading2:"REASONABLE AND EFFECTIVE",
    text2:
      "We believe that quality does not always have to be expensive and thus have kept affordable rates for our services. Our price is reasonable, we provide services with best price value to our clients.",
      icon:<img src={yes} alt="yes-icon" style={{width:"50px"}} />
  },
  { heading2:"MAKING THE PERFECT LOGO DESIGN",
    text2:
      'Our keen communication and understanding of your requirements combined with our "out of box" designs will give you the perfect logo that showcases your brand in a unique way. We love to brainstorm logo ideas and develop the story behind its design.',
      icon:<img src={yes} alt="yes-icon" style={{width:"50px"}} />
  },
];

const BrandTaxt = () => {
    return (
        <div style={{width:"100%", background:"#dcf3f6", marginTop:"30px"}}>
            <h className="heading-logo-text">WHY HIRE US TO CREATE YOUR BRAND'S LOGO DEIGN?</h>
          <div className="text-data-container">
            {textData.slice(0, 2).map((data, index) => (
              <div key={index} className="column-flex-container">
                <div className="column-flex-contaoner-wrapper">
                <div className="data-icon" >{data.icon}</div>
              
                <span className="text-data-container-font">
                    <h3>{data.heading1}</h3>{data.text1}</span>
                
              </div></div>
            ))}
          </div>
          <div className="text-data-container">
            {textData.slice(2).map((data, index) => (
              <div key={index} className="column-flex-container">
                 <div className="column-flex-contaoner-wrapper">
                <div className="data-icon">{data.icon}</div>
                
               
                <span className="text-data-container-font"> <h3>{data.heading2}</h3>{data.text2}</span>
                
              </div>
                
              </div>
            ))}
          </div>
        </div>
      );
      
};

export default BrandTaxt;
