import React, { useEffect, useState } from 'react'
import { useCart } from 'react-use-cart'
import CheckoutProductCard from './CheckoutProductCard'
import "./styleCheckoutComponent.scss"

const CheckoutProduct = ({classes, deliveryCharges}) => {
  const {items,cartTotal} = useCart()
  const [totalPrice, setTotalPrice] = useState(0)

  useEffect(()=>{
    setTotalPrice(cartTotal + deliveryCharges)
  },[cartTotal,deliveryCharges])
  return (
    <div className={`product-container__checkout ${classes}`}>
        <div className='product-card-container__checkout'>
          {items.map((item)=>{
            return <CheckoutProductCard key={item.id} item={item}/>
          })}
       </div>
       <div className='product-card-container__checkout'>
        <div className='heading-cont__checkout-product'>
            <div><h5>Subtotal</h5></div>
            <div><h5>AED {cartTotal}</h5></div>
        </div>
        <div className='heading-cont__checkout-product'>
            <div><h5>Delivery Charges</h5></div>
            <div><h5>AED {deliveryCharges}</h5></div>
        </div>
        {/* <div className='heading-cont__checkout-product'>
            <div><h5>Shipping</h5>
            <div className='prod-shipping__checkout'>?</div>
            </div>
            <div><h5>AED 320.00</h5></div>
        </div> */}
       </div>
       <div>
       <div className='heading-cont__checkout-product'>
            <div><h5>Total</h5></div>
            <div><h2>AED {totalPrice}</h2></div>
        </div>
       </div>
    </div>
  )
}

export default CheckoutProduct