import React, { useEffect } from 'react'
import AppGuidelineTool from './AppGuidLineTool/AppGuidelineTool'
import AppReviews from './AppReviews/AppReviews'
import Banner from './Banner'
import SameDayDelivery from './SameDayDelivery'
import  AppServices from './Services/AppServices';
import PhotoServices from './Services/PhotoServices';
import EditingServices from './Services/EditingServices';
import { Helmet } from 'react-helmet-async'
import './style.scss'
import TrendingProducts from './E-comereceSection/EcomereceSection'
import EComereceServices from './Services/EComereceServices'
import FeaturedCollections from './FeaturedCollections'

function Home() {
  useEffect(()=>{
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  },[])
  return (
    <>
      <Helmet>
        <title>Ozelu Studio Dubai Providing Traditional Photo Studio Services Online - Free Delivery</title>
        <meta name='description' content='Ozelu studio offers online services like converting photos taken by mobile into passport size photos, printing normal photos or documents, with same day free delivery. Also, an E-Commerce store with amazing frames, art works and other gift products.'></meta>
      </Helmet>
     <Banner/>
     <SameDayDelivery/>
     <AppGuidelineTool/>
     <AppServices/>
    {/* <EComereceServices/> */}
    <FeaturedCollections/>
     <EditingServices/>
     <PhotoServices/>
     <TrendingProducts/>
     <AppReviews/>
    </>
  )
}

export default Home