import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios'


const initialState = {
  isLoading: true,
  stripe: null,
  cardNumber: null,
  templates: [],
  areas: [],
  purposes: [],
  mainMenu: null,
  servicesMenu: null,
  policiesMenu: null,
  passportSizePrices: [],
  photoPrintingPrices: [],
  documentPrintingPrices: [],
  storePrices: [],
  cartIsOpened: false
}

const reducer = (state, action) => {
    
    switch (action.type) {
      case 'SAVE_STRIPE':
        return {...state,stripe:action.payload.stripe}
      case 'SAVE_CARD_NUMBER':
        return {...state,cardNumber:action.payload}
      case 'SET_CART_OPENED':
        return {...state,cartIsOpened: action.payload}
      case "SET_ASSETS":
        let  areas = action.payload?.assets?.areas;
        let purposes = []
        if(action.payload?.assets?.purposes){
            purposes = action.payload?.assets?.purposes.map((purpose)=>{
                let value = purpose.title.split('-').pop()
                return {value , label: value}
            })
        }
        // Remove from localStorage
        if(window.localStorage.getItem('areas')){
            window.localStorage.clear('areas')
        }

        if(JSON.parse(window.sessionStorage.getItem('areas'))){
            areas = JSON.parse(window.sessionStorage.getItem('areas'))
        }else{
            window.sessionStorage.setItem('areas',JSON.stringify(areas))
        }
        return {...state,
            templates: action.payload?.assets?.templates,
            areas: areas,
            purposes: purposes,
            passportSizePrices: action.payload?.assets?.areasPassportSizes,
            photoPrintingPrices: action.payload?.assets?.areasPhotoPrintingDeliveries,
            documentPrintingPrices: action.payload?.assets?.areasDocumentsPrintings,
            storePrices: action.payload?.assets?.areasStores,
            mainMenu: action.payload?.assets?.mainMenu,
            servicesMenu: action.payload?.assets?.servicesMenu,
            policiesMenu: action.payload?.assets?.policiesMenu,
            isLoading: false
        }
      default: {
          return { ...state }
      }
    }
}

const AppContext = createContext({
    ...initialState,
    setCardNumber: () => {},
    getAreaSet: () => {},
    tryCreateToken: () => Promise.resolve(),
    setCartOpened: ()=> {},
    // addStepOne: () => Promise.resolve(),
})

export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const setCardNumber = (value) => {
        dispatch({
            type:"SAVE_CARD_NUMBER",
            payload: value
        })
    }

    const setCartOpened = (value) => {
        dispatch({
            type: 'SET_CART_OPENED',
            payload: value
        })
    }

    const tryCreateToken = async function() {
        const { token, error } = await state.stripe.createToken(state.cardNumber);
        if (error) {
            document.getElementById('error-handler').innerHTML = error.message;
            return undefined;
        } else {
            document.getElementById('error-handler').innerHTML = '';
            // Send the token to your server.
            return token;
        }
    }

    const getAreaSet = function(areaName) {
        var set = 0;
        state.areas.find(obj => {
            if (obj.name == areaName) {
                set = obj.areaset;
                return;
            }
        })
    
        return set;
    }

    const getCollections = async function() {
        try {
            const response = await axios.get("http://localhost:5000/api/collections/all");
            console.log(response.data.collections)
            dispatch({
                type: "SET_COLLECTIONS",
                payload: {collections: response.data.collections}
            })
        } catch (err) {
            console.log("Error",err);
        }
    }
    useEffect(()=>{
        getCollections()
      },[])
    
    useEffect(() => {
         (async () => {
            try {
                if(!window?.Stripe) return;
                let stripeInstance = window.Stripe(process.env.REACT_APP_STRIPE_TEST_TOKEN);
                dispatch({
                    type: 'SAVE_STRIPE',
                    payload: {stripe:stripeInstance},
                })
                const {data} = await axios.get(process.env.REACT_APP_API_URL + '/api/assets');
                dispatch({
                    type: "SET_ASSETS",
                    payload: {assets: data.assets}
                })
                console.log("Assets",data)
                // const {data} = await axios.get("http://localhost:5000/api/collections/all");
                // dispatch({
                // type: "SET_COLLECTIONS",
                //  payload: {collections: data}})
            } catch (err) {
                console.log("Error",err);
            }
        })()
    }, [])

    return (
        <AppContext.Provider
            value={{
                ...state,
                setCardNumber,
                tryCreateToken,
                setCartOpened,
                getAreaSet
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppContext
