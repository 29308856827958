import React, { useContext, useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import slugify from 'slugify'
import {useCart} from 'react-use-cart'
import './ProductCard.scss'
import AppContext from '../../store/AppContext'

const posColors = ['color','colors','colour','colours'];
const posSizes = ['size','sizes']

function ProductCard({sizeD,sizeT,sizeM,product}) {

    const {addItem} = useCart()
    const {setCartOpened} = useContext(AppContext)
    const [sizeClasses, setSizeClasses] = useState(null);
    const [sizes,setSizes] = useState(null)
    const [colors, setColors] = useState(null)

    function handleAddToCart(e){
        e.preventDefault()
        let selectedVariant = product?.variants[0]
        let obj = {}
        if(selectedVariant){
          obj.id = selectedVariant._id;
          obj.title = product.title
          obj.title_option = selectedVariant.title;
          obj.price = selectedVariant.price;
          obj.compare_at_price = selectedVariant.compare_at_price;
          obj.sku = selectedVariant.sku;
          obj.type = 'variant';
          obj.image = selectedVariant.featured_image;
        }else{
          obj.id = product._id
          obj.title = product.title
          obj.title_option = null
          obj.price = product.price
          obj.compare_at_price = product.compare_at_price
          obj.sku = product.sku
          obj.type = 'product'
          obj.image = product?.images[0]
        }
        addItem(obj, 1)
        setCartOpened(true)
      }

    function renderImage(){
        if(product.images[0]){
            let media = product.images[0]
            return <Link className='db' to={'/products/'+product.slug}>
                <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyloadt4sed" 
                    data-ratio={media.ratio} style={{backgroundImage: `url(${media.src})`}}>
                </div>
                </Link>
        }else{
            return <Link class="db" to={"/products/"+product.slug}>
                <div class="pr_lazy_img main-img nt_img_ratio nt_bg_lz lazyloadt4sed" data-id="37168797548764" data-bgset="//cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_180x.png?v=1663078666 180w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_360x.png?v=1663078666 360w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_540x.png?v=1663078666 540w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_720x.png?v=1663078666 720w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_900x.png?v=1663078666 900w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_1080x.png?v=1663078666 1080w" data-parent-fit="width" data-wiis="" 
                    data-ratio="0.6732117812061711" style={{backgroundImage: `url(//cdn.shopify.com/shopifycloud/shopify/assets/no-image-50-3d8cc48bd078edcd544c8d60f929ed2d8800a3fc52e0f602e84b1767e392bfcd_1728x.gif)`}}>
                <picture style={{display: 'none'}}>
                    <source data-srcset="//cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_180x.png?v=1663078666 180w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_360x.png?v=1663078666 360w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_540x.png?v=1663078666 540w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_720x.png?v=1663078666 720w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_900x.png?v=1663078666 900w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_1080x.png?v=1663078666 1080w" sizes="321px" srcset="//cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_180x.png?v=1663078666 180w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_360x.png?v=1663078666 360w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_540x.png?v=1663078666 540w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_720x.png?v=1663078666 720w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_900x.png?v=1663078666 900w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_1080x.png?v=1663078666 1080w"/>
                    <img alt="" class="lazyautosizes lazyloadt4sed" data-sizes="auto" data-ratio="0.6732117812061711" sizes="321px"/>
                </picture>
                </div>
            </Link>
        }
    }

    useEffect(()=>{
        let classes = 'desgin__1 got_offset done nt_pr product pr_list_item pr_animated mt__30 pr_grid_item swatch_style_rounded swatch_list_size_small'
        switch(sizeD){
            case 2:
                classes += ' col-lg-6'
                break
            case 3:
                classes += ' col-lg-4'
                break
            case 4:
                classes += ' col-lg-3'
                break
            case 5:
                classes += ' col-lg-15'
                break
            case 6:
                classes += ' col-lg-2'
                break
        }
        switch(sizeT){
            case 2:
                classes += ' col-md-6'
                break
            case 3:
                classes += ' col-md-4'
                break
            case 4:
                classes += ' col-md-3'
                break
        }
        switch(sizeM){
            case 1:
                classes += ' col-12'
                break
            case 2:
                classes += ' col-6'
                break
        }
        setSizeClasses(classes)
    },[sizeD,sizeM,sizeT])

    useEffect(()=>{
        product.options.map((option)=>{
            if(posColors.includes(option.name.toLowerCase())){
                setColors(option.values)
            }else if(posSizes.includes(option.name.toLowerCase())){
                setSizes(option.values)
            }
        })
    },[product])

  return (
    <div class={sizeClasses+' w-100'}>
        <div class="product-inner pr">
            <div class="product-image pr oh lazyloadt4sed">
                <span class="tc nt_labels pa pe_none cw"></span>
                {renderImage()}
                {/* <div class="nt_add_w ts__03 pa d-none">
                    <a href="/collections/children/products/apologize-in-advance-funny-kids-tshirt" data-no-instant="" data-id="7686034489564" class="wishlistadd cb chp ttip_nt tooltip_right" rel="nofollow">
                        <span class="tt_txt">Add to Wishlist</span><i class="facl facl-heart-o"></i>
                    </a>
                </div> */}
                <div class="hover_button op__0 tc pa flex column ts__03 des_btns_pr_1 has_sizelistt4_true has_2btnst4_pr">
                    {/* <a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt tooltip_top_left" href="/collections/children/products/apologize-in-advance-funny-kids-tshirt" data-id="7686034489564" rel="nofollow">
                        <span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span>
                    </a> */}
                    <a href="#" onClick={handleAddToCart} class="pr pr_atc cd br__40 bgw tc dib js__qs cb chp ttip_nt tooltip_top_left">
                        <span class="tt_txt">Add To Cart</span><i class="iccl iccl-cart"></i><span>Add To Cart</span>
                    </a>
                </div>
                {sizes && <div class="product-attr pa ts__03 cw op__0 tc pe_none">
                    <p class="truncate mg__0 w__100">
                        {sizes.map((size,i)=>{
                            if(i == 0) return size
                            return ', '+size
                        })}
                    </p>
                </div>}
            </div>
            <div class="product-info">
                <div class="product-info__inner">
                    <h3 class="product-title pr fs__14 mg__0 fwm">
                        <Link className="cd chp" to={`/products/${product.slug}`}>{product.title}</Link>
                    </h3>
                    {(product.compare_at_price > product.price) ? (
                        <span class="price dib mb__5">
                            <del>AED {product.compare_at_price}</del>
                            <ins>AED {product.price}</ins>
                        </span>
                    ) : (
                        <span class="price dib mb__5">AED {product.price}</span>
                    )}

                    <div class="rte dn">
                    <p class="mb__5">"It’s never too early to start mastering the art of apologizing! Start them young with this clever Tshirt." Additional Information: Our Punny Tshirts are made of 100% Premium Soft Cotton...</p>
                    </div>
                    <div class="shopify-product-reviews-badge star-rating" data-id="7686034489564"></div>
                    {colors &&
                    <div class="swatch__list_js swatch__list lh__1 nt_swatches_on_grid lazyloadt4sed">
                        {colors.map((color,i)=>{
                            return <span key={i} class="swatch__list--item pr ttip_nt tooltip_top_right">
                            <span class="tt_txt">{color}</span>
                            <span class={`swatch__value bg_color_${slugify(color.toLowerCase())} lazyloadt4sed`}></span>
                        </span>
                        })}
                    </div>}
                </div>
                <div class="product-info__btns flex column mt__20"><a class="pr nt_add_qv js_add_qv cd br__40 pl__25 pr__25 bgw tc dib ttip_nt_" href="/collections/children/products/apologize-in-advance-funny-kids-tshirt" data-id="7686034489564" rel="nofollow"><span class="tt_txt">Quick view</span><i class="iccl iccl-eye"></i><span>Quick view</span></a><a href="/collections/children/products/apologize-in-advance-funny-kids-tshirt" data-id="7686034489564" class="pr pr_atc cd br__40 bgw tc dib js__qs cb chp ttip_nt_" rel="nofollow"><span class="tt_txt">Quick Shop</span><i class="iccl iccl-cart"></i><span>Quick Shop</span></a></div>
            </div>
        </div>
    </div>
  )
}


export default ProductCard
