import React, { useEffect } from 'react'
import Select from 'react-select'

export const selectStyles = {
    control: (styles) => ({ ...styles, backgroundColor: '#ededed',border:'none',boxShadow:'none' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: "#ededed",
        color: 'inherit',
        cursor: 'default',
        fontSize: '16px',
        fontWeight: '400',
        paddingTop:'0',
        paddingBottom: '0',
        ':hover': {
            backgroundColor: '#777771',
            color: '#ffffff'
        }
      };
    },
    // input: (styles) => ({ ...styles, backgroundColor:'yellow' }),
    // placeholder: (styles) => ({ ...styles, backgroundColor: 'green' }),
    // singleValue: (styles, { data }) => ({ ...styles, backgroundColor: 'blue' }),
  };

function SearchableSelect({options,value,onChangeHandler,type,placeholder='Select',controlBg='#ededed',optionBg='#ededed'}) {

  const selectStylesCustom = {
    control: (styles) => ({ ...styles, backgroundColor: controlBg,border:'none',boxShadow:'none' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: optionBg,
        color: 'inherit',
        cursor: 'default',
        fontSize: '16px',
        fontWeight: '400',
        paddingTop:'0',
        paddingBottom: '0',
        ':hover': {
            backgroundColor: '#777771',
            color: '#ffffff'
        }
      };
    },
    // input: (styles) => ({ ...styles, backgroundColor:'yellow' }),
    // placeholder: (styles) => ({ ...styles, backgroundColor: 'green' }),
    // singleValue: (styles, { data }) => ({ ...styles, backgroundColor: 'blue' }),
  };

  useEffect(()=>{
    console.log("options",options)
  },[])

  return (
    <Select
        className="selectOptionsCustom"
        defaultValue={options[0]}
        isSearchable={true}
        name="area"
        placeholder={placeholder}
        styles={selectStylesCustom}
        options={options}
        value={options.filter((item)=>item.value === value)}
        onChange={(e)=>{onChangeHandler(type,e.value)}}
    />
  )
}

export default SearchableSelect