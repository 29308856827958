import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Banner from './Banner'

const DacumentPrinting = () => {
  useEffect(()=>{
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  },[])
  return (
    <div>
        <Helmet>
          <title>Documents Printing Same Day Free Delivery Dubai – Online Upload</title>
          <meta name='description' content='With Ozelu, upload your documents you want to print in our online form and have it delivered to you within the same day. Online payment and free delivery, printing A4 size. Easy, quick and hassle free.' />
        </Helmet>
        <Banner/>
    </div>

  )
}

export default DacumentPrinting