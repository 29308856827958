import React from "react";

const CardSection = () => {
  return (
    <div className="card-main-container">
      <h className="heading-logo-text">OUR BASIC PACKAGES</h>
      <div className="card-main-container">
      <div className="card-container-logo">
        <div
          class="card text-white  mb-3 card-element-logo card-one-margin"
          style={{ maxwidth: "18rem",  }}
        >
          <div class="card-header">STANDARD PACKAGE</div>
          <div class="card-body-logo">
            <h5 class="card-title-logo">AED 600</h5>
            <p class="card-text-logo">
              <ul>
                <li>
                  4 initial logo concepts will be provided only (no further new
                  concepts will be provided in case of disapproval)
                </li>
                <li>
                  UNLIMITED revision/changes will be provided to the winning
                  logo concept.
                </li>
                <li>100% high quality work.</li>
                <li>
                  Editable files where colors, text and shapes can be updated if
                  you want
                </li>
                <li>FINAL FILES includes AI, ESP, PDF, JPG,PSD, PNG.</li>
                <li>You will own 100% copyright of the design</li>
              </ul>
            </p>
          </div>
          <div className="footer-email-button">
          <div class="what-center">
                <a href="https://wa.me/971545679479"><button className="button-style-logo">WhatsApp</button></a>
                
                </div>
                <div class="what-center">
                <a href="https://wa.me/971545679479"><button className="button-style-logo">Email</button></a>
                
                </div>
                </div>
        </div>
        <div
          class="card text-white  mb-3 card-element-logo"
          style={{ maxwidth: "18rem" }}
        >
          <div class="card-header">PREMIUM PACKAGE</div>
          <div class="card-body-logo">
            <h5 class="card-title-logo">AED 1000</h5>
            <p class="card-text-logo">
              <ul>
                <li>
                6-7 initial logo concepts will be provided only (no further new concepts will be provided in case of disapproval)
                </li>
                <li>
                UNLIMITED revision/changes will be provided to the winning logo concept.

                </li>
                <li>100% high quality work.</li>
                <li>
                  Editable files where colors, text and shapes can be updated if
                  you want
                </li>
                <li>FINAL FILES includes AI, ESP, PDF, JPG,PSD, PNG.</li>
                <li>You will own 100% copyright of the design</li>
              </ul>
            </p>
          </div>
          <div className="footer-email-button">
          <div class="what-center">
                <a href="https://wa.me/971545679479"><button className="button-style-logo">WhatsApp</button></a>
                
                </div>
                <div class="what-center">
                <a href="https://wa.me/971545679479"><button className="button-style-logo">Email</button></a>
                
                </div>
                </div>
        </div>
        
      </div>
      </div>
    </div>
  );
};

export default CardSection;
