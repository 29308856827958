import React from 'react'
import { useNavigate } from 'react-router-dom';
import SameDayImg from '../../images/images/home-page/same-day-delivery.png'

function SameDayDelivery() {
  const navigate = useNavigate();
  return (
    <div className="delivery">
        <div className="ourServiceSection container">
            <div className="serviceSectionLeftContent">
                <h2 className="head">What is Passport Size Photos Delivery service?</h2>
                <br/>
                <div className="content">
                    <p>
                    Ozelu provides an online service that simplifies the process of taking passport size photographs for new passports or renewals, visa applications and other official documents.</p>

                    <p>Forget about visiting a photo studio to get your photograph clicked by using our online service for passport size photos. If you have a phone with a camera of just 10 megapixels or more, click a picture of yourself, our team of photoshop experts will edit it, print and deliver it to a location of your choice. We guarantee that if you follow our guidelines, the picture will be accepted by the authority you are applying to, with a money back guarantee in the rare case of a rejection.</p>
                </div>
                <div>
                    <button onClick={(e)=>navigate('passport-size-photos')} className="orderButton heading-h3">Place Your Order</button>
                </div>
            </div>
            <div className="serviceSectionRightContent">
                <img className="sameDayDeliveryImg" src={SameDayImg} alt=""/>
            </div>
            {/* <!-- <div className="orderPlayButton">

            </div> --> */}
        </div>
    </div>
  )
}

export default SameDayDelivery