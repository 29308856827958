import React from 'react'
import BannerImg from '../../images/images/home-page/cover-photo-home-page.png'
import "./styles.scss"

function Banner() {
  return (
    
        <section className="banner">
            <div className='row'>
            <div className="col-md-6 pl-12 header-text-container">
                <div className="hero-area-text">
                    <div>
                        <h2 className="cover-photo-heading">You Request <br/>We Deliver</h2>
                    </div>
                    <div className="paragraph">
                        <p className="description cover-photo-content">
                            Ozelu focuses on providing traditional photo studio
                            services
                            online,
                            while having a creative touch and
                            being customer centric.
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-md-6 img-div" >
                <img src={BannerImg} alt="img" />
            </div>
            </div>
        </section>
    
  )
}

export default Banner