import React, { useRef, useEffect } from 'react'
import Drift from 'drift-zoom'
import 'drift-zoom/src/css/drift-basic.css'

function ImageGallery({medias}) {
    const carousel = useRef(null);
    const navCarousel = useRef(null);

    async function initFlickity() {
        if (typeof window.Flickity !== 'undefined' && carousel.current && navCarousel.current) {
            const main = new window.Flickity(carousel.current, {
                initialIndex: ".media_id_"+medias[0]._id,
                fade:false,
                draggable:">1",
                cellSelector: ".p-item:not(.is_varhide)",
                cellAlign: "center",
                wrapAround: true,
                autoPlay: false,
                prevNextButtons:true,
                adaptiveHeight: true,
                imagesLoaded: false, 
                lazyLoad: 0,
                dragThreshold : 6,
                pageDots: false,
                rightToLeft: false
  
            })
            
            new window.Flickity(navCarousel.current, {
                initialIndex: ".media_id_"+medias[0]._id,
                cellSelector: ".n-item:not(.is_varhide)",
                cellAlign: "left",
                asNavFor: ".p-thumb",
                wrapAround: true,
                draggable: ">1",
                autoPlay: 0,
                prevNextButtons: 0,
                percentPosition: 1,
                imagesLoaded: 0,
                pageDots: 0,
                groupCells: true,
                rightToLeft: false,
                contain:  1,
                freeScroll: 0
            })
        }
    }

    useEffect(() => {
        if(!medias?.length) return;
        initFlickity();
    }, [medias]);

    useEffect(()=>{
        if(!medias?.length) return;
        setTimeout(()=>{
            const paneContainer = document.querySelector('.dt_img_zoom');
            const demoTrigger = document.querySelectorAll('.img_ptw');
              [...demoTrigger].forEach((img)=>{
                new Drift(img, {
                    showWhitespaceAtEdges: false,
                    paneContainer: paneContainer,
                    inlinePane: false,
                    containInline: true,
                    hoverBoundingBox: true,
                    zoomFactor: 3,
                    inlineOffsetX:0,
                    inlineOffsetY:  0,
                    
                });
              })
        },2000)
    },[medias])

    if(medias?.length === 0){
        return    <>
        <div class="row theiaStickySidebar">
        <div class="col-12 col-lg col_thumb   rf--slider">
            {/* <img id="image-id" src={medias[0].src} data-width={medias[0].width} data-height={medias[0].height} loading="lazy" alt="I do what I want Rebel Cat - Funny Phone Cover"/> */}
            <div  class="p-thumb fade_flick_1 p-thumb_ppr images sp-pr-gallery equal_nt nt_contain ratio_imgtrue position_2 nt_slider pr_carousel flickity-enabled is-draggable">
                <div class={`img_ptw p_ptw js-sl-item p-item sp-pr-gallery__img w__100 nt_bg_lz lazyloadt4sed is-selected`} 
                    style={{position:'absolute',paddingTop: 60+'%', backgroundImage: `url(//cdn.shopify.com/shopifycloud/shopify/assets/no-image-50-3d8cc48bd078edcd544c8d60f929ed2d8800a3fc52e0f602e84b1767e392bfcd_1728x.gif)`}}>
                    <noscript><img src="//cdn.shopify.com/s/files/1/0613/5758/8700/products/Phone-Covers-Coffee-spill-Rebel-Cat-Mockup-1.png?v=1654378902" loading="lazy" alt="I do what I want Rebel Cat - Funny Phone Cover"/></noscript>
                </div>
            </div>
            <span class="tc nt_labels pa pe_none cw"></span>
            <div class="p_group_btns pa flex"><button class="br__40 tc flex al_center fl_center bghp_ show_btn_pr_gallery ttip_nt tooltip_top_left"><i class="las la-expand-arrows-alt"></i><span class="tt_txt">Click to enlarge</span></button></div>
        </div>
        </div>
        </>
    }

  return (
    <>
    <div class="row theiaStickySidebar">
    <div class="col-12 col-lg col_thumb   rf--slider">
        {/* <img id="image-id" src={medias[0].src} data-width={medias[0].width} data-height={medias[0].height} loading="lazy" alt="I do what I want Rebel Cat - Funny Phone Cover"/> */}
        <div ref={carousel} class="p-thumb fade_flick_1 p-thumb_ppr images sp-pr-gallery equal_nt nt_contain ratio_imgtrue position_2 nt_slider pr_carousel flickity-enabled is-draggable">
                {medias.map((media)=>{
                    // return  <img id={media._id} class={`img_ptw p_ptw js-sl-item p-item sp-pr-gallery__img w__100 nt_bg_lz media_id_${media._id} lazyloadt4sed is-selected`} data-mdid={media._id} 
                    // src={media.src} data-zoom={media.src} data-src={media.src} data-width={media.width} data-height={media.height} />
                    return  <div key={media._id} id={media._id} class={`img_ptw p_ptw js-sl-item p-item sp-pr-gallery__img w__100 nt_bg_lz media_id_${media._id} lazyloadt4sed is-selected`} data-mdid={media._id} 
                    style={{position:'absolute',paddingTop: (1/media.aspect_ratio)*100+'%', backgroundImage: `url(${media.src})`}} data-zoom={media.src} data-src={media.src} data-width={media.width} data-height={media.height}>
                        <noscript><img src="//cdn.shopify.com/s/files/1/0613/5758/8700/products/Phone-Covers-Coffee-spill-Rebel-Cat-Mockup-1.png?v=1654378902" loading="lazy" alt="I do what I want Rebel Cat - Funny Phone Cover"/></noscript>
                    </div>
                })}
        </div>
        <span class="tc nt_labels pa pe_none cw"></span>
        <div class="p_group_btns pa flex"><button class="br__40 tc flex al_center fl_center bghp_ show_btn_pr_gallery ttip_nt tooltip_top_left"><i class="las la-expand-arrows-alt"></i><span class="tt_txt">Click to enlarge</span></button></div>
    </div>
    <div class="dt_img_zoom pa t__0 r__0 dib"></div>
    <div class="col-12 col-lg-auto col_nav nav_medium">
        <div ref={navCarousel} class="p-nav ratio_imgtrue row equal_nt nt_cover position_8 nt_slider pr_carousel flickity-enabled is-draggable p-nav-ready">
            {medias.map((media)=>{
                return <div key={media._id} class="col-lg-12 col-3 n-item js-sl-item is-nav-selected">
                    <span class="nt_bg_lz lazyloadt4sed" style={{paddingTop: (1/media.aspect_ratio)*100+'%', backgroundImage: `url(${media.src})`}}>
                        <i class="hide"></i>
                    </span>
                </div>
            })}
        </div>
        <button type="button" aria-label="Previous" class="btn_pnav_prev pe_none"><i class="las la-angle-up"></i></button><button type="button" aria-label="Next" class="btn_pnav_next pe_none"><i class="las la-angle-down"></i></button>
    </div>
    </div>
    </>
  )
}

export default ImageGallery