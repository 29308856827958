import React from "react";
import "./stylePhotoServices.scss";
import headShot from "../../../images/images/portrait-headhots/portrait-headshots.jpg";
import eventShot from "../../../images/images/photography-Services/event-photography.jpg";
import weddingShot from "../../../images/images/photography-Services/wedding-photography.jpg";
import { Link } from "react-router-dom";

const PhotoServices = () => {
  return (
    <div className="photography-services--wrapper">
      <section className="services-tittle">
        <div className="container">
          <h2 className="txt-center heading-h2">Photography Services</h2>
        </div>
      </section>
      <section className="photography-services">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="services bg-blue services-img ">
                <Link
                  to="/photography-service/portrait-headshots"
                  className="text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <img src={headShot} alt="headShot" />

                  <h3 className="heading-h3">Portrait Headshots</h3>

                  <p className="content">
                    Corporate Headshots, Couple Photos,
                    <br /> Website Banners,
                    <br /> Graduation Shots and
                    <br /> other Portrait Headshots
                  </p>
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="services bg-green services-img">
                <Link
                  to="/photography-service/event-photography"
                  className="text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <img src={eventShot} alt="eventShot" />
                  <h3 className="heading-h3">Event Photography</h3>
                  <p className="content">
                    Corporate Events, Private Events,
                    <br /> Birthday Parties,
                    <br /> Gender Reveal and
                    <br />
                    other type of Events
                  </p>{" "}
                </Link>
              </div>
            </div>
            <div className="col-md-4">
              <div className="services bg-purple services-img">
                <Link
                  to="/photography-service/wedding-photography"
                  className="text-decoration-none"
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <img src={weddingShot} alt="weddingShot" />
                  <h3 className="heading-h3">Wedding Photography</h3>
                  <p className="content">
                    We do consultation before the event,
                    <br /> edit photos, option to print
                    <br /> and create a photo album
                  </p>{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PhotoServices;
