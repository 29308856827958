import React, { useContext, useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import './CollectionCard.scss'

function CollectionCard({collection}) {


    function renderImage(){
        if(collection?.featured_image){
            let media = collection?.featured_image
            return <div className="pr_lazy_img main-img nt_img_ratio lazyloadt4sed" 
                    data-ratio={media?.ratio} style={{backgroundImage: `url(${media?.src})`}}>
                    
                    {/* <img src={media.src} alt={media.alt} /> */}
                </div>
        }else{
            return <div className="pr_lazy_img main-img nt_img_ratio lazyloadt4sed" data-id="37168797548764" data-bgset="//cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_180x.png?v=1663078666 180w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_360x.png?v=1663078666 360w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_540x.png?v=1663078666 540w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_720x.png?v=1663078666 720w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_900x.png?v=1663078666 900w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_1080x.png?v=1663078666 1080w" data-parent-fit="width" data-wiis="" 
                    data-ratio="0.6732117812061711" style={{backgroundImage: `url(//cdn.shopify.com/shopifycloud/shopify/assets/no-image-50-3d8cc48bd078edcd544c8d60f929ed2d8800a3fc52e0f602e84b1767e392bfcd_1728x.gif)`}}>
                <picture style={{display: 'none'}}>
                    <source data-srcset="//cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_180x.png?v=1663078666 180w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_360x.png?v=1663078666 360w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_540x.png?v=1663078666 540w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_720x.png?v=1663078666 720w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_900x.png?v=1663078666 900w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_1080x.png?v=1663078666 1080w" sizes="321px" srcset="//cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_180x.png?v=1663078666 180w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_360x.png?v=1663078666 360w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_540x.png?v=1663078666 540w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_720x.png?v=1663078666 720w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_900x.png?v=1663078666 900w, //cdn.shopify.com/s/files/1/0613/5758/8700/products/KidsTshirts-ApologizeinAdvanceMockup1_1080x.png?v=1663078666 1080w"/>
                    <img alt="" className="lazyautosizes lazyloadt4sed" data-sizes="auto" data-ratio="0.6732117812061711" sizes="321px"/>
                </picture>
                </div>
        }
    }


  return (
    <div className={' w-100'}>
        <div className="collection-inner pr">
            <div className="collection-image pr oh lazyloadt4sed testing-card">
                <span className="tc nt_labels pa pe_none cw"></span>
                <Link className="db" to={"/collections/"+collection.slug}>
                    {/* <div className='bg-circle'></div> */}
                    {renderImage()}
                </Link>
            </div>
            <div className="collection-info">
                <div className="collection-info__inner">
                    <h3 className="collection-title pr fs__14 mg__0 fwm">
                        <Link className="cd chp" to={"/collections/"+collection.slug}>
                            {collection.title}
                        </Link>
                    </h3>
                </div>
            </div>
        </div>
    </div>
  )
}


export default CollectionCard
