import React, { useEffect, useRef, useState } from 'react'
import "./style.scss"
import { Formik } from 'formik'
import Swal from 'sweetalert2';
import ErrorIcon from '../../../images/images/error.jpeg';
// import { purposes } from '../../../utils';
import useApp from '../../../store/useApp';
import axios from 'axios';
import Loading from '../../../components/Loading';
import SearchableSelect from '../../../components/SearchableSelect';

import visaCard from "../../../images/images/visa-icon.png"
import masterCard from "../../../images/images/master-card-icon.png"

import ImageDropzone from '../../../components/ImageDropzone';

const OrderForm = () => {
    const {areas,purposes,passportSizePrices,getAreaSet} = useApp()
    const [stripe,setStripe] = useState(null);
    const [cardNumber,setCardNumber] = useState(null);
    const [areaOptions,setAreaOptions] = useState([]);
    const formRef = useRef();
    const [dummyImages, setDummyImages] = useState([]);
    const [showLoading,setShowLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [filesValid,setFilesValid] = useState(true);
    const [filesValidSize, setFilesValidSize] = useState(true)
    const [price,setPrice] = useState(0)

    const openButton = useRef();
    const closeButton = useRef();
    const initialValues = {
        service: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        address: "",
        area: "",
        numberOfPeople: "",
        perPersonPhotos: "",
        purpose: "",
        price: "0",
        comments: "",
        size: "",
        bgColor: "",
    }

    const checkFilesValid = function(){
        let isValid = true;
        if(files && files.length){
            files.map((file)=>{
                console.log("Size",file.size)
                if(file.size > (10 * 1024 * 1024)){
                    isValid = false
                }
            })
        }
        isValid ? setFilesValidSize(true) : setFilesValidSize(false)
    }

    const validator = function(values){
        const errors = {};
        if(!values.service) errors.service = 'Required';
        if(!values.firstname) errors.firstname = 'Required';
        if(!values.lastname) errors.lastname = 'Required';
        if(!values.phone) errors.phone = 'Required';
        if (values.service != "Soft Copy By Email") {
            if(!values.area) errors.area = 'Required';
            if(!values.perPersonPhotos) errors.perPersonPhotos = 'Required';
            if (values.purpose === "Other") {
              if(!values.size) errors.size = 'Required';
              if(!values.bgColor) errors.bgColor = 'Required';
            }
        }
        if(!values.address) errors.address = 'Required';
        if(!values.numberOfPeople) errors.numberOfPeople = 'Required';
        if(!values.purpose) errors.purpose = 'Required';
        if (!values.email) {
            errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
        }
        return errors;
    }

    const getFormikValues = () => {
        const formikValues = formRef.current?.values
         return formikValues
     }

    function onChangeService(e) {
        if (e.currentTarget.value === "Soft Copy By Email") {
        //   setPrice(40);
        calculatePrice();
        } else {    
          calculatePrice();
        }
        // this.orderForm.patchValue({ price: this.price });
    }

    function calculatePrice() {
        setTimeout(()=>{
        const {service,perPersonPhotos,area,numberOfPeople} = getFormikValues();
            let innerPrice = 0;
            if (service != "Soft Copy By Email") {
                    setPrice(0);
                    innerPrice = 0
                //   const area = this.orderForm.get("area")?.value;
                //   let areaSet: number = this.formHelper.getAreaSet(area);
                let areaSet = getAreaSet(area);
                let priceObj = passportSizePrices?.filter((item)=>{
                    return item.areaset === areaSet && item.perPersonPhotos == perPersonPhotos
                })
                if(priceObj.length > 0){
                    setPrice(priceObj[0]?.price)
                    innerPrice = priceObj[0]?.price
                }else{
                    setPrice(0)
                    innerPrice = 0
                }
                // if (perPersonPhotos == 6 && areaSet === 1) {
                //     setPrice(45);
                //     innerPrice = 45
                // } else if (perPersonPhotos == 8 && areaSet === 1) {
                //     setPrice(50);
                //     innerPrice = 50
                // } else if (perPersonPhotos == 12 && areaSet === 1) {
                //     setPrice(60);
                //     innerPrice = 60
                // }
                // area set 2
                // if (perPersonPhotos == 6 && areaSet === 2) {
                //     setPrice(50);
                //     innerPrice = 50
                // } else if (perPersonPhotos == 8 && areaSet === 2) {
                //     setPrice(55);
                //     innerPrice = 55
                // } else if (perPersonPhotos == 12 && areaSet === 2) {
                //     setPrice(65);
                //     innerPrice = 65
                // }
                
                // area set 3
                // if (perPersonPhotos == 6 && areaSet === 3) {
                //     setPrice(55);
                //     innerPrice = 55
                // } else if (perPersonPhotos == 8 && areaSet === 3) {
                //     setPrice(60);
                //     innerPrice = 60
                // } else if (perPersonPhotos == 12 && areaSet === 3) {
                //     setPrice(70);
                //     innerPrice = 70
                // }
            
                setPrice(Number(numberOfPeople || 1)*innerPrice)
                //   this.orderForm.patchValue({ price: this.price });
            } else {
                setPrice(Number(numberOfPeople || 1) * 40);
            //   this.orderForm.patchValue({ price: this.price });
            }
        },100)
    }

    async function setValueCalculatePrice(type,value){
        formRef.current.setFieldValue(type,value)
        await Promise.resolve()
        calculatePrice();
    }

    function onConfirm() {
        // console.log(this.orderForm.value);
        closeButton.current.click();
    
        setShowLoading(true);

        tryCreateToken().then(token => {
            //console.log("Log From then Fucntion: ", token);
            const {service,area,purpose,perPersonPhotos,numberOfPeople,size,bgColor,firstname,lastname,email,phone,address,comments} = getFormikValues();
            if(token.status) {
                const formData = new FormData();
                files.forEach((file) => {
                  formData.append("files", file);
                });
      
                if (service != "Soft Copy By Email") {
                  formData.append("area", area);
                  formData.append("perPersonPhotos",perPersonPhotos);
                  if (purpose === "Other") {
                    formData.append("size", size);
                    formData.append("bgColor", bgColor);
                  }
                }
                formData.append("service", service);
                formData.append("firstname", firstname);
                formData.append("lastname", lastname);
                formData.append("email", email);
                formData.append("phone", phone);
                formData.append("address", address);
                formData.append("numberOfPeople",numberOfPeople);
                formData.append("comments", comments);
                formData.append("price", price);
                formData.append("purpose", purpose);
      
                formData.append("token", token.token.id);

                axios({
                    data:formData,
                    method:'post',
                    url: process.env.REACT_APP_API_URL + '/api/stripe/pay',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }).then(response=>{
                    console.log("Response",response)
                if(response.data.status){
                    setShowLoading(false);
                    Swal.fire(
                        "Order Placed!",
                        "Please check Your Email!",
                        "success"
                    );
                }else{
                    setShowLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text:
                          response?.data?.message ||
                          "Something went wrong!",
                    });
                }
                console.log("Response", response);
              }).catch(err=>{
                setShowLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text:
                      err?.data?.message ||
                      "Something went wrong!",
                });
                console.log("Error",err);
              })
            }
            else
            {
              setShowLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: token?.token || "Token not found!",
              });
            }
        });    
    }

    function renderCardElements(stripe) {
        var elements = stripe.elements({
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
                },
            ],
            // Stripe's examples are localized to specific languages, but if
            // you wish to have Elements automatically detect your user's locale,
            // use `locale: 'auto'` instead.
            locale: window.__exampleLocale
        });
        var elementStyles = {
            invalid: {
                color: '#E25950',
    
                '::placeholder': {
                    color: '#FFCCA5',
                },
            },
        };
    
        //const card = elements.create('card');
        let  cardNumberInstance = elements.create('cardNumber', {
            style: elementStyles,
            placeholder: 'Card Number'
        });
        setCardNumber(cardNumberInstance)
        const cardExpiry = elements.create('cardExpiry', {
            style: elementStyles,
        });
        const cardCvc = elements.create('cardCvc', {
            style: elementStyles,
        });
    
        cardNumberInstance.mount('#payment_element_cardNumber');
        cardExpiry.mount('#payment_element_cardExpiry');
        cardCvc.mount('#payment_element_cardCvv');
        console.log("Rendering Card Elements: ")
        return true;
    }
    
    async function tryCreateToken() {
      const { token, error } = await stripe.createToken(cardNumber);
      console.log("Card number",cardNumber);
      if (error) {
          return {status:false,token: error.message};
      } else {
          // Send the token to your server.
          return {status:true,token:token};
      }
    }

    useEffect(() => {
        console.log("Purposes>>",purposes)
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    useEffect(()=>{
        let options = areas?.map((area)=>{
            return {value: area.name,label: area.name}
        })
        setAreaOptions(options)
    },[areas])

    useEffect(()=>{
        const loadImages = async function(){
            const image1 = await import('../../../images/images/passport-size-photos-delivery/before-after/man-passport-size-photo-sample-before.jpg');
            const image2 = await import('../../../images/images/passport-size-photos-delivery/before-after/baby-passport-size-photo-sample-1-before.jpg');
            const image3 = await import('../../../images/images/passport-size-photos-delivery/before-after/kid-passport-size-photo-sample-1-before.jpg');
            setDummyImages([
                image1.default,image2.default,image3.default
            ])
        }
        loadImages();
    },[]);

    useEffect(()=>{
        if(!window?.Stripe) return;
        let stripeInstance = window.Stripe(process.env.REACT_APP_STRIPE_TEST_TOKEN);
        setStripe(stripeInstance);
        if(stripeInstance) renderCardElements(stripeInstance)
    },[])

    useEffect(()=>{
        checkFilesValid()
    },[files])

  return (
    <div className='order-form--wrapper'>
        {showLoading && <Loading/>}
    <Formik 
        innerRef={formRef}
        initialValues={initialValues}
        validate={validator}
        onSubmit={(values,errors) => {
            if(files.length === 0){
                setFilesValid(false)
                Swal.fire({
                    icon: "error",
                    title: "Validation Error",
                    text: "Please fill all fields!",
                });
                return;
            }
            if(!filesValidSize) return;
            openButton.current.click()
        }}>{({values,errors,touched,isValid,handleSubmit,handleChange,validateForm,setFieldValue})=>(
    <form className="container" onSubmit={(event)=>{event.preventDefault();validateForm().then((errors)=>{
        if(errors && Object.keys(errors).length !== 0){
            Swal.fire({
                icon: "error",
                title: "Validation Error",
                text: "Please fill all fields!",
              });
              handleSubmit();
        }else{
            handleSubmit();
        }})}}>
        <div className="contactSection" id='photos'>
            <h2 className="heading-h2">Fill The Form To Place Order!</h2>
            <div className="formContainer">
                <div className="formControll">
                    <label htmlFor="service">Service</label>
                    <select name='service' id='service'
                        onChange={(e)=>{handleChange(e);onChangeService(e);}}
                        value={values.service}
                        className="selectOptions">
                        <option disabled selected value="">Choose Service</option>
                        <option value="Soft Copy By Email">Soft Copy By Email</option>
                        <option value="Soft Copy By Email & Hard Copy Delivery">Soft Copy By Email & Hard Copy Delivery
                        </option>
                    </select>
                    {errors.service && touched.service && <div className="inputError">
                        <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon} />
                        </span> Please select service
                    </div>}
                </div>

                <div className="formControll">
                    <label htmlFor="firstname">First Name</label>
                    <input type='text'
                        onChange={handleChange}
                        value={values.firstname}
                        placeholder="Please Write Your First Name" name="firstname"
                        id="firstname"/>
                    {errors.firstname && touched.firstname &&
                    <div className="inputError">
                        <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon} />
                        </span> Please enter your first name
                    </div>}
                </div>

                <div className="formControll">
                    <label htmlFor="lastname">Last Name</label>
                    <input type='text'
                        onChange={handleChange}
                        value={values.lastname}
                        placeholder="Please Write Your Last Name" name="lastname"
                        id="lastname"/>
                    {errors.lastname && touched.lastname && <div 
                        className="inputError">
                        <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon}/>
                        </span> Please enter your last name
                    </div>}
                </div>

                <div className="formControll">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email"
                        onChange={handleChange}
                        value={values.email}
                        placeholder="Please Write Your Email"
                        name="email"/>
                    {errors.email && touched.email && <div className="inputError">
                        <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon} />
                        </span> Please enter your valid email address
                    </div>}
                </div>

                <div className="formControll">
                    <label htmlFor="phone">Phone Number</label>
                    <input type="tel" id="phone"
                        onChange={handleChange}
                        value={values.phone}
                        placeholder="Please Write Your Phone Number"
                        name="phone"/>
                    {errors.phone && touched.phone && <div className="inputError">
                        <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon} />
                        </span> Please enter your phone number
                    </div>}
                </div>
                <div className="formControll">
                    <label htmlFor="address">Address</label>
                    <input type="text"
                        onChange={handleChange}
                        value={values.address}
                        placeholder="Please Write Your Address" id="address"
                        name="address" />
                    {errors.address && touched.address && <div className="inputError">
                        <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon} />
                        </span> Please enter your address
                    </div>}
                </div>

                {values.service !== 'Soft Copy By Email' && <div className="formControll">
                    <label htmlFor="area">Area</label>
                    <SearchableSelect value={values.area} options={areaOptions} type="area" onChangeHandler={setValueCalculatePrice} />
                    {errors.area && touched.area && <div className="inputError">
                        <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon} />
                        </span> Please select your area
                    </div>}
                </div>}
                <div className="formControll">
                    <label htmlFor="purpose">Purpose</label>
                    <SearchableSelect options={purposes} type="purpose" value={values.purpose} onChangeHandler={setFieldValue} />
                    {errors.purpose && touched.purpose && <div className="inputError">
                        <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon} />
                        </span> Please select purpose
                    </div>}
                </div>

                {values.purpose === 'Other' && values.service !== 'Soft Copy By Email' && <>
                    <div className="formControll">
                        <label htmlFor="size">Size of Photo (mmxmm or name the visa/passport)</label>
                        <input
                            onChange={handleChange}
                            value={values.size}
                            type="text" placeholder="Please Write Size" id="size" name='size'/>
                        {errors.size && touched.size && <div className="inputError">
                            <div className="arrow-up"></div>
                            <span>
                                <img className="errorImage" src={ErrorIcon} />
                            </span> Please Write Size
                        </div>}
                    </div>

                    <div className="formControll">
                        <label htmlFor="bgColor">Background Color</label>
                        <input id='bgColor' 
                                onChange={handleChange}
                                value={values.bgColor}
                                name='bgColor' type="text" placeholder="Please Write Background Color" required/>
                        {errors.bgColor && touched.bgColor && <div 
                            className="inputError">
                            <div className="arrow-up"></div>
                            <span>
                                <img className="errorImage" src={ErrorIcon} />
                            </span> Please Write Background Color
                        </div>}
                    </div>
                </>}

                <div className="formControll">
                    <label htmlFor="people">Number Of People</label>
                    <input min="1" 
                        id='numberOfPeople'
                        onChange={(e)=>{handleChange(e);calculatePrice();}}
                        value={values.numberOfPeople}
                        name='numberOfPeople' type="number" placeholder="Please Write Number Of People"/>
                    {errors.numberOfPeople && touched.numberOfPeople && <div 
                        className="inputError">
                        <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon} />
                        </span> Please enter number of people
                    </div>}
                </div>

                {values.service !== 'Soft Copy By Email' && <div className="formControll">
                    <label htmlFor="numberPhotos">Number of Photos Required Per Person</label>
                    <select type="text"
                        onChange={(e)=>{handleChange(e);calculatePrice();}}
                        value={values.perPersonPhotos}
                        placeholder="Please Select Number of Photos Required Per Person"
                        id='perPersonPhotos' name='perPersonPhotos' className="selectOptions">
                        <option disabled selected value="">Please Select Number of Photos Required Per Person</option>
                        <option value="6">6</option>
                        <option value="8">8</option>
                        <option value="12">12</option>
                    </select>
                    {errors.perPersonPhotos && touched.perPersonPhotos && <div 
                        className="inputError">
                        <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon} />
                        </span> Please select Per Person photos
                    </div>}
                </div>}


                <div className="form-label">
                    {values.service == 'Soft Copy By Email' && <label>Upload Photo(s) to be Edited </label>}
                    {values.service != 'Soft Copy By Email' &&<label>Upload Photo(s) to be Printed </label>}
                </div>
                <ImageDropzone files={files} setFiles={setFiles} showCounters={false} />
                {!filesValid && isValid && <div className="inputError">
                    <div className="arrow-up"></div>
                    <span>
                        <img className="errorImage" src={ErrorIcon} />
                    </span> Please upload photos
                </div>}
                {!filesValidSize && isValid && <div className="inputError">
                    <div className="arrow-up"></div>
                    <span>
                        <img className="errorImage" src={ErrorIcon} />
                    </span> Per file size should not exceed 10MB.
                </div>}

                <div className="formControll any-comments">
                    <label htmlFor="people">Any Comments</label>
                    <textarea type="textarea"
                        onChange={handleChange}
                        value={values.comments}
                        id="comments" name="comments"> </textarea>
                </div>

                <div className="formControll">
                    <label htmlFor="people">Price</label>
                    <input readOnly value={price} type="text" id="people" name="people"/>
                </div>
            </div>
        </div>
        <br/>

        {/* <Stripe stripeKey='pk_test_51LvIOeKAUk8XyRgr30AgN3wspDiNrcGxnFHUBVU9C6tbdItJ47Fsv3Odn0ZN3JV1rTS4eb9FqKma1Qwzp1Dr4i1z00zkLD81Ls' 
                token={tokenHandler} /> */}
        <div style={{display:"flex", width:"100%"}}> <div style={{width:"10%", display:"flex", flexDirection:"column", alignItems:"center"}}><img src={visaCard} alt="VISAcARD" style={{width:"40px", margin:"10px 0px"}}/>
        <img src={masterCard} alt="VISAcARD" style={{width:"40px", margin:"0px 0px"}}/></div>
        <div style={{flexDirection:"column", width:"90%"}}>
        <div id='payment_element_cardNumber' className='stripe-field'></div>
        <div id='payment_element_cardExpiry' className='stripe-field'></div>
        <div id='payment_element_cardCvv' className='stripe-field'></div>
                </div></div>
        <div id="form-container" className="formControll payment-form">
            <div id="element-container"></div>
            {/* <div id="error-handler" role="alert">{{errorElement.textContent}}</div> */}
            <div id="success" style={{ display: "none", position: "relative", float: "left"}}>
                Success! Your token is <span id="token"></span>
            </div>
            <div className="submit-button">
                <button type="submit" id="tap-btn">Submit</button>
            </div>
        </div>
    </form>
    )}
    </Formik>


<button type="button" ref={openButton} className="btn btn-primary display-none" data-toggle="modal"
    data-target="#exampleModalCenter">
</button>

<div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">Just a check before you go ahead with the
                    confirmation :)</h5>

                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <p>Have a look at the below sample photos one more time before you confirm your order, if your photos
                    are far off from these samples, we would like to recommend you
                    to please retake the pictures and re-attach to the form. This is just to make sure that you have no
                    trouble in your application for any governmental or other process.</p>
                <div className="photo-container">
                {dummyImages.map((image)=>{
                    return <img src={image} />
                })}
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" ref={closeButton} data-dismiss="modal">Go Back</button>
                <button type="button" data-dismiss="modal" onClick={onConfirm}>Confirm</button>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default OrderForm