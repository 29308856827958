import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Banner from './Banner'

const EventPhotoGraphy = () => {
  useEffect(()=>{
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  },[])
  return (
    <div>
      <Helmet>
        <title>Experienced Professional Event Photographers Dubai – Ozelu Studio</title>
        <meta name='description' content='With years of experience in events photography, we at Ozelu take projects and execute it with passion and deliver according to the client’s requirements. Call us for a free consultation so we can discuss your requirements and the uniqueness of your event.' />
      </Helmet>
        <Banner/>
    </div>
  )
}

export default EventPhotoGraphy