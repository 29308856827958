import '../src/styles.scss'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import CommingSoon from "./screens/CommingSoon/CommingSoon";
import Home from "./screens/Home/Index";
import LogoDesign from "./screens/LogoDesign/Index";
import PhotoRetouching from "./screens/PhotoRetouching/Index";
import PortraitHeadshots from "./screens/PortraitHeadshots/Index";
import EventPhotoGraphy from "./screens/EventPhotoGraphy/Index";
import WeddingPhotography from "./screens/WeddingPhotography/Index";
import CVcustomDesigning from "./screens/CVcustomDesigning/Index";
import PassportSizePhotoDelivery from "./screens/PassportSizePhotoDelivery/Index";
import DacumentPrinting from "./screens/DacumentPrinting/Index";
import PhotoPrintingDelivery from "./screens/PhotoPrintingDelivery/Index";
import Main from './layout/Main';
import Collection from './screens/ProductsGrid/Index';
import { AppProvider } from './store/AppContext';
import CVdisplayPage from './screens/CVcustomDesigning/CVdesigningComponent/CVdisplayPage';
import CVtempletes from './screens/CVcustomDesigning/CVdesigningComponent/CVtempletes';
import Cart from './screens/Cart/Index';
import ProductPage from './screens/ProductPage/Index';
import Checkout from './screens/Checkout/Index';
import Page from './screens/Page/Index'

import {CartProvider} from 'react-use-cart'
import './components/EComereceStore/MiniCart.scss'
import CollectionsGrid from './screens/CollectionsGrid/Index';
import Caricature from './screens/Caricature/Index';

const router = createBrowserRouter([
  {
    path: "/",
    element:<Main/>,
    children:[
      {
        path: "/",
        element: <Home/>,
      },
      {
        path: "contact-us",
        element: <CommingSoon/>,
      },
      {
        path: "cv-templates",
        element: <CVcustomDesigning/>
      },
      {
        path: "editing-service",
        children:[
          {
            path: "logo-designing",
            element: <LogoDesign/>
          },
          {
            path: "photo-retouching",
            element: <PhotoRetouching/>
          },
          {
            path: "caricatures",
            element: <Caricature/>
          },
        ]
      },
      {
        path: "photography-service",
        children: [
          {
            path: "portrait-headshots",
            element: <PortraitHeadshots/>
          },
          {
            path: "event-photography",
            element: <EventPhotoGraphy/>
          },
          {
            path: "wedding-photography",
            element: <WeddingPhotography/>
          }
        ]
      },     
      {
        path: "passport-size-photos",
        element: <PassportSizePhotoDelivery/>,
      },
      {
        path: "photos-printing-delivery",
        element: <PhotoPrintingDelivery/>,
      },
      {
        path: "documents-printing",
        element: <DacumentPrinting/>,
      },
      {
        path: "cart",
        element: <Cart/>
      },
      {
        path: "collections",
        element: <CollectionsGrid/>
      },
      {
        path: "collections/:handle",
        element: <Collection/>
      },
      {
        path: "products/:handle",
        element: <ProductPage/>
      },
      {
        path: "checkout",
        element: <Checkout/>
      },
      {
        path: "pages/:handle",
        element: <Page/>
      },
      {
        path: "policies/:handle",
        element: <Page/>
      }
    ]
  }
]);

function App() {

  function handleItemAdd(cart){
    console.log("cart>>",cart)
  }
  return (
    <div className="App">
      <CartProvider onItemAdd={handleItemAdd}>
        <AppProvider>
          <RouterProvider router={router}/>
        </AppProvider>
      </CartProvider>
    </div>
  );
}

export default App;
