import React, { useEffect, useState } from 'react'
import {Swiper,SwiperSlide} from 'swiper/react';
import { Navigation } from 'swiper';
import axios from 'axios';
import CollectionCard from '../../components/EComereceStore/CollectionCard';

function FeaturedCollections() {

  const swiperRef = React.useRef()
  const prevRef = React.useRef();
  const nextRef = React.useRef();
  const [collections, setCollections] = useState(null)

  const handlePrev = function(){
    swiperRef.current?.slidePrev()
  }

  const handleNext = function(){
    swiperRef.current?.slideNext()
  }
  
  useEffect(()=>{
      const getCollections =async ()=>{
          const {data} = await axios.get(process.env.REACT_APP_API_URL + '/api/assets/collections/by-tag/'+'trending');
          const {collections} = data
          setCollections(collections)
      }
      getCollections()
  },[])

  return (
    <div className='SectionWrapper'>
    <div className='container' >
        <section className="services-tittle">
        <div className="container" >
          <h2 className="txt-center heading-h2">E-Commerce Store</h2>
        </div>
      </section>
       {collections && <section style={{maxWidth:"1260px"}}>
        <div className='cards-wrapper'>
        <Swiper className='equal_nt ratio_nt on_list_view_false'
        style={{ width:"100%", height:"100%"}}
          modules={[Navigation]}
          navigation={{
            prevEl: prevRef?.current,
            nextEl: nextRef?.current
          }}
          onBeforeInit={(swiper) => {
            console.log("Swiper>>",swiper)
            swiperRef.current = swiper;
          }}
          breakpoints={{
            280: {
                slidesPerView: 3,
            },
            576: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
          }}
          spaceBetween={20}>
            {collections.map((collection)=>{
              return <SwiperSlide style={{background:'white'}}><CollectionCard collection={collection} /></SwiperSlide>
            })}
          </Swiper>
          <i className='btn--next fa-solid fa-chevron-right' ref={nextRef} onClick={handleNext} type="next"></i >
          <i className='btn--prev fa-solid fa-chevron-left' ref={prevRef} onClick={handlePrev} type="prev"></i >  
        </div></section>}
    </div>
  </div>
  )
}

export default FeaturedCollections