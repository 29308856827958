import React from 'react'
import loadingImg from '../images/Rolling.svg';
import './loading.scss'

function Loading() {
  return (
    <div className='loading--wrapper'>
        <img src={loadingImg} style={{width:'auto'}}/>
        <span>Loading...</span>
    </div>
  )
}

export default Loading