import React, { useContext, useEffect } from 'react'
import './header.scss'
import {Link, useLocation} from "react-router-dom"
import { useState } from 'react';
import AppContext from '../store/AppContext';
// import useApp from '../store/useApp';

const sidebarItems = [
  {
    text: "Passport Size Photos Delivery",
    link: "/passport-size-photos",
    isActive: false,
  },
  {
    text: "Photos and Documents Printing",
    isActive: false,
    subMenu: [
      {
        text: "Photo Printing & Delivery",
        link: "/photos-printing-delivery",
      },
      {
        text: "Document Printing",
        link: "/documents-printing",
      },
    ],
  },
  {
    text: "CV Templates",
    link: "/cv-templates",
    isActive: false
  },

  {
    text: "Editing Services",
    isActive: false,
    subMenu: [
      {
        text: "Logo Designing",
        link: "/editing-service/logo-designing",
      },
      {
        text: "Photo Retouching",
        link: "/editing-service/photo-retouching",
      },
    ],
  },
  {
    text: "Photography Services",
    isActive: false,
    subMenu: [
      {
        text: "Portrait Headshots",
        link: "/photography-service/portrait-headshots",
      },
      {
        text: "Event Photography",
        link: "/photography-service/event-photography",
      },
      {
        text: "Wedding Photography",
        link: "/photography-service/wedding-photography",
      },
    ],
  },
  {
    text: "E-Commerece Store",
    isActive: false,
    subMenu: [
      {
        text: "All",
        link: '/collections/all'
      }
    ]
  }
];

////////////////////////////////////////////////////////////////////////////////////////////

/////////////////////////////////////////////////////////////////////////////////////////////

function Header() {
  const location = useLocation();
  const {mainMenu} = useContext(AppContext)
  const [activeIndex, setActiveIndex] = useState(-1);
  
  function toggleSubmenu(index,innerIndex=0) {
      setActiveIndex((prev) => {
      if (prev?.outer === index && prev?.inner === innerIndex) {
        // closeMenu();
        return -1;
      }
      return {outer: index,inner: innerIndex};
    });
    if (activeIndex.outer === index && activeIndex.inner === innerIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex({
        outer: index,
        inner: innerIndex
      });
    }
    console.log("Active index>>",activeIndex)
  }
  
  const openMenu = ()=>{
    
      document.getElementById("overlay").style.display = "flex"
      
  }
  const closeMenu = ()=>{
      document.getElementById("overlay").style.display = "none" 
  }

  // const handleMouseEnter = (e)=>{
  //   const elem = e.currentTarget;
  //   if(!elem.parentElement.classList.contains('show')){
  //     e.currentTarget.click()
  //   }
  // }

  const handleInnerMouseEnter = (e)=>{
    const elem = e.currentTarget.querySelector('.dropdown-menu');
    if(!elem.classList.contains('show')){
      elem.classList.add('show')
    }
  }

  const handleInnerMouseLeave = (e)=>{
    const elem = e.currentTarget.querySelector('.dropdown-menu');
    if(elem.classList.contains('show')){
      elem.classList.remove('show')
    }
  }

  function NewlineText(props) {
    const text = props.text;
    return <>{text.split('*').map((str,i)=>{
      if(i === 0) return str;
      return <><br/>{str}</>
    })}</>

  }

  useEffect(()=>{
    console.log("main menu>>",mainMenu)
  },[mainMenu])
  
  if(!mainMenu) return null;

  return (
    <div className='header--wrapper' id='header'>
    <div className="header">
      <nav className="navbar navbar-expand-md navbar-light width-100 navbar-default navbar-static-top">
        <Link className="navbar-brand" style={{textDecoration:'none'}} to='/'>
          <h1 className="logo">Ozelu Studio</h1>
        </Link>
        <button className="navbar-toggler" type="button">
          <span style={{fontSize: '30px',cursor: 'pointer'}} onClick={openMenu}>&#9776;</span>
        </button>
        {mainMenu &&
        <div className="collapse navbar-collapse flex-end" id="collapsibleNavbar">
          <ul className="navbar-nav nav-link-typography">
            {mainMenu?.children.map((item)=>{
              return <li className='nav-item'>
                {(item.children && item?.children.length) ? (
                  <div className="dropdown">
                  <a className="nav-link" style={{textDecoration:'none'}} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <NewlineText text={item.title}/>
                  </a>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {item?.children?.map((childItem)=>{
                      return <>
                        {(childItem.children && childItem?.children.length) ? (
                          <li class="dropdown-submenu" onMouseEnter={handleInnerMouseEnter} onMouseLeave={handleInnerMouseLeave}>
                              <Link class="dropdown-item" tabIndex="-1" to={childItem.url}>
                                  {childItem.title} &raquo;
                              </Link>
                              <ul class="dropdown-menu">
                                {childItem.children.map((grandChildItem)=>{
                                  return <li><Link class="dropdown-item" tabIndex="-1" to={grandChildItem.url}>{grandChildItem.title}</Link></li>
                                })}
                              </ul>
                          </li>
                        ):(
                          <Link className={`dropdown-item ${location.pathname=== childItem.url ? 'active' : ''}`} style={{textDecoration:'none'}} to={childItem.url}  onClick=  {() => window.scrollTo(0, 0)}>{childItem.title}</Link>
                        )}
                      </>
                      return 
                    })}
                  </div>
                </div>
                ) : (
                  <Link  style={{textDecoration:'none'}} to={item.url} className={`nav-link ${location.pathname=== item.url ? 'active' : ''}`} onClick=  {() => window.scrollTo(0, 0)} ><NewlineText text={item.title}/></Link>
                )}
              </li>
            })}
          </ul>
        </div>}
      </nav>
    </div>

    <div id="overlay" >
      <nav onClick={(event)=>event.stopPropagation()}>
        <div className="top-div">Menu</div>
        <ul>
          {mainMenu?.children.map((item, index) => (
            <li key={item.title} >
              <Link
                to={item.url}
                style={{textDecoration:'none'}}
                // routerLinkActive="active"
                className={location.pathname === item.url? 'active': ''}
                onClick={closeMenu}
              >
                {item.title.split('*').join(' ')}
                <div className='inner-icon'  onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          toggleSubmenu(index)
                        }}>
                {(item?.children && item?.children?.length) ? (activeIndex?.outer === index ?  <span className="fas fa-minus"></span> : <span className="fas fa-plus"></span>) : null }
                </div>
              </Link>
              {(item.children && item?.children?.length) ? (
                <ul style={{ display: activeIndex?.outer === index ? 'block' : 'none' }}>
                  {item.children.map((subMenuItem,innerIndex) => (
                    <li key={subMenuItem.title}>
                      <Link
                        to={subMenuItem.url}
                        style={{textDecoration:'none'}}
                        onClick={() => {
                          if(subMenuItem.url ){
                            closeMenu();
                          }
                          window.scrollTo(0, 0);
                        }}
                        className={location.pathname === subMenuItem.url? 'active': ''}
                      >
                        {subMenuItem.title}
                        <div className='inner-icon'  onClick={(e) => {
                          e.stopPropagation()
                          e.preventDefault()
                          if(subMenuItem?.children && subMenuItem?.children?.length){
                            toggleSubmenu(index,innerIndex)
                          }
                        }}>
                          {(subMenuItem?.children && subMenuItem?.children?.length) ? (activeIndex?.inner === innerIndex ?  <span className="fas fa-minus"></span> : <span className="fas fa-plus"></span>) : null }
                        </div>
                      </Link>
                      {(subMenuItem.children && subMenuItem?.children?.length) ? (
                        <ul style={{ display: activeIndex?.inner === innerIndex ? 'block' : 'none' }}>
                          {subMenuItem.children.map((subSubMenuItem) => (
                            <li key={subSubMenuItem.title}>
                              <Link
                                to={subSubMenuItem.url}
                                style={{textDecoration:'none'}}
                                onClick={(e) => {
                                  if(subSubMenuItem.url ){
                                    closeMenu();
                                  }
                                  window.scrollTo(0, 0);
                                }}

                                className={location.pathname === subSubMenuItem.url? 'active': ''}
                              >
                                {subSubMenuItem.title}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </ul>
      </nav>
      <button className="closebtn" onClick={closeMenu}>
        <i className="fas fa-xmark" />
      </button>
    </div>
  </div>
  )
}

export default Header