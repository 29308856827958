import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Banner from './Banner'


const PhotoRetouching = () => {
  useEffect(()=>{
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  },[])
  return (
    <div className='photo-retouching-wrapper'>
      <Helmet>
        <title>Professional High Quality Photo Retouching Dubai UAE – Ozelu Photo Retoucher</title>
        <meta name='description' content='Why look for freelancers, when you have locally the best professional photo retoucher’s. If you need your wedding photos retouched, photos from a professional photoshoot or just want to get some old memories retouched and enhanced, contact us for a free consultation.' />
      </Helmet>
       <Banner/>
    </div>
  )
}

export default PhotoRetouching