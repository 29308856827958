import React from 'react'
import { Link } from 'react-router-dom'
import "./Grid.scss"
const GridSection = ({collections}) => {

    return (
        <div className='container container_cat cat_default mt__60 mb__60'>
            <div className='nt_cats_holder row fl_center equal_nt hoverz_true ratio1_1 cat_space_30 cat_design_1'>
                {collections?.map(collection=>
                {return (<div key={collection._id} className='cat_grid_item cat_space_item col-lg-4 col-md-3'>
                    <div className='oh pr'>
                        <div className='cat_grid_item__content pr oh'>
                            <Link to={`/collections/`+collection.slug} className='db cat_grid_item__link'>
                                <div className='cat_grid_item__overlay nt_bg_lz lazyloadt4sed' style={{backgroundImage: `url(${collection.featured_image.src})`}}>

                                </div>
                            </Link>
                        </div>
                        <div className='cat_grid_item__wrapper pe_none'>
                            <div className='cat_grid_item__title h3'>
                                {collection.title}
                            </div>
                        </div>
                    </div>
                </div>)
                })}
            </div>
        </div>
    )
}

export default GridSection