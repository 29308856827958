import React from 'react'
import "./styleEditingServices.scss"
import logoDesign from "../../../images/images/home-page/logo-designing.jpg"
import cvDesign from "../../../images/images/home-page/cv-designing-templates.jpg"
import photoRetouching from "../../../images/images/home-page/photos-retouching.jpg"
import caricatures from '../../../images/caricatures/caricatures-cover-photo.jpg'
import {Link} from "react-router-dom"

const EditingServices = () => {
  return (
    <div className='editing-services--wrapper'>
        <section className="services-tittle">
	<div className="container">
		<h2 className="txt-center heading-h2">Editing Services</h2>
	</div>
</section>
<section className="editing-services">
	<div className="container">
		<div className="row mb-20">
			<div className="col-md-4 bg-gradient no-pad">
				<img className="editing-service-img" src={logoDesign} alt="logoDesign" />
			</div>
			<div className="col-md-8 bg-pink">
				<div className="editing-services-right">
					<h2 className="heading-h3">Logo Designing</h2>
					<p className="content">If you need a logo for your new start-up or want to bring a new persona to your
						already existing
						business, do get in touch with us to design for you a fully customized logo tailored specially
						to your goals and ideas.</p>
					<Link to="editing-service/logo-designing" type="submit" className="btn-pink" style={{textDecoration:"none", color:"black", textAlign:"center"}}
					onClick=  {() => window.scrollTo(0, 0)}>Click here for
						more details </Link>
				</div>
			</div>
		</div>
		<div className="row mb-20">
			<div className="col-md-4 bg-gradient no-pad">
            <img className="editing-service-img" src={cvDesign} alt="cvDesign" />
			</div>
			<div className="col-md-8 bg-lightgreen">
				<div className="editing-services-right">
					<h2 className="heading-h3">CV Templates</h2>
					<p className="content">Choose from our free samples or paid templates (editable) of well-designed expert approved CVs or get in touch with us to
						design for you a
						fully customized CV based on the industry you are hunting for and the profession you would like
						to get into. </p>
					<Link to="/cv-templates" type="submit" className="btn-green" style={{textDecoration:"none", color:"black", textAlign:"center"}}
					onClick=  {() => window.scrollTo(0, 0)}>Click here for
						more details</Link>
				</div>
			</div>
		</div>
		<div className="row mb-20">
			<div className="col-md-4 bg-gradient no-pad">
            <img className="editing-service-img" src={photoRetouching} alt="photoRetouching" />
			</div>
			<div className="col-md-8 bg-yellow">
				<div className="editing-services-right">
					<h2 className="heading-h3">Photo Retouching</h2>
					<p className="content">Upload your photos to be retouched by our highly skilled professionals in
						Photoshop, Adobe
						Illustrator and other design tools and we will make sure we exceed your expectations. Upload
						your images to our simple form, set your expectations and pay online via your debit or credit
						card. </p>
					<Link to="editing-service/photo-retouching" type="submit" className="btn-yellow" style={{textDecoration:"none", color:"black", textAlign:"center"}}
					onClick=  {() => window.scrollTo(0, 0)}>Click here
						for more details</Link>
				</div>
			</div>
		</div>
		<div className="row mb-20">
			<div className="col-md-4 bg-gradient no-pad">
            <img className="editing-service-img" src={caricatures} alt="caricatures" />
			</div>
			<div className="col-md-8 bg-purple">
				<div className="editing-services-right">
					<h2 className="heading-h3">Caricatures</h2>
					<p className="content">Check our samples of caricatures and digital illustrations for different occasions like weddings, birthdays or as a memory for couples. Get in touch with us to get a quote and let us inspire you with our creativity. </p>
					<Link to="editing-service/caricatures" type="submit" className="btn-purple" style={{textDecoration:"none", color:"black", textAlign:"center"}}
					onClick=  {() => window.scrollTo(0, 0)}>Click here
						for more details</Link>
				</div>
			</div>
		</div>
	</div>
</section>

    </div>
  )
}

export default EditingServices