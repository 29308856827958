import axios from 'axios'
import React, { useEffect, useState } from 'react'
import PageLoader from '../../components/Loader/PageLoader'
import Banner from './Banner'
import GridSection from './Grid'

const CollectionsGrid = () => {
  const [collections, setCollections] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(()=>{
      const getCollections =async ()=>{
          const {data} = await axios.get(process.env.REACT_APP_API_URL + '/api/assets/collections');
          const {collections} = data
          setCollections(collections)
          setIsLoading(false)
      }
      getCollections()
      window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
      });
  },[])

  if(isLoading) return <PageLoader/>

  return (
    <div>
        <Banner/>
        <GridSection collections={collections}/>
    </div>
  )
}

export default CollectionsGrid