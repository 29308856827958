import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "../../components/Loader/PageLoader";
import Banner from "./Banner";
import CollectionGridItems from "./CollectionGridItems";
import { Helmet } from "react-helmet-async";

const Index = function(){
    const {handle} = useParams()

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(true)
    const [collection,setCollection] = useState({})
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(1);
    const [page,setPage] = useState(null)
    const [sort,setSort] = useState(null)

    
    async function changePage(page){
        setPage(page)
    }

    function changeSort(sorting){
        setSort(sorting)
    }

    useEffect(()=>{
        setIsLoading(true)
        const searchByQuery = async () => {
            let queryParams = ''
            if(page && !sort) queryParams += 'page='+page;
            if(sort && !page) queryParams += 'sort_by='+sort
            if(page && sort) queryParams = 'page='+page+'&sort_by='+sort
            const {data} = await axios.get(process.env.REACT_APP_API_URL + '/api/assets/collections/'+handle+'?'+queryParams);
            const {collection,pagination} = data
            setCollection(collection)
            setCurrentPage(pagination.currentPage);
            setTotalCount(pagination.count);
            setIsLoading(false)
            navigate({
                pathname: '/collections/'+handle,
                search: '?'+queryParams
            })
        }
        searchByQuery()
    },[page,sort])

    useEffect(()=>{
        setIsLoading(true)
        const getCollection =async ()=>{
            const {data} = await axios.get(process.env.REACT_APP_API_URL + '/api/assets/collections/'+handle);
            const {collection,pagination} = data
            setCollection(collection)
            setCurrentPage(pagination.currentPage);
            setTotalCount(pagination.count);
            setIsLoading(false)
        }
        getCollection()
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    },[handle])
    if(isLoading) return <PageLoader/>
    return (
        <>
            <Helmet>
                <title>{collection?.page_title}</title>
                <meta name="description" content={collection?.meta_description} />
            </Helmet>
            <Banner title={collection?.title}/>
            <CollectionGridItems products={collection?.products} currentPage={currentPage} totalCount={totalCount} changePage={changePage} changeSort={changeSort}/>
        </>
    )
}

export default Index;