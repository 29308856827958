import React, { useRef, useState, useEffect } from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import ErrorIcon from "../../../images/images/error.jpeg";
import axios from "axios";
import Checkout from "../../../components/Checkout";
import useApp from "../../../store/useApp";
import "./styles.scss";
import FileDropzone from "../../../components/FileDropzone";
import SearchableSelect from "../../../components/SearchableSelect";
import LoadingProgress from "../../../components/LoadingProgress";

const DacumentPrintingForm = () => {
  const { tryCreateToken, documentPrintingPrices,getAreaSet,areas } = useApp();
  const [price, setPrice] = useState(40);
  const [deliveryCharges, setDeliveryCharges] = useState(0);
  const formRef = useRef();
  const [showLoading, setShowLoading] = useState(false);
  const [percentageUploaded, setPercentageUploaded] = useState(0)
  const [areaOptions, setAreaOptions] = useState([]);

  const [totalColorPrintPages, setTotalColorPrintPages] = useState(0);
  const [totalBlackAndWhitePages, setTotalBlackAndWhitePages] = useState(0);

  const [filesForColorPrint, setFilesForColorPrint] = useState([]);
  const [filesForBlackAndWhitePrint, setFilesForBlackAndWhitePrint] = useState([]);
  const [filesForColorPrintValid,setFilesForColorPrintValid] = useState(true)
  const [filesForBlackAndWhitePrintValid,setFilesForBlackAndWhitePrintValid] = useState(true)

  const initialValues = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    address: "",
    area: "",
    comments: "",
    colorPrint: false,
    blackAndWhitePrint: false,
  };

  const checkFilesSizeValid = function(){

    let filesForColorPrintValid = true;
    if(filesForColorPrint && filesForColorPrint.length){
      filesForColorPrint.map((file)=>{
            if(file.size > (10 * 1024 * 1024)){
              filesForColorPrintValid = false
            }
        })
    }
    filesForColorPrintValid ? setFilesForColorPrintValid(true) : setFilesForColorPrintValid(false)

    let filesForBlackAndWhitePrintValid = true;
    if(filesForBlackAndWhitePrint && filesForBlackAndWhitePrint.length){
      filesForBlackAndWhitePrint.map((file)=>{
            if(file.size > (10 * 1024 * 1024)){
              filesForBlackAndWhitePrintValid = false
            }
        })
    }
    filesForBlackAndWhitePrintValid ? setFilesForBlackAndWhitePrintValid(true) : setFilesForBlackAndWhitePrintValid(false)

  }


  const validator = function (values) {
    const errors = {};
    if (!values.firstname) errors.firstname = "Required";
    if (!values.lastname) errors.lastname = "Required";
    if (!values.phone) errors.phone = "Required";
    if (!values.address) errors.address = "Required";
    if (!values.area) errors.area = "Required";
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    return errors;
  };
  const getFormikValues = () => {
    const formikValues = formRef.current?.values;
    return formikValues;
  };

  function setFileCounter(type,file,value){
    if(type == 'colorPrint'){
      let files = filesForColorPrint.map((innerFile)=>{
        if(file == innerFile){
          return Object.assign(innerFile, {count: value});
        }else{
          return innerFile
        }
      })
      setFilesForColorPrint(files)
    }else if(type == 'blackAndWhitePrint'){
      let files = filesForBlackAndWhitePrint.map((innerFile)=>{
        if(file == innerFile){
          return Object.assign(innerFile, {count: value});
        }else{
          return innerFile
        }
      })
      setFilesForBlackAndWhitePrint(files)
    }
    calculatePrice()
  }

  function calculatePrice() {
    let newDeliveryCharges = 0;
    const {area} = getFormikValues();
    let areaSet = getAreaSet(area);

    let priceObj = documentPrintingPrices?.filter((item)=>{
        return item.areaset === areaSet
    })
    if(priceObj.length > 0){
        setDeliveryCharges(priceObj[0]?.price);
        newDeliveryCharges = priceObj[0]?.price;
    }else{
        setDeliveryCharges(0)
        newDeliveryCharges = 0
    }


    setTimeout(() => {
      const {
        colorPrint,
        blackAndWhitePrint
      } = getFormikValues();
      let newPrice = 0;
      setPrice(0);
      if (colorPrint) {
        filesForColorPrint.forEach((item)=>{
          newPrice = newPrice + (item.count * item.pages * 3)
        })
        setPrice(newPrice + newDeliveryCharges);
      }
      if (blackAndWhitePrint) {
        filesForBlackAndWhitePrint.forEach((item)=>{
          newPrice = newPrice + (item.count * item.pages * 2)
        })
        setPrice(newPrice + newDeliveryCharges);
      }
      if (newPrice < 40) {
        newPrice = 40;
        setPrice(newPrice + newDeliveryCharges);
      }
    }, 100);
  }

  async function setValueCalculatePrice(type, value) {
    formRef.current.setFieldValue(type, value);
    await Promise.resolve();
    calculatePrice();
  }

  function onSubmit() {
    const {
      firstname,
      lastname,
      email,
      phone,
      address,
      area,
      comments,
      colorPrint,
      blackAndWhitePrint
    } = getFormikValues();
    setShowLoading(true);

    tryCreateToken().then(async (token) => {
      if (token === undefined || token === "") {
        console.log("Error while getting token");
        setShowLoading(false);
      } else {
        const formData = new FormData();
        const counts = []

        if (colorPrint) {
          filesForColorPrint.forEach((file) => {
            const extension = file.name.split(".").pop();
            const filename = `${file.name
              .split(".")
              .slice(0, -1)
              .join(".")}_color_print(${file.count}).${extension}`;
            formData.append("files", file, filename);
            counts.push(file.count)
          });
        }
        if (blackAndWhitePrint) {
          filesForBlackAndWhitePrint.forEach((file) => {
            const extension = file.name.split(".").pop();
            const filename = `${file.name
              .split(".")
              .slice(0, -1)
              .join(".")}_black_white_print(${file.count}).${extension}`;
            formData.append("files", file, filename);
            counts.push(file.count)
          });
        }
        formData.append("firstname", firstname);
        formData.append("lastname", lastname);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("address", address);
        formData.append("area", area);
        formData.append("price", price);
        formData.append("token", token.id);
        formData.append("count",JSON.stringify(counts));
        formData.append("comments", comments);

        try {
          const {data} = await axios({
            data: formData,
            method: "POST",
            url: process.env.REACT_APP_API_URL + '/api/createOrder/document',
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
              console.log(progressEvent)
              const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
              setPercentageUploaded(progress)
            }
          })
          const {order_id,customer_id} = data

          axios.post(process.env.REACT_APP_API_URL + "/api/stripe/document-pay",{
            order_id,customer_id,price})
          .then((response) => {
            console.log("Response", response);
            if (response.data.status) {
              setShowLoading(false);
              Swal.fire("Order Placed!", "Please check Your Email!", "success");
            } else {
              setShowLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response?.data?.message || "Failed to collect your payment.",
              });
            }
            console.log("Response", response);
          })
          .catch((err) => {
            setShowLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err?.data?.message || "Failed to collect your payment.",
            });
            console.log("Error", err);
          });
        } catch (error) {
          setShowLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error?.data?.message || "Something went wrong while uploading your files. Please make sure files are in proper format and size. If error presist please contact our support.",
          });
        }
      }
    });
  }

  function setPagesAndCopies(){
    let pages = 0
    filesForColorPrint.forEach((file)=>{
      pages += (file.pages * file.count)
    })
    setTotalColorPrintPages(pages)
    pages = 0
    filesForBlackAndWhitePrint.forEach((file)=>{
      pages +=  (file.pages * file.count)
    })
    setTotalBlackAndWhitePages(pages)
  }


  useEffect(() => {
    let options = areas.map((area) => {
      return { value: area.name, label: area.name };
    });
    setAreaOptions(options);
  }, []);

  useEffect(() => {
    calculatePrice()
    setPagesAndCopies()
    checkFilesSizeValid()
  },[filesForColorPrint,filesForBlackAndWhitePrint])

  return (
    <div className="document-printing-form--wrapper">
      {showLoading && <LoadingProgress loaded={percentageUploaded}/>}
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validate={validator}
        onSubmit={(values, errors) => {
          onSubmit();
        }}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          handleSubmit,
          handleChange,
          setFieldValue,
          validateForm,
        }) => (
          <form
            class="container"
            onSubmit={(event) => {
              event.preventDefault();
              validateForm().then((errors) => {
                if (errors && Object.keys(errors).length !== 0) {
                  Swal.fire({
                    icon: "error",
                    title: "Validation Error",
                    text: "Please fill all fields!",
                  });
                  handleSubmit();
                } else {
                  if(!filesForColorPrintValid || !filesForBlackAndWhitePrintValid) return;
                  handleSubmit();
                }
              });
            }}
          >
            <div class="contactSection" id="photos">
              <h2 class="heading-h3">
                Please fill the form below to place an order
              </h2>
              <div class="formContainer">
                <div class="formControll">
                  <label for="firstname">First Name</label>
                  <input
                    formControlName="firstname"
                    value={values.firstname}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="Please Write Your First Name"
                    name="firstname"
                    id="firstname"
                  />
                  {errors.firstname && touched.firstname && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your first name
                    </div>
                  )}
                </div>

                <div class="formControll">
                  <label for="lastname">Last Name</label>
                  <input
                    formControlName="lastname"
                    type="text"
                    value={values.lastname}
                    onChange={(e) => handleChange(e)}
                    placeholder="Please Write Your Last Name"
                    name="lastname"
                    id="lastname"
                  />
                  {errors.lastname && touched.lastname && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your last name
                    </div>
                  )}
                </div>

                <div class="formControll">
                  <label for="email">Email</label>
                  <input
                    formControlName="email"
                    value={values.email}
                    onChange={(e) => handleChange(e)}
                    type="email"
                    id="email"
                    placeholder="Please Write Your Email"
                    name="email"
                  />
                  {errors.email && touched.email && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your valid email address
                    </div>
                  )}
                </div>

                <div class="formControll">
                  <label for="pnumber">Phone Number</label>
                  <input
                    formControlName="phone"
                    value={values.phone}
                    onChange={(e) => handleChange(e)}
                    type="tel"
                    id="pnumber"
                    placeholder="Please Write Your Phone Number"
                    name="phone"
                  />
                  {errors.phone && touched.phone && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your phone number
                    </div>
                  )}
                </div>
                <div class="formControll">
                  <label for="address">Address</label>
                  <input
                    formControlName="address"
                    value={values.address}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="Please Write Your Address"
                    id="address"
                    name="address"
                  />
                  {errors.address && touched.address && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your address
                    </div>
                  )}
                </div>
                <div class="formControll">
                  <label for="area">Area</label>
                  <SearchableSelect
                    type="area"
                    options={areaOptions}
                    value={values.area}
                    onChangeHandler={setValueCalculatePrice}
                  />
                  {errors.area && touched.area && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please select your area
                    </div>
                  )}
                </div>
                <div class="formControll any-comments">
                  <label for="people">Any Comments</label>
                  <textarea
                    formControlName="comments"
                    type="textarea"
                    value={values.comments}
                    onChange={handleChange}
                    id="comments"
                    name="comments"
                  >
                    {" "}
                  </textarea>
                </div>
              </div>
              <br />
              <div class="formControll">
                <span>
                  <h6>Please specify Color or Black & white</h6>
                </span>
                <br/>
              </div>
              <div class="formControll">
                <div>
                  <input
                    checked={values.colorPrint}
                    onChange={(e) => {
                      setValueCalculatePrice("colorPrint", !values.colorPrint);
                    }}
                    type="checkbox"
                    name="img-bg"
                  />
                  <label class="margin-left-15" for="img-bg">
                    Color Print{" "}
                  </label>
                  <br />
                </div>
                <div>
                  <input
                    type="checkbox"
                    checked={values.blackAndWhitePrint}
                    onChange={(e) => {
                      setValueCalculatePrice(
                        "blackAndWhitePrint",
                        !values.blackAndWhitePrint
                      );
                    }}
                    name="img-bg"
                  />
                  <label class="margin-left-15" for="img-bg">
                    Black and White Print
                  </label>
                  <br />
                </div>
              </div>
              {values.colorPrint && (
                <>
                  <div class="formControll">
                    <label>
                      Please upload only PDF or Images for Color print{" "}
                    </label>
                    <FileDropzone
                      files={filesForColorPrint}
                      setFiles={setFilesForColorPrint}
                      pages={totalColorPrintPages}
                      setPages={setTotalColorPrintPages}
                      showCounters={true}
                    />
                    {!filesForColorPrintValid && <div className="inputError">
                    <div className="arrow-up"></div>
                      <span>
                          <img className="errorImage" src={ErrorIcon} />
                      </span> Per file size should not exceed 10MB.
                  </div>}
                  </div>
                  <br/>
                  {/* <div className="formControll">
                    <label for="colorPrintCounter">Number of Copies</label>
                    <input
                      formControlName="Counter"
                      type="number"
                      min={1}
                      id="colorPrintCounter"
                      name="colorPrintCounter"
                      value={values?.colorPrintCounter}
                      onChange={(e) =>
                        setValueCalculatePrice(
                          "colorPrintCounter",
                          e.currentTarget.value
                        )
                      }
                    />
                  </div> */}
                </>
              )}
              <br />
              
              {values.blackAndWhitePrint && (
                <>
                  <div className="formControll">
                    <label>
                      Please upload only PDF or Images for Black and
                      White Print{" "}
                    </label>
                    <FileDropzone
                      files={filesForBlackAndWhitePrint}
                      setFiles={setFilesForBlackAndWhitePrint}
                      pages={totalBlackAndWhitePages}
                      setPages={setTotalBlackAndWhitePages}
                      showCounters={true}
                    />
                    {!filesForBlackAndWhitePrintValid && <div className="inputError">
                      <div className="arrow-up"></div>
                        <span>
                            <img className="errorImage" src={ErrorIcon} />
                        </span> Per file size should not exceed 10MB.
                    </div>}
                  </div>
                  <br/>
                  {/* <div className="formControll">
                    <label for="blackAndWhiteCounter">Number of Copies</label>
                    <input
                      formControlName="Counter"
                      type="number"
                      min={1}
                      id="blackAndWhiteCounter"
                      name="blackAndWhiteCounter"
                      value={values?.blackAndWhiteCounter}
                      onChange={(e) =>
                        setValueCalculatePrice(
                          "blackAndWhiteCounter",
                          e.currentTarget.value
                        )
                      }
                    />
                  </div> */}
                </>
              )}
              
              <div class="formControll">
                <label for="people">Price</label>
                <input
                  readOnly
                  formControlName="price"
                  value={price}
                  type="text"
                  id="people"
                  name="people"
                />
                <div class="order-summary">
                <p className="mt-2"> Delivery Charges: {deliveryCharges} AED </p>
                  {values.colorPrint && (
                    <p>
                      {" "}
                      Color Print: {" "}
                      {totalColorPrintPages} Pages x 3 AED ={" "}
                      {totalColorPrintPages * 3} AED{" "}
                    </p>
                  )}
                  {values.blackAndWhitePrint && (
                    <p>
                      {" "}
                      Black and White Print: {totalBlackAndWhitePages} Pages x 2 AED ={" "}
                      {totalBlackAndWhitePages *
                        2}{" "}
                      AED{" "}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <br />
            <div id="form-container" class="formControll payment-form">
              <Checkout />
              <div class="submit-button">
                <button type="submit" id="tap-btn">
                  Submit
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default DacumentPrintingForm;
