import React, { useEffect, useRef, useState } from "react";
// import "../../../src/styles.scss"
import "./styles.scss";
import { BeforeAfterImageCategory } from "../../utils";
import itWorkStep1 from '../../images/images/passport-size-photos-delivery/step-1-take-photo-phone.png'
import itWorkStep2  from '../../images/images/passport-size-photos-delivery/step-2-pay-online.png';
import itWorkStep3 from '../../images/images/passport-size-photos-delivery/step-3-same-day-free-delivery.jpg';

import paymentMethod1 from "../../images/images/passport-size-photos-delivery/payment-method-1.png";
import paymentMethod2 from "../../images/images/passport-size-photos-delivery/payment-method-2.png";
import paymentMethod3 from "../../images/images/passport-size-photos-delivery/payment-method-3.png";
import paymentMethod4 from "../../images/images/passport-size-photos-delivery/payment-method-4.png";

import OrderForm from "./OrderForm/OrderForm";
import AppCarusal from "./Carusal/AppCarusal";
import { toggleAccordian } from "../../utils";
import PassportSizesChart from "./PassportSizesChart/PassportSizesChart";
import NewAppCarusal from "../PassportSizePhotoDelivery/Swiper/NewCarusal";

const Banner = () => {
  const [data, setData] = useState([
    {
      question: "What if my picture gets rejected?",
      ans: [
        "We have processed over 50,000+ passport size pictures and have a 100% acceptance rate, whether it’s a visa/passport application or a driver’s license. We also provide a full money-back guarantee in case it gets rejected.🙂",
      ],
    },
    {
      question: "What is Passport Size Photo Delivery service?",
      ans: [
        "With our passport size photos delivery service you can take a photo of your self via a mobile phone (as a selfie) or someone else can take of you, following our guidelines and we will convert the image into a passport size photo and send you the soft copy via email and deliver the hard copy to your location.",
        "For some passport's and visa's like UAE visa, Emirates ID, USA passport, USA visa, USA lottery photo etc we check the photo online before sending to you to have a guaranteed acceptance."
      ],
    },
  ]);
  const [adultImages, setAdultImages] = useState([]);
  const [babyImages, setBabyImages] = useState([]);
  const [kidImages, setKidImages] = useState([]);
  function scroll() {
    const form = document.getElementById("passport-form");
    if (form) {
      form.scrollIntoView({ behavior: "smooth" });
    }
  }

  async function getBeforeAfterObj(number, category) {
    const beforeImgSrc = await import(
      `../../images/images/passport-size-photos-delivery/before-after/${category}-passport-size-photo-sample${number ? `-${number}` : ''}-before.jpg`
    );
    const afterImgSrc = await import(
      `../../images/images/passport-size-photos-delivery/before-after/${category}-passport-size-photo-sample${number ? `-${number}` : ''}-after.jpg`
    );
    return {
      beforeImgSrc: beforeImgSrc.default,
      afterImgSrc: afterImgSrc.default,
    };
  }

  async function loadImages() {
    setAdultImages([
      await getBeforeAfterObj(null, BeforeAfterImageCategory.Man),
      await getBeforeAfterObj(1, BeforeAfterImageCategory.Woman),
      await getBeforeAfterObj(null, 'adult'),
      await getBeforeAfterObj(2, BeforeAfterImageCategory.Woman),
      await getBeforeAfterObj(null, 'emirates-id'),
      await getBeforeAfterObj(3, BeforeAfterImageCategory.Woman),
      await getBeforeAfterObj(null, 'uae-visa'),
      await getBeforeAfterObj(null, 'female'),
    ]);

    setKidImages([
      await getBeforeAfterObj(1, BeforeAfterImageCategory.Kid),
      await getBeforeAfterObj(2, BeforeAfterImageCategory.Kid),
      await getBeforeAfterObj(3, BeforeAfterImageCategory.Kid),
      await getBeforeAfterObj(null, 'kid-emirates-id'),
    ]);

    setBabyImages([
      await getBeforeAfterObj(1, BeforeAfterImageCategory.Baby),
      await getBeforeAfterObj(2, BeforeAfterImageCategory.Baby),
      await getBeforeAfterObj(3, BeforeAfterImageCategory.Baby),
      await getBeforeAfterObj(4, 'new-born'),
    ]);
  }

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const data = await loadImages();
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="wrapper-banner">
        <div className="homeSectionPassport passportDeliverySectionHome">
          <div className="homeSectionLeftContent photoDeliveryHomeSection" style={{width:"100%"}}>
            <div>
              <h2 className="cover-photo-heading">
                GET YOUR <br />
                PASSPORT PHOTO <br />
                DELIVERED TO <br />
                YOU! <br />
              </h2>
            </div>
            <div className="description cover-photo-content">
              100% Government Compliant, Passport Size Photo Delivery in Dubai!
            </div>
            <div className="orderPlayButton">
              <button className="orderButton heading-h3" onClick={scroll}>
                Place Your Order
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="servics-tittle bg-darkgreen">
        <div className="container ">
          <div className="tittle-bg-clr p-15 txt-center">
            <h2>
              <span className="free-shipping-extra-bold">
                {" "}
                Same day delivery in Dubai{" "}
              </span>

              <span className="free-shipping-medium">
                {" "}
                between 8:30 pm to 12 am
              </span>
              <span className="free-shipping-extra-bold">
                {" "}
                (except Saturdays)
              </span>
            </h2>
            <p className="txt-center free-shipping-sub-heading">
              For all orders placed before 8:00 pm, same day delivery – if you
              want an exception, please get in touch with us
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="itWorks services-title">
          <h2 className="heading-h2">How It Works?</h2>
        </div>
        <div className="stepRow">
          <div className="stepRoot">
            <div className="stepImage">
              <img
                src={itWorkStep1}
                style={{ width: "250px" }}
                alt="itWorkStep1"
              />
            </div>
            <h2 className="heading-h3">Step 1</h2>
            <div className="content">
              Take A Picture With Your Phone & Upload In The Form Below
            </div>
            <p className="content">
              Follow the Guidelines and upload a picture in the form below.
            </p>
          </div>
          <div className="stepRoot">
            <div className="stepImage">
              <img
                src={itWorkStep2}
                style={{ width: "250px" }}
                alt="itWorkStep2"
              />
            </div>
            <h2 className="heading-h3">Step 2</h2>
            <div className="content">Make The Payment Online</div>
            <p className="content">
              Make the payment online using our secure payment gateway.
            </p>
          </div>
          <div className="stepRoot">
            <div className="stepImage">
              <img
                src={itWorkStep3}
                style={{ width: "250px" }}
                alt="sameDay"
              />
            </div>
            <h2 className="heading-h3">Step 3</h2>
            <div className="content">Get It Delivered To Your Doorstep!</div>
            <p className="content">
              We edit the picture, print it out, and deliver it to your home.
              Same day after 11:30 pm (except Saturdays).
            </p>
          </div>
        </div>
      </div>
      <PassportSizesChart />
      <div className="rules cont ainer">
        <h2 className="heading-h2">
        Guidelines for taking photo from home for passport size photo delivery
        </h2>
        {adultImages.length > 0 &&
          kidImages.length > 0 &&
          babyImages.length > 0 && (
            <NewAppCarusal
              carouselName="adult"
              adultImages={adultImages}
              kidImages={kidImages}
              babyImages={babyImages}
            />
          )}
      </div>

      {/* <div className="rules container">
    <div className="">
        <h2 className="heading-h2">
            Rules for taking the Photograph with your Phone for Passport Size Photos Delivery
        </h2>
        <ul className="content">
            <li>Keep straight face</li>
            <li>Look straight into the camera lense</li>
            <li>Your eyes must be open and visible</li>
            <li>No hair on face (if female, please don’t hide your
                eyebrows or any part of face with hair)</li>
            <li>No smiling, keep a neutral expression</li>
            <li>Preferable to not wear eye glasses</li>
            <li>Ears should show (if female, this is highly recommended but
                not necessary - for scarfed women, you can ignore)</li>
        </ul>
    </div>
</div>

{adultImages.length > 0 &&
<AppCarusal carouselName="adult" images={adultImages}/>}

<div className="container rules">
    <h2 className="heading-h2">
        How to take a photo of a Baby for a passport size photo?
    </h2>
    <ul className="content">
        <li>Have the baby's face straight</li>
        <li>Make the baby look straight into the camera lense or somewhere towards the phone</li>
        <li>Eyes must be open and visible (some customer agents allow closed eyes too but don't risk)</li>
        <li>No hair or anything on the face of baby</li>
        <li>Take picture with neutral expression, preferably poker faced, no smiling
        </li>
        <li> Preferable to have the baby's mouth closed</li>
        <li>Ears should show (this is highly recommended but not necessary)</li>
    </ul>
    <p className="content"> Pro Tip - Make the baby lie on a flat surface like a bed and position the phone camera on
        top of
        the baby's forhead and then click multiple photos
    </p>
</div> 
{babyImages.length > 0 &&
<AppCarusal carouselName="baby" images={babyImages}/>}

<div className="container rules">
    <h2 className="heading-h2">
        Take a photo of your kid from the comfort of you home using the below guidelines
    </h2>
    <ul className="content">
        <li> Make the kid stand or sit straight with a straight face</li>
        <li>Make him look straight into the camera lense</li>
        <li>Eyes must be open and visible</li>
        <li>No hair on face (if female, please don’t hide eyebrows or any part of face with hair)</li>
        <li>No smiling, a neutral expression mus tbe kept
        </li>
        <li>Preferable that the kid is not wearing eye glasses</li>
        <li>The kid's ears should show (if female, this is highly recommended but not necessary)</li>
    </ul>
</div>
{kidImages.length > 0 &&
<AppCarusal carouselName="kid" images={kidImages}/>} */}

      <div id="passport-form">
        <OrderForm />
      </div>

      <div className="container">
        <div>{/* <app-order-form></app-order-form> */}</div>

        <div className="paymentPictureSection container">
          <img src={paymentMethod1} alt="paymentMethod1" />
          <img src={paymentMethod2} alt="paymentMethod2" />
          <img src={paymentMethod3} alt="paymentMethod3" />
          <img src={paymentMethod4} alt="paymentMethod4" />
        </div>
      </div>
      <div className="bg-black">
        <div className="countingSection container">
          <div className="countingContainer">
            <div className="cover-photo-heading">49,371</div>
            <div className="cover-photo-content">
              Passport Size Photos <br /> Delivered in UAE
            </div>
          </div>
          <div className="countingContainer">
            <div className="cover-photo-heading">98%</div>
            <div className="cover-photo-content">On Time Delivery</div>
          </div>
          <div className="countingContainer">
            <div className="cover-photo-heading">100%</div>
            <div className="cover-photo-content">Government Compliant</div>
          </div>
        </div>
      </div>

      <div className="bg-faq">
        <div className="faqSection container">
          <h2 className="faqHeading playfair-heading-800">FAQ's</h2>
          <div style={{ paddingBottom: "40px" }}>
            <div className="dosis-600">
              {data.map((item, i) => {
                return (
                  <div key={i}>
                    <button
                      className="accordion"
                      onClick={(e) => toggleAccordian(e, i, data, setData)}
                    >
                      {item.question}
                    </button>
                    <div className="panel" hide={!item.isActive}>
                      {item.ans.map((line,index) => {
                        return <p key={index}>{line}</p>;
                      })}
                    </div>
                    <hr className="faqLine" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
