import React from 'react'

import logo1 from '../../../images/images/logo-designing/logo-samples/custom-logo-dubai.png'
import logo2 from '../../../images/images/logo-designing/logo-samples/custom-logo.png'
import logo3 from '../../../images/images/logo-designing/logo-samples/logo-artwork.png'
import logo4 from '../../../images/images/logo-designing/logo-samples/logo-design-1.png'
import logo5 from '../../../images/images/logo-designing/logo-samples/logo-design-2.png'
import logo6 from '../../../images/images/logo-designing/logo-samples/logo-designing-dubai.png'
import logo7 from '../../../images/images/logo-designing/logo-samples/logo-designing-uae.png'
import logo8 from '../../../images/images/logo-designing/logo-samples/logo-designing.png'
import logo9 from '../../../images/images/logo-designing/logo-samples/logo-designs.png'
import logo10 from '../../../images/images/logo-designing/logo-samples/logo-sample.png'



import "./style.scss"
const WorkSmaple = () => {
  return (
    <div className='work-sample-container'>

        <h className="heading-logo-text">CREATIVE LOGO DESIGN PORTFOLIO</h>
        <h5>Take a look at our creative logo designs</h5>
        <div className='logo-work-container'>
            <img src={logo1} alt="logo" className="logo-worksection-img"/>
            <img src={logo2} alt="logo" className="logo-worksection-img"/>
            <img src={logo3} alt="logo" className="logo-worksection-img"/>
            <img src={logo4} alt="logo" className="logo-worksection-img"/>
            <img src={logo5} alt="logo" className="logo-worksection-img"/>
            <img src={logo6} alt="logo" className="logo-worksection-img"/>
            <img src={logo7} alt="logo" className="logo-worksection-img"/>
            <img src={logo8} alt="logo" className="logo-worksection-img"/>
            <img src={logo9} alt="logo" className="logo-worksection-img"/>
            <img src={logo10} alt="logo" className="logo-worksection-img"/>
            
        </div>

    </div>
  )
}

export default WorkSmaple