import React,{useState,useEffect} from 'react'
import "./styles.scss"
import printingPhoto from '../../images/images/photos-printing-delivery/photo-printing-delivery.png';
import PhotoPrintingForm from './PhotoPrintingForm/PhotoPrintingForm';
import { toggleAccordian } from '../../utils';


const Banner = () => {
    const [data,setData] = useState([
        {
            "question": "What’s special about us?",
            "ans": [
              "Unlike any other studio, we crop the pictures manually to adjust to the size of print as best as possible.",
              "For example if a person head is being cropped off in the auto print, we edit the photo in photoshop and adjust the size to get the best print possible.",
            ]
          },
      
          {
            "question": "What paper do we print on?",
            "ans": ["Glossy photo paper, with amazing print quality having vibrant colors and superb contrast.🙂"]
          },
      ])
    function scroll() {
        const form = document.getElementById('printing-form');
        if(form){
            form.scrollIntoView({ behavior: "smooth" });
        }
    }
    useEffect(()=>{
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
      },[]);
  return (
    <div className="printing-delivery--wrapper">
      <div className="homeSection" id="home">
        <div className="homeSectionLeftContent">
          <h1 className="heading"></h1>
          <div className="description"></div>
        </div>
      </div>
      <div className="printing-delivery">
        <div className="servics-tittle bg-darkgreen">
          <div className="container ">
            <div className="tittle-bg-clr p-15 txt-center">
              <h2>
                <span className="free-shipping-extra-bold">
                  {" "}
                  Delivery within 24 hours{" "}
                </span>

                <span className="free-shipping-medium">
                  {" "}
                  with minimum order value of
                </span>
                <span className="free-shipping-extra-bold"> only AED 40</span>
              </h2>
              <p className="txt-center free-shipping-sub-heading">
                In most cases delivery within same day, hence, do call us if any
                urgency
              </p>
              <p className="txt-center free-shipping-sub-heading">
              Please Note: We manually crop and adjust each photo in case you haven't sized them correctly, to give the best result
              </p>
            </div>
          </div>
        </div>
        <div className="photo-delivery bg-lightpink">
          <div className="container bg-lightpink">
            <h2 className="txt-center heading-h1 txt-bold">
              Photo Printing Delivery
            </h2>
            <p className="txt-center content">
              Get your photos printed from the comfort of your home by uploading
              photos to our form below, pay online and get your photos delivered
              to you within the same day.
            </p>
            <div className="row">
                <div className="col-md-7">
                    <img className="photo-sec-img" src={printingPhoto} alt="printingPhoto"/>
                </div>
                <div className="col-md-5">
                    <div className="photo-txt content">
                        <ul>
                            <li>Our prints have rich and vibrant colors</li>
                            <li>Have 11 sizes to pick from</li>
                            <li>Very quick and simple to use our online form below</li>
                            <li>Competitive prices with <b>same day delivery</b> in Dubai for orders above 40 AED</li>
                            <li>We mostly use glossy photo paper which is the preferred choice for most clients and
                                professions</li>
                        </ul>
                        <a className="homeSectionLeftContent photo-sec-txt">
                            <button onClick={scroll} className="orderButton heading-h1" >
                                Place Your Order</button>
                        </a>
                        
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div className="container">
        <h2 className="headingSizeChart playfair-heading-800">
          {" "}
          Photo Print Size Chart and Prices
        </h2>
        <div className="photoPrintingSizesChart">
          <table className="photoSize content">
            <tr>
              <th>Sizes in inches</th>
              <th>Sizes in cm</th>
              <th>Price (AED)</th>
            </tr>
            <tr>
              <td>4"x6"</td>
              <td>10x15</td>
              <td>6</td>
            </tr>
            <tr>
              <td>5"x"7</td>
              <td>13x18</td>
              <td>8</td>
            </tr>
            <tr>
              <td>5.8"x"8.3 (A5)</td>
              <td>14.8x21</td>
              <td>9</td>
            </tr>
            <tr>
              <td>8.0"x"10.0</td>
              <td>20.32x25.4</td>
              <td>10</td>
            </tr>
            <tr>
              <td>8.3"x"11.7 (A4)</td>
              <td>21x29.7</td>
              <td>11</td>
            </tr>
            <tr>
              <td>12"x"12</td>
              <td>30.48x30.48</td>
              <td>25</td>
            </tr>
            <tr>
              <td>11.7"x"16.5 (A3)</td>
              <td>29.7x42</td>
              <td>27</td>
            </tr>
          </table>
        </div>
    </div>

    <div id="printing-form">
        <PhotoPrintingForm/>
    </div>

    <div className="bg-faq">
        <div className="faqSection container">
            <h2 className="faqHeading playfair-heading-800">FAQ's</h2>
            <div style={{paddingBottom: '40px'}}>

            <div className="dosis-600">
                {data.map((item,i)=>{
                    return <div key={i}>
                        <button className='accordion' onClick={(e)=>toggleAccordian(e,i,data,setData)}>{item.question}</button>
                        <div className='panel' hide={!item.isActive}>
                            {item.ans.map((line)=>{
                                return <p>{line}</p>
                            })}
                        </div>
                        <hr className='faqLine'/>
                    </div>
                })}
            </div>
            </div>
        </div>
    </div>

    </div>
    </div>
  );
};

export default Banner;
