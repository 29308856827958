import React from 'react'
import './Banner.scss'

function Banner({title}) {
  return (
    <div className="shopify-section page_section_heading">
        <div className="page-head tc pr oh page_head_cat_heading page_head_411754299612">
            <div className="container pr">
                <h1 className="mb__5 cw">{title}</h1>
                <div className="mt__5 mg__0 rtet4"></div>
            </div>
        </div>
    </div>
  )
}

export default Banner