import React, { useEffect, useState } from 'react'
import {Swiper,SwiperSlide} from 'swiper/react';
import { Navigation } from 'swiper';
import "./styleEcomerecSlider.scss"
import DummyCard from '../../../components/EComereceStore/TrendingProduct';
import axios from 'axios';
import ProductCard from '../../../components/EComereceStore/ProductCard';

function TrendingProducts() {

  const swiperRef = React.useRef()
  const [products, setProducts] = useState(null)

  const handlePrev = function(){
    swiperRef.current?.slidePrev()
  }

  const handleNext = function(){
    swiperRef.current?.slideNext()
  }
  
  useEffect(()=>{
      const getProducts =async ()=>{
          const {data} = await axios.get(process.env.REACT_APP_API_URL + '/api/assets/products/by-tag/'+'trending');
          const {products} = data
          setProducts(products)
      }
      getProducts()
  },[])

  return (
    <div className='SectionWrapper'>
    <div className='container' >
        <section className="services-tittle">
        <div className="container" >
          <h2 className="txt-center heading-h2">Trending Now</h2>
        </div>
      </section>
       {products && <section style={{maxWidth:"1260px"}}>
        <div className='cards-wrapper'>
        <Swiper className='equal_nt ratio_nt on_list_view_false'
        style={{ width:"100%", height:"100%"}}
          modules={[Navigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
            
          }}
          breakpoints={{
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 4,
            },
          }}
          spaceBetween={20}>
            {products.map((product)=>{
              return <SwiperSlide style={{background:'white'}}><ProductCard product={product} /></SwiperSlide>
            })}
          </Swiper>
          <i className='btn--next fa-solid fa-chevron-right' onClick={handleNext} type="next"></i >
          <i className='btn--prev fa-solid fa-chevron-left' onClick={handlePrev} type="prev"></i >  
        </div></section>}
    </div>
  </div>
  )
}

export default TrendingProducts