import React from 'react'
import cameraPhotographyWedding from "../../images/images/wedding-photography/camera-photography-wedding.jpg"
import weddingPhotographySample from "../../images/images/wedding-photography/wedding-photography-sample.jpg"
import bestWeddingPhotographyDaubi from "../../images/images/wedding-photography/best-wedding-photography-dubai.jpg"
import weddingPhotographyOzelu from "../../images/images/wedding-photography/wedding-photography-ozelu.jpg"
import OzeluPhotoSample from "../../images/images/wedding-photography/ozelu-wedding-photo-sample.jpg"
import weddingPhotoShotDubai from "../../images/images/wedding-photography/wedding-photoshoot-dubai.jpg"
import AppReservationForm from '../ReservationForm/AppReservationForm';
import { scroll } from "../../utils"
import "./style.scss"


const Banner = () => {
    const scrollToForm = () => {
        const formRef = document.getElementById('reservation-form');
        scroll(formRef);
    }
  return (
    <div className='wrapper-wedding-photography'>
        <div class="banner-photography">
            <div class="row">
                <div class="col-md-6 pl-12">
                <h2 class="re-cover">Capture Your Beautiful Moments</h2>
                <p>If you need a photographer for your special day at affordable prices and guaranteed good results, call
                us, WhatsApp us
                or simply submit our contact form.</p>
                <div class="what-center">
                <a href="https://wa.me/971545679479"><button>WhatsApp to Book</button></a>
                <button onClick={()=>scrollToForm()}>Go to Contact Form</button>
                </div>
                </div>
                <div class="col-md-6">
                <img src={cameraPhotographyWedding} alt="cameraPhotographyWedding" />
            </div>
            </div>
        </div>
        <section class="php-photography">
    <div class="container-photography">
        <div class="row pt-30">
            <div class="col-md-6 pl-12">
                <h2>Wedding Photography in Dubai</h2>
                <p>
                    Ozelu Studio wedding photographers welcome you to the realm of
                    gorgeous and genuine wedding photography. Our studio provides a
                    variety of wedding photography techniques in Dubai, Sharjah, and
                    anywhere else in the United Arab Emirates.

                    <br />
                    <br />
                    We understand that everyone want's their wedding to have a unique
                    touch, hence, each of our clients receives a tailored approach. On the
                    eve or much before the wedding, the photographer must meet with the
                    bride and groom in person to discuss all aspects of photography on
                    such an important day, build a personal bond, and learn what the happy
                    couple appreciate the most.
                    <br /><br />
                    Our photographers exclusively employ cutting-edge technology. As a
                    result, we have been able to produce high-quality photos. All of the
                    photos are processed many times in various software solutions like
                    photoshop, lightroom and other photo processing software's. We
                    personally edit each shot, bringing it to perfection so that you may
                    enjoy the stunning images for many years to come. Our spectacular
                    picture books with special designs are also handcrafted - so that you
                    may feel as much of the individuality of your occasion as possible.
                    <br /><br />

                    This page features our clients' most popular wedding photography
                    packages. However, if you want anything specific, we would gladly
                    design a one-of-a-kind package for you that totally fulfills your
                    requirements.
                </p>
            </div>
            <div class="col-md-5">
            <img src={weddingPhotographySample} alt="weddingPhotographySample" />
            </div>
        </div>
        <section class="services-tittle-photography">
            <div class="container-photography">
                <h2 class="txt-center">Wedding Photoshoot Packages</h2>
            </div>
        </section>
        </div>
</section>
<section class="php-photography">
            <div class="container-photography">
            <div class="row">
            <div class="col-md-4">
                <h2 className='pl-custom'>Silver Package<br/> 800 AED</h2>
                <ul>

                    <li>1 photographer</li>
                    <li>1 hour</li>
                    <li>Unlimited number of high quality images with post editing on light and tone correction - minimum
                        100
                        images</li>
                    <li>All photos shared via email within 7 days</li>
                    <li>An extra hour: 800 AED</li>
                </ul>
            </div>
            <div class="col-md-4">
                <h2 className='pl-custom'>Gold Package <br/>1,000 AED</h2>
                <ul>
                    <li>1 photographer</li>
                    <li>1 hour</li>
                    <li>Professional studio lights to be brought at event</li>
                    <li>Unlimited number of high quality images with post editing on light and tone correction - minimum
                        100
                        images</li>
                    <li>10 high resolution images with professional
                        retouching (Photoshop)</li>
                    <li>All photos shared via email within 7 days</li>
                    <li>An extra hour: 1,000 AED</li>
                </ul>
            </div>
            <div class="col-md-4">
                <h2 className='pl-custom'>Platinum Package <br/>1,600 AED</h2>
                <ul>
                    <li>2 photographer</li>
                    <li>1 hour</li>
                    <li>Unlimited number of high quality images with post editing on light and tone correction - minimum
                        200
                        images</li>
                    <li>10 high quality photo retouch</li>
                    <li>All photos shared via email within 7 days</li>
                    <li>An extra hour: 1,000 AED</li>
                </ul>
                </div>
                </div>
                </div>
        </section>
        <section class="services-tittle-photography">
    <div class="container-photography">
        <h2>Our Wedding Photography Portfolio</h2>
    </div>
</section>
<section class="gallery-photography">
    <div class="container-photography">
        <div class="row pb-25">
            <div class="col-md-12">
            <img src={bestWeddingPhotographyDaubi} alt="bestWeddingPhotographyDaubi" />
            </div>
        </div>
        <div class="row pb-25">
            <div class="col-md-12">
            <img src={weddingPhotographyOzelu} alt="weddingPhotographyOzelu" />
            </div>
        </div>
        <div class="row pb-25">
            <div class="col-md-12">
            <img src={OzeluPhotoSample} alt="OzeluPhotoSample" />
            </div>
        </div>
        <div class="row pb-25">
            <div class="col-md-12">
            <img src={weddingPhotoShotDubai} alt="weddingPhotoShotDubai" />
            </div>
        </div>
    </div>
</section>

    <div>
        <AppReservationForm service="Wedding Photography" />
    </div>
    </div>
  )
}

export default Banner