import React, { useEffect } from 'react'
import Banner from './Banner'
import { Helmet } from 'react-helmet-async'

const PassportSizePhotoDelivery = () => {
  useEffect(()=>{
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  },[])
  return (
    <div>
      <Helmet>
        <title>Passport Size Photos Delivery Dubai Same Day – 100% Acceptance Guarantee</title>
        <meta name='description' content='The only online photography studio in Dubai to deliver passport size photos to your location in same day. Ozelu photo studio offers 100% acceptance guarantee including Emirates ID.'></meta>
      </Helmet>
        <Banner/>
    </div>
  )
}

export default PassportSizePhotoDelivery