import React,{useEffect,useState} from 'react'
import { useDropzone } from 'react-dropzone';
import PdfIcon from '../images/pdf.png'
import * as PDFJS from 'pdfjs-dist'
import pdfjsworker from 'pdfjs-dist/build/pdf.worker.entry'

function FileDropzone({files,setFiles,pages,setPages, showCounters}) {
    const [thumbs,setThumbs] = useState(null);

    async function getPages(fileInfo){
        let result =await (function(fileInfo){
            let reader = new FileReader()
            return new Promise((resolve, reject) => {    
                if (fileInfo) {
                    if(fileInfo.type === 'application/pdf'){
                        reader.readAsArrayBuffer(fileInfo)
                        reader.onloadend = () => {
                            var typedarray = new Uint8Array(reader.result);
                            const loadingTask = PDFJS.getDocument(typedarray).promise.then(function(pdf){
                                console.log("Pages>>",pdf.numPages)
                                resolve(Object.assign(fileInfo,{pages:pdf.numPages})) 
                            }); 
                            // let pages = reader.result.toString().match(/\/Type[\s]*\/Page[^s]/g).length
                            // resolve(Object.assign(fileInfo,{pages})) 
                        }
                    }
                    else{
                        resolve(Object.assign(fileInfo,{pages:1}))
                    }
                }
            });
        })(fileInfo);
        return result
    }

    function getFilesObject(files){
        return files.map((file)=>{
            if(file.type === 'application/pdf'){
                return Object.assign(file,{
                    preview: PdfIcon,
                    count: file.count || 1
                })
            }
            // else if(file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'){
            //     return Object.assign(file,{
            //         preview: DocxIcon,
            //         count: file.count || 1
            //     })
            // }
            else{
                return Object.assign(file,{
                    preview: URL.createObjectURL(file),
                    count: file.count || 1
                })
            }
        })
    }

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'application/pdf': ['.pdf'],
            // 'application/msword': ['.doc'],
            // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'image/*': []
        },
        onDrop: acceptedFiles => {
            let newFiles = acceptedFiles.map(async (fileInfo) => {
                return await getPages(fileInfo)
            });
            Promise.all(newFiles).then((items)=>{
                console.log("New items",items)
                setFiles([...files,...getFilesObject(items)])
            })
            // setFiles([...files,...getFilesObject(acceptedFiles)])
        }
    });

    function onRemove(e,event) {
        e.stopPropagation();        
        const fileToRemove = files.splice(files.indexOf(event), 1);
        const newFiles = files.map((file)=>{
            if(file !== fileToRemove ) return file;
        })
        let allPages = newFiles.map(async(fileInfo) => {
            return await getPages(fileInfo);
        });
        Promise.all(allPages).then((values)=>{
            setFiles([...getFilesObject(values)])
        })
        // setFiles([...getFilesObject(newFiles)])
    }

    function setFileCounter(e,file,type){
        e.preventDefault()
        let value = 1;
        let newFiles = files.map((innerFile)=>{
            if(file == innerFile){
                value = parseInt(file.count)
                if(type == 'plus'){
                    return Object.assign(innerFile, {count: value + 1});
                }else{
                    if(value > 1) return Object.assign(innerFile, {count: value - 1});
                }
            }
            return innerFile
        })
        setFiles(newFiles)    
    }

    function renderThumbs(){
        setThumbs(files?.map((file,i) => (
            <div key={i} className="thumb--img">
                <div className='thumb--img-item' style={{backgroundImage: `url(${file.preview})`}}>
                    {showCounters && <div class="q-counter" onClick={(e)=>e.stopPropagation()}>
                        <span class="down" onClick={(e)=>setFileCounter(e,file,'minus')}>-</span>
                        <input type="text" value={file.count} readOnly />
                        <span class="up" onClick={(e)=>setFileCounter(e,file, 'plus')}>+</span>
                    </div>}
                </div>
                <div style={{width: '100px',background: '#000000',opacity: '0.7',textAlign: 'center'}} >
                    <p style={{color: 'white',margin: '0'}}>{file.name?.slice(0,20)+'...'}</p>
                </div>
                <div class="ng-star-inserted" className='badge' onClick={(e)=>onRemove(e,file)}>
                    <svg style={{height: '10px',width: '10px'}}>
                        <line style={{stroke: '#fff',strokeWidth: '2px'}} x1="0" y1="0" x2="10" y2="10"></line>
                        <line style={{stroke: '#fff',strokeWidth: '2px'}} x1="0" y1="10" x2="10" y2="0"></line>
                    </svg>
                </div>
            </div>
        )))
    }

    useEffect(()=>{
        renderThumbs()
    },[files])

    useEffect(() => {
        renderThumbs()
        setFiles([...files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))]);
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    useEffect(()=>{
        PDFJS.GlobalWorkerOptions.workerSrc = pdfjsworker;
    },[])

    return (
        <div {...getRootProps({className: 'custom-dropzone selectForm'})} >
            <input {...getInputProps()} />
            {files.length <= 0 && <div style={{margin: '10px auto',textAlign: 'center'}}>
            <h2>Drop or browse files here</h2>
            </div>}
            {thumbs}
        </div>
    )
}

export default FileDropzone