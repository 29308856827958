import React, { useEffect, useState } from 'react'
import './CollectionGridItems.scss'
import ProductCard from '../../components/EComereceStore/ProductCard'
import QuickShopModal from '../../components/EComereceStore/QuickShopModal'
import CollectionFooter from './CollectionFooter'

const sortings = [
    {
        label: 'Alphabetically, A-Z',
        value: 'title-ascending'
    },
    {
        label: 'Alphabetically, Z-A',
        value: 'title-descending'
    },
    {
        label: 'Price, low to high',
        value: 'price-ascending'
    },
    {
        label: 'Price, high to low',
        value: 'price-descending'
    },
    {
        label: 'Date, old to new',
        value: 'created-ascending'
    },
    {
        label: 'Date, new to old',
        value: 'created-descending'
    }
]

function CollectionGridItems({products,currentPage,totalCount,changePage,changeSort}) {

    const [productsPerLineD,  setProductsPerLineD] = useState(4)
    const [productsPerLineT, setProductsPerLineT] = useState(3)
    const [productsPerLineM, setProductsPerLineM] = useState(2)

    const [sortBy, setSortBy] = useState(sortings[0])
    const [sbOpened, setSbOpened] = useState(false)
    
    
    
    function setViewSizeD(e, size){
        e.preventDefault();
        setProductsPerLineD(size);
    }

    function setViewSizeT(e, size){
        e.preventDefault()
        setProductsPerLineT(size)
    }

    function setViewSizeM(e, size){
        e.preventDefault()
        setProductsPerLineM(size)
    }

    function onSortByClick(e){
        e.preventDefault()
        e.stopPropagation()
        setSbOpened(!sbOpened)
    }

    function onChangeSortBy(e,sort){
        e.preventDefault()
        e.stopPropagation()
        setSortBy(sort)
        changeSort(sort.value)
        setSbOpened(false)
    }

  return (
    <>
    {/* <QuickShopModal/> */}
    <div class="container container_cat pop_default cat_default mb__60">
        <div class="cat_toolbar row fl_center al_center mt__30">
            <div class="cat_filter col op__0 pe_none">
                <a rel="nofollow" href="#" data-no-instant="" data-opennt="#shopify-section-nt_filter2" data-pos="left" data-remove="true" data-class="popup_filter" data-bg="hide_btn" class="has_icon btn_filter mgr">
                    <i class="iccl fwb iccl-filter fwb mr__5"></i>
                Filter</a>
                <a rel="nofollow" href="#" data-no-instant="" data-id="#shopify-section-nt_filter2" class="btn_filter js_filter dn mgr">
                    <i class="iccl fwb iccl-filter fwb mr__5"></i>Filter
                </a>
            </div>
            <div class="cat_view col-auto" data-link="/collections/children?sort_by=manual&amp;">
                <div class="dn dev_desktop dev_view_cat">
                    <a rel="nofollow" href="#" data-mode="list" data-dev="dk" data-col="listt4" class="pr mr__10 cat_view_page view_list view_listt4" onClick={(e)=>setViewSizeD(e,1)}></a>
                    <a rel="nofollow" href="#" data-mode="grid" data-dev="dk" data-col="6" class={productsPerLineD === 2 ? 'pr mr__10 cat_view_page view_6 view-active' : 'pr mr__10 cat_view_page view_6'} onClick={(e)=>setViewSizeD(e,2)}></a>
                    <a rel="nofollow" href="#" data-mode="grid" data-dev="dk" data-col="4" class={productsPerLineD === 3 ? 'pr mr__10 cat_view_page view_4 view-active' : 'pr mr__10 cat_view_page view_4'} onClick={(e)=>setViewSizeD(e,3)}></a>
                    <a rel="nofollow" href="#" data-mode="grid" data-dev="dk" data-col="3" class={productsPerLineD === 4 ? 'pr mr__10 cat_view_page view_3 view-active' : 'pr mr__10 cat_view_page view_3'} onClick={(e)=>setViewSizeD(e,4)}></a>
                    <a rel="nofollow" href="#" data-mode="grid" data-dev="dk" data-col="15" class={productsPerLineD === 5 ? 'pr mr__10 cat_view_page view_15 view-active' : 'pr mr__10 cat_view_page view_15' } onClick={(e)=>setViewSizeD(e,5)}></a>
                    <a rel="nofollow" href="#" data-mode="grid" data-dev="dk" data-col="2" class={productsPerLineD === 6 ? 'pr cat_view_page view_2 view-active' : 'pr cat_view_page view_2'} onClick={(e)=>setViewSizeD(e,6)}></a>
                </div>
                <div class="dn dev_tablet dev_view_cat">
                    <a rel="nofollow" data-link="/collections/children?sort_by=manual&amp;" data-no-instant="" href="#" data-dev="tb" data-col="listt4" class="pr mr__10 cat_view_page view_list view_listt4"></a>
                    <a rel="nofollow" data-no-instant="" href="#" data-dev="tb" data-col="6" class={productsPerLineT === 2 ? 'pr mr__10 cat_view_page view_6 view-active' : 'pr mr__10 cat_view_page view_6'} onClick={(e) => setViewSizeT(e,2)}></a>
                    <a rel="nofollow" data-no-instant="" href="#" data-dev="tb" data-col="4" class={productsPerLineT === 3 ? 'pr mr__10 cat_view_page view_4 view-active' : 'pr mr__10 cat_view_page view_4'} onClick={(e) => setViewSizeT(e,3)}></a>
                    <a rel="nofollow" data-no-instant="" href="#" data-dev="tb" data-col="3" class={productsPerLineT === 4 ? 'pr cat_view_page view_3 view-active' : 'pr cat_view_page view_3'} onClick={(e) => setViewSizeT(e,4)}></a>
                </div>
                <div class="flex dev_mobile dev_view_cat">
                    <a rel="nofollow" data-link="/collections/children?sort_by=manual&amp;" data-no-instant="" href="#" data-dev="mb" data-col="listt4" class="pr mr__10 cat_view_page view_list view_listt4"></a>
                    <a rel="nofollow" data-no-instant="" href="#" data-dev="mb" data-col="12" class={productsPerLineM === 1 ? 'pr mr__10 cat_view_page view_12 view-active' : 'pr mr__10 cat_view_page view_12'} onClick={(e)=> setViewSizeM(e,1)}></a>
                    <a rel="nofollow" data-no-instant="" href="#" data-dev="mb" data-col="6" class={productsPerLineM === 2 ? 'pr cat_view_page view_6 view-active' : 'pr cat_view_page view_6'} onClick={(e)=> setViewSizeM(e,2)}></a>
                </div>
            </div>
            <div class={sbOpened ? 'cat_sortby cat_sortby_js col tr opended' : 'cat_sortby cat_sortby_js col tr'} onClick={()=>setSbOpened(false)}>
                <a class="in_flex fl_between al_center sortby_pick" rel="nofollow" data-no-instant="" href="#" onClick={(e)=>onSortByClick(e)}>
                    <span class="sr_txt dn">{sortBy.label}</span>
                    <span class="sr_txt_mb">Sort by</span>
                    <i class="ml__5 mr__5 facl facl-angle-down"></i>
                </a>
                <div class="nt_sortby dn">
                    <svg class="ic_triangle_svg" viewBox="0 0 20 9" role="presentation">
                    <path d="M.47108938 9c.2694725-.26871321.57077721-.56867841.90388257-.89986354C3.12384116 6.36134886 5.74788116 3.76338565 9.2467995.30653888c.4145057-.4095171 1.0844277-.40860098 1.4977971.00205122L19.4935156 9H.47108938z" fill="#ffffff"></path>
                    </svg>
                    <div class="h3 mg__0 tc cd tu ls__2 dn_lg db">Sort by<i class="pegk pe-7s-close fs__50 ml__5"></i></div>
                    <div class="nt_ajaxsortby wrap_sortby">
                        {sortings.map((sort)=>{
                            return <a className={sortBy.value === sort.value ? 'truncate selected' : 'truncate'} href='#' onClick={(e)=>onChangeSortBy(e,sort)}>{sort.label}</a>
                        })}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-12">
                <div id="shopify-section-collection_page" class="shopify-section tp_se_cdt">
                    <div class="nt_svg_loader dn"></div>
                    <div class="on_list_view_false products nt_products_holder row fl_center row_pr_1 cdt_des_1 round_cd_false nt_cover ratio2_3 position_8 space_30 equal_nt nt_default">

                    {/* Items */}
                    {products && products.map((product)=>{
                        return <ProductCard sizeD={productsPerLineD} sizeT={productsPerLineT} sizeM={productsPerLineM} product={product}/>
                    })}

                    </div>
                    <div class="mt__40 mb__60"></div>
                </div>
                <CollectionFooter changePage={changePage} currentPage={currentPage} totalCount={totalCount}/>
            </div>
        </div>
    </div>
    </>
  )
}

export default CollectionGridItems