import React from 'react'
import './style.scss'

function CommingSoon() {
  return (
    <div className='comming-soon-container'>
        <p>Coming Soon!</p>
    </div>
  )
}

export default CommingSoon