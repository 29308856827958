

import coverImg from  "../../images/images/portrait-headhots/cover-photo-portrait-headshot.jpg"
import headshots from  "../../images/images/portrait-headhots/portrait-headshots.jpg"
import closeup from  "../../images/images/portrait-headhots/photo-shot-closeup.jpg"
import dubai from  "../../images/images/portrait-headhots/portrait-headshot-dubai.jpg"
import corporateHeadshotDubai from  "../../images/images/portrait-headhots/corporate-headshot-dubai.jpg"
import AmazingHeadShow from  "../../images/images/portrait-headhots/amazing-shadow-headshot.jpg"
import businessHeadShoeDubai from  "../../images/images/portrait-headhots/business-headshot-dubai.jpg"
import pinkBackgroundPortraitHeadshow from  "../../images/images/portrait-headhots/pink-background-portrait-headshot.jpg"
import yellowBackgroundHeadShot from  "../../images/images/portrait-headhots/yellow-background-headshot.jpg"
import couplePhotoShopDubai from  "../../images/images/portrait-headhots/couple-photoshoot-dubai.jpg"
import portraitHeadshotKids from  "../../images/images/portrait-headhots/kids-photoshoot.jpg"
import photoShopCloseUp from  "../../images/images/portrait-headhots/photo-shot-closeup.jpg"

import AppReservationForm from '../ReservationForm/AppReservationForm';
import { scroll } from "../../utils"
import "./style.scss"

const Banner = () => {
    const scrollToForm = () => {
        const formRef = document.getElementById('reservation-form');
        scroll(formRef);
    }
  return (
    <div className="wrapper-headshot-photography">
        <section className="banner-photography">
    <div className="row">
        <div className="col-md-6 pl-12">
            <h2 className="re-cover-headshot">Shoot Doesn’t End Until You Are Satisfied</h2>
            <p>Book a portrait headshot at your own home with full privacy and no rushing. Guarantee satisfaction; shoot
                doesn’t end until you are are satisfied.</p>
            <div className="what-center">
                <a href="https://wa.me/971545679479"><button>WhatsApp to Book</button></a>
                <button onClick={()=>scrollToForm()}>Go to Contact Form</button>
            </div>
        </div>
        <div className="col-md-6">
            <img src={coverImg} alt="coverImg" />
        </div>
    </div>
</section>
<section className="services-tittle-photography">
    <div className="container-photography">
        <h2 >Portrait Headshot Packages</h2>
        <p className="padding">Please book via whatsApp, by calling us or filling the form below.</p>
    </div>
</section>
<section className="php-photography">
    <div className="container-photography">
        <div className="row">
            <div className="col-md-6">
                <img src={headshots} alt="headshots"/>
                <h2>Silver Business Portrait Package<br/> 150 AED</h2>
                <ul>
                    <li>20 minutes studio photoshoot</li>
                    <li>1 person</li>
                    <li>1 Background; White, Grey or Black Background</li>
                    <li>1 outfit</li>
                    <li>Perfect for Linkedin profile or CV</li>
                    <li>1 high quality photo retouch</li>
                    <li>All pictures shared via email within 48 hours</li>
                </ul>
            </div>
            <div className="col-md-6">
                <img src={closeup} alt="closeup"/>
                <h2>Gold Business Portrait Package <br/>280 AED</h2>
                <ul>
                    <li>40 minutes studio photoshoot</li>
                    <li>1 person</li>
                    <li>2 Background; White, Grey or Black Background</li>
                    <li>2 outfit</li>
                    <li>Perfect for Linkedin profile or CV</li>
                    <li>3 high quality photo retouch</li>
                    <li>All pictures shared via email within 48 hours</li>
                </ul>
            </div>
        </div>
        <div className="row pt-30">
            <div className="col-md-7">
                <h2>Headshot Photography Dubai</h2>
                <p>In the highly competitive market of Dubai, United Arab Emirates, it is becoming increasing difficult
                    to distinguish
                    yourself from others. One of the recommendations given to potential candidates in big corporates is
                    to keep your
                    LinkedIn or business profile updated, of which one element is a clean portrait headshot.</p>
                <p>
                    Hence, if you are a looking to get a clean and natural looking business headshot for yourself then
                    Ozelu Studio is your
                    answer. At Ozelu Studio, we ensure that we click photos from a variety of angles with different
                    poses so you can choose
                    a perfect click that goes with your personal branding and personality.
                </p>
                <p>
                    In additional to portrait headshots we also do family portraits, kids portraits and couple portrait
                    headshots. Give us a
                    call to book your appointment at our private studio with no disturbance.
                </p>
            </div>
            <div className="col-md-5">
                <img src={dubai} alt="dubai"/>
            </div>
        </div>
    </div>
</section>
<section class="services-tittle-photography">
    <div class="container-photography">
        <h2 class="">Examples of photos from previous shoots</h2>
    </div>
</section>
<section className="gallery-photography">
    <div className="container-photography">
        <div className="row pb-25">
            <div className="col-md-6">
                <img src={corporateHeadshotDubai} alt="corporateHeadshotDubai"/>
            </div>
            <div className="col-md-6">
                <img src={AmazingHeadShow} alt="AmazingHeadShow"/>
            </div>
        </div>
        <div className="row pb-25">
            <div className="col-md-6">
            <img src={businessHeadShoeDubai} alt="businessHeadShoeDubai"/>
            </div>
            <div className="col-md-6">
            <img src={pinkBackgroundPortraitHeadshow} alt="pinkBackgroundPortraitHeadshow"/>
            </div>
        </div>
        <div className="row pb-25">
            <div className="col-md-6">
            <img src={yellowBackgroundHeadShot} alt="yellowBackgroundHeadShot"/>
            </div>
            <div className="col-md-6">
            <img src={couplePhotoShopDubai} alt="couplePhotoShopDubai"/>
            </div>
        </div>

        <div className="row pb-25">
            <div className="col-md-5">
                <img src={portraitHeadshotKids} alt="portraitHeadshotKids"/>
            </div>
            <div className="col-md-7">
                <img src={photoShopCloseUp} alt="photoShopCloseUp"/>
            </div>
        </div>
    </div>
</section>

    <div>
        <AppReservationForm service="Portraits Photography"/>
    </div>

    </div>
  )
}

export default Banner