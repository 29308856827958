import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Banner from './CVdesigningComponent/Banner'
import CVtempletes from './CVdesigningComponent/CVtempletes'

const CVcustomDesigning = () => {
  useEffect(()=>{
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  },[])
  return (
    <div>
        <Banner/>
        <Helmet>
          <title>Free and Premium CV Templates Download in Word Document – Ozelu CV Designs</title>
          <meta name='description' content='Choose from our numerous CV designs, tailored and customized specifically to UAE market, after many years of research. Go ahead and download our free or premium templates and get your dream job.' />
        </Helmet>
        <CVtempletes/>
    </div>
  )
}

export default CVcustomDesigning