import React, {useContext, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useCart } from 'react-use-cart'
import { Link } from 'react-router-dom'
import AppContext from '../../store/AppContext'

function MiniCart() {
    const navigate = useNavigate()
    const {cartIsOpened,setCartOpened} = useContext(AppContext)
    const {cartTotal,items,isEmpty,updateItemQuantity,removeItem} = useCart()

    const handleCartClose = function(e){
        setCartOpened(false)
    }

    const handleChangeQuantity = (id,q)=>{
        updateItemQuantity(id,parseInt(q))
    }

    const handleCheckout = (e)=>{
        e.preventDefault()
        navigate('/checkout')
        setCartOpened(false)
    }

    const handleCollectionRedirect = (e)=>{
        e.preventDefault()
        navigate('/collections/all')
        setCartOpened(false)
    }

    useEffect(()=>{
        console.log("items",items)
    },[])
  return (
    <>
    <div id="gliconsymbols" className="hide dn">
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <symbol id="scl_remove"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"> <polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line> </svg> </symbol> <symbol id="scl_edit"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"> <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path> </svg> </symbol> <symbol id="scl_selected"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M9 20l-7-7 3-3 4 4L19 4l3 3z"></path></svg></symbol>
        </svg>
    </div>
    <div className={`mask-overlay ntpf t__0 r__0 l__0 b__0 op__0 ${cartIsOpened ? 'mask_opened':''}`} onClick={handleCartClose}></div>
    <div id="shopify-section-cart_widget" className="mini_cart_container">
        <div id="nt_cart_canvas" className={`nt_fk_canvas dn ${cartIsOpened ? 'act_opened' : ''}`}>
            <form action="/cart" method="post" novalidate="" className="nt_mini_cart nt_js_cart flex column h__100 btns_cart_1">
                <div className="mini_cart_header flex fl_between al_center">
                    <div className="h3 widget-title tu fs__16 mg__0">Shopping cart</div>
                    <i className="close_pp pegk pe-7s-close ts__03 cd" onClick={handleCartClose}></i>
                </div>
                <div className="mini_cart_wrap">
                    <div className="mini_cart_content fixcl-scroll">
                    <div className="fixcl-scroll-content">
                        {isEmpty ? <div className="empty tc mt__40">
                            <i className="las la-shopping-bag pr mb__10"></i>
                            <p>Your cart is empty.</p>
                            <p className="return-to-shop mb__15">
                                <a className="button button_primary tu js_add_ld" onClick={handleCollectionRedirect} href="#">
                                    <span className="truncate">Return To Shop</span>
                                </a>
                            </p>
                        </div> :
                        <div className="mini_cart_items js_cat_items lazyloadt4sed" data-rendert4s="/cart/?view=viewjs" data-currentrendert4s="">
                                {items.map((item)=>{
                                    return <div key={item.id} className="mini_cart_item js_cart_item cart_item_42958172520668 flex al_center pr oh">
                                    <a href="/products/snack-monster-funny-kids-tshirt?variant=42958172520668" className="mini_cart_img">
                                        <img className="lz_op_ef w__100 lazyautosizes lazyloadt4sed" src={item?.image?.src ? item.image.src : '//cdn.shopify.com/shopifycloud/shopify/assets/no-image-50-3d8cc48bd078edcd544c8d60f929ed2d8800a3fc52e0f602e84b1767e392bfcd_1728x.gif'}  alt={item?.image?.alt}/>
                                    </a>
                                    <div className="mini_cart_info">
                                    <a href="/products/snack-monster-funny-kids-tshirt?variant=42958172520668" className="mini_cart_title truncate">{item.title}</a>
                                    <div className="mini_cart_meta">
                                        <p className="cart_meta_variant">{item.title_option}</p>
                                        <div className="cart_meta_price price">
                                            <div className="cart_price">Dhs. {item.price}</div>
                                        </div>
                                    </div>
                                    <div className="mini_cart_actions">
                                        <div className="quantity pr mr__10 qty__true">
                                            <input type="number" className="input-text qty text tc qty_cart_js" step="1" min="0" max="9999" value={item.quantity} size="4" pattern="[0-9]*" inputmode="numeric" onChange={(e)=>handleChangeQuantity(item.id,e.currentTarget.value)} />
                                            <div className="qty tc fs__14">
                                                <button type="button" style={{paddingRight:'15px'}} className="plus db cb pa pd__0 tr r__0" onClick={(e)=>handleChangeQuantity(item.id,item.quantity + 1)}>
                                                    <i className="facl facl-plus"></i>
                                                </button>
                                                <button type="button" style={{paddingLeft: '15px'}} className="minus db cb pa pd__0 tl l__0 qty_1" onClick={(e)=>handleChangeQuantity(item.id,item.quantity - 1)}>
                                                    {item.quantity == 1 ? <svg viewBox="0 0 24 24">
                                                        <use xlinkHref="#scl_remove"></use>
                                                    </svg> :
                                                    <i className="facl facl-minus"></i>}
                                                </button>
                                            </div>
                                        </div>
                                        <a href="#" onClick={(e)=>{e.preventDefault();removeItem(item.id)}} rel="nofollow" data-no-instant="" className="cart_ac_remove js_cart_rem ttip_nt tooltip_top_right">
                                            <span className="tt_txt">Remove this item</span>
                                            <svg viewBox="0 0 24 24">
                                                <use xlinkHref="#scl_remove"></use>
                                            </svg>
                                        </a>
                                    </div>
                                    </div>
                                </div>
                                })}
                        </div>}
                    </div>
                    </div>
                    {!isEmpty && <div className="mini_cart_footer js_cart_footer dn" style={{display:'block'}}>
                    <div className="js_cat_dics"></div>
                    <div className="total row fl_between al_center">
                        <div className="col-auto"><strong>Subtotal:</strong></div>
                        <div className="col-auto tr js_cat_ttprice">
                            <div className="cart_tot_price">Dhs. {cartTotal}</div>
                        </div>
                    </div>
                    <p className="txt_tax_ship mb__5 fs__12">Taxes, shipping and discounts codes calculated at checkout</p>
                    <button type="submit" onClick={handleCheckout} className="button btn-checkout mt__10 mb__10 js_add_ld truncate">Check Out</button>
                    </div>}
                </div>
            </form>
        </div>
    </div>
    </>
  )
}

export default MiniCart