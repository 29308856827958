import React, { useEffect } from 'react'
import slugify from 'slugify'

const get_color = ['color','colors','colour','colours']

function VariantSnippet({product, selectedVariant, changeVariant}) {

    function handleVariantChange(e,index){
        let value = e.currentTarget.dataset.value;
        console.log("Index",index,selectedVariant)
        switch (index) {
            case 0:
                changeVariant(value,selectedVariant.option2,selectedVariant.option3)
                break;
            case 1:
                changeVariant(selectedVariant.option1,value,selectedVariant.option3)
                break;
            default:
                changeVariant(selectedVariant.option1,selectedVariant.option2,value)
                break;
        }
    }

    useEffect(()=>{
        console.log("Variant>>", selectedVariant)
    },[])
  return (
    <>
        <div class="fimg_false variations mb__40 style__circle size_medium style_color des_color_1 remove_soldout_false">
        {product.options.map((option,index)=>{
            let selected_value = null
            switch (index) {
                case 0:
                    selected_value = selectedVariant?.option1
                    break;
                case 1:
                    selected_value = selectedVariant?.option2
                    break;
                default:
                    selected_value = selectedVariant?.option3
                    break;
            }
            return (<>
                {get_color.includes(option.name.toLowerCase()) ? (
                    <div class="nt_select_ppr0  swatch is-color is-ntcolor">
                            <h4 class="swatch__title">
                            <span>
                                {option.name}:
                                <span class="nt_name_current">
                                {' '}{selected_value}
                                </span>
                            </span>
                            </h4>
                            <ul
                            class="swatches-select swatch__list_pr"
                            data-id={index}
                            data-size="2"
                            >
                                {option.values.map((value,i)=>{
                                    return <li key={i}
                                        data-index={i}
                                        data-value={value}
                                        class={`ttip_nt tooltip_top_right nt-swatch swatch_pr_item bg_css_natural-beige ${value == selected_value ? 'is-selected' : ''}`}
                                        data-escape={value}
                                        onClick={(e)=>handleVariantChange(e,index)}
                                    >
                                        <span class="tt_txt">{value}</span>
                                        <span className={`swatch__value_pr pr bg_color_${slugify(value.toLowerCase())} lazyloadt4sed`}></span>
                                    </li>
                                })}
                            </ul>
                        </div>
                ) : (
                    <div
                    data-opname={option.name}
                    class={`nt_select_ppr1 swatch is-label is-${slugify(option.name.toLowerCase())}`}
                    id="nt_select_ppr_1"
                >
                    <h4 class="swatch__title">
                        <span>
                            {option.name}:
                            <span class="nt_name_current">{' '}{selected_value}</span>
                        </span>
                    </h4>
                    <ul
                    class="swatches-select swatch__list_pr"
                    data-id={index}
                    data-size="2"
                    >
                        {option.values.map((value,i)=>{
                            return <li key={i}
                                        data-index={i}
                                        data-value={value}
                                        class={`nt-swatch swatch_pr_item pr bg_css_38x42-cm ${value == selected_value ? 'is-selected' : ''}`}
                                        data-escape={value}
                                        onClick={(e)=>handleVariantChange(e,index)}
                                    >
                                        <span class="swatch__value_pr">{value}</span>
                                    </li>
                        })}
                    </ul>
                </div>
                )}
            </>)
        })}
        </div>
    </>
  )
}

export default VariantSnippet