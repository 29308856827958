import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageLoader from '../../components/Loader/PageLoader';
import { htmlDecode } from '../../helpers/generals';
import {Helmet} from 'react-helmet-async'
import './styles.scss'

function Index() {
    const {handle} = useParams()
    const [page,setPage] = useState(null);
    const [isLoading, setIsLoading] = useState(true)

    useEffect(()=>{
        setIsLoading(true)
        const getPage =async ()=>{
            const {data} = await axios.get(process.env.REACT_APP_API_URL + '/api/assets/pages/'+handle);
            const {page} = data
            setPage(page)
            console.log("Page",page)
            setIsLoading(false)
        }
        getPage()
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    },[handle])

    if(isLoading) return <PageLoader/>
    if(!page)  return null
  return (
    <div id="nt_content">
        <Helmet>
            <title>{page?.page_title}</title>
            <meta name='description' content={page?.meta_description} />
        </Helmet>
        <div id="shopify-section-pages_heading" class="shopify-section page_section_heading">
            <div class="page-head tc pr oh page_head_pages_heading">
                <div class="container pr">
                    <h1 class="mb__5 cw">{page.title}</h1>
                </div>
            </div>
        </div>
        <div class="container mt__60 mb__60 rtet4" dangerouslySetInnerHTML={{__html: htmlDecode(page.body_html)}}></div>
    </div>
  )
}

export default Index