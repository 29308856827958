import React,{useEffect,useState} from 'react'
import { useDropzone } from 'react-dropzone';

function ImageDropzone({files,setFiles, showCounters}) {
    const [thumbs,setThumbs] = useState(null);
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            console.log("Files",files)
            setFiles([...files,...acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file),
            count: 1
            }))]);
        }
    });

    function onRemove(e,event) {
        e.stopPropagation();
        const fileToRemove = files.splice(files.indexOf(event), 1);
        const newFiles = files.map((file)=>{
            if(file !== fileToRemove ) return Object.assign(file,{
                preview: URL.createObjectURL(file)
            });
        })
        setFiles(newFiles);
    }

    function setFileCounter(e,file,type){
        e.preventDefault()
        let value = 1;
        let newFiles = files.map((innerFile)=>{
            if(file == innerFile){
                value = parseInt(file.count)
                if(type == 'plus'){
                    return Object.assign(innerFile, {count: value + 1});
                }else{
                    if(value > 1) return Object.assign(innerFile, {count: value - 1});
                }
            }
            return innerFile
        })
        setFiles(newFiles)    
    }

    function renderThumbs(){
        setThumbs(files?.map((file,i) => (
            <div key={file.name} className="thumb--img">
                <div className='thumb--img-item' style={{backgroundImage: `url(${file.preview})`}}>
                    {showCounters && <div class="q-counter" onClick={(e)=>e.stopPropagation()}>
                        <span class="down" onClick={(e)=>setFileCounter(e,file,'minus')}>-</span>
                        <input type="text" value={file.count} readOnly />
                        <span class="up" onClick={(e)=>setFileCounter(e,file, 'plus')}>+</span>
                    </div>}
                </div>
                <div class="ng-star-inserted" className='badge' onClick={(e)=>onRemove(e,file)}>
                    <svg style={{height: '10px',width: '10px'}}>
                        <line style={{stroke: '#fff',strokeWidth: '2px'}} x1="0" y1="0" x2="10" y2="10"></line>
                        <line style={{stroke: '#fff',strokeWidth: '2px'}} x1="0" y1="10" x2="10" y2="0"></line>
                    </svg>
                </div>
            </div>
        )))
    }

    useEffect(()=>{
        renderThumbs()
    },[files])

    useEffect(() => {
        setFiles([...files.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        }))]);
        renderThumbs()
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <div {...getRootProps({className: 'custom-dropzone selectForm'})} >
            <input {...getInputProps()} />
            {files.length <= 0 && <div style={{margin: '10px auto',textAlign: 'center'}}>
            <h2>Drop or browse files here</h2>
            </div>}
            {thumbs}
        </div>
    )
}

export default ImageDropzone