import React from 'react'
import BannerImg from '../../images/caricatures/caricatures-cover-photo.jpg';
import WeddingCarcatureIllustration1 from '../../images/caricatures/fwdphotoscaricaturespage/wedding-caricature-illustration-1.jpg';
import WeddingCarcatureIllustration2 from '../../images/caricatures/fwdphotoscaricaturespage/wedding-caricature-illustration-2.jpg';
import WeddingCarcatureIllustration3 from '../../images/caricatures/fwdphotoscaricaturespage/wedding-caricature-illustration-3.jpg';
import WeddingCarcatureIllustration4 from '../../images/caricatures/fwdphotoscaricaturespage/wedding-caricature-illustration-4.jpg';
import WeddingCarcatureIllustration5 from '../../images/caricatures/fwdphotoscaricaturespage/wedding-caricature-illustration-5.jpg';
import WeddingCarcatureIllustration6 from '../../images/caricatures/fwdphotoscaricaturespage/wedding-caricature-illustration-6.jpg';
import WeddingCarcatureIllustration7 from '../../images/caricatures/fwdphotoscaricaturespage/wedding-caricature-illustration-7.jpg';
import WeddingCarcatureIllustration8 from '../../images/caricatures/fwdphotoscaricaturespage/wedding-caricature-illustration-8.jpg';

import CoupleCarcatureIllustration1 from '../../images/caricatures/fwdphotoscaricaturespage/couple-caricature-illustration-1.jpg';
import CoupleCarcatureIllustration2 from '../../images/caricatures/fwdphotoscaricaturespage/couple-caricature-illustration-2.jpg';
import CoupleCarcatureIllustration3 from '../../images/caricatures/fwdphotoscaricaturespage/couple-caricature-illustration-3.jpg';
import CoupleCarcatureIllustration4 from '../../images/caricatures/fwdphotoscaricaturespage/couple-caricature-illustration-4.jpg';
import CoupleCarcatureIllustration5 from '../../images/caricatures/fwdphotoscaricaturespage/couple-caricature-illustration-5.jpg';
import CoupleCarcatureIllustration6 from '../../images/caricatures/fwdphotoscaricaturespage/couple-caricature-illustration-6.jpg';
import CoupleCarcatureIllustration7 from '../../images/caricatures/fwdphotoscaricaturespage/couple-caricature-illustration-7.jpg';
import CoupleCarcatureIllustration8 from '../../images/caricatures/fwdphotoscaricaturespage/couple-caricature-illustration-8.jpg';

import BirthdayCarcatureIllustration1 from '../../images/caricatures/fwdphotoscaricaturespage/birthday-caricature-illustration-1.jpg';
import BirthdayCarcatureIllustration2 from '../../images/caricatures/fwdphotoscaricaturespage/birthday-caricature-illustration-2.jpg';
import BirthdayCarcatureIllustration3 from '../../images/caricatures/fwdphotoscaricaturespage/birthday-caricature-illustration-3.jpg';
import BirthdayCarcatureIllustration4 from '../../images/caricatures/fwdphotoscaricaturespage/birthday-caricature-illustration-4.jpg';
import BirthdayCarcatureIllustration5 from '../../images/caricatures/fwdphotoscaricaturespage/birthday-caricature-illustration-5.jpg';
import BirthdayCarcatureIllustration6 from '../../images/caricatures/fwdphotoscaricaturespage/birthday-caricature-illustration-6.jpg';
import BirthdayCarcatureIllustration7 from '../../images/caricatures/fwdphotoscaricaturespage/birthday-caricature-illustration-7.jpg';
import BirthdayCarcatureIllustration8 from '../../images/caricatures/fwdphotoscaricaturespage/birthday-caricature-illustration-8.jpg';



import { scroll } from "../../utils"
const Banner = () => {
    const scrollToForm = () => {
        const formRef = document.getElementById('reservation-form');
        scroll(formRef);
    }
  return (
    <div className='wrapper-caricature'>
    <div >
        <div class="banner-caricature">
            <div class="row banner-row py-3">
                <div class="col-md-5 d-flex flex-column justify-content-center banner-caricature-left">
                    <h2 class="banner-heading">CARICATURES <br/>&<br/> ILLUSTRATIONS</h2>
                    <p className='banner-text pt-3'>We create unique and personalized caricatures and illustrations</p>
                </div>
                <div class="col-md-7">
                    <img src={BannerImg} alt="BannerImg"/>
                </div>
            </div>
        </div>
        <div className='banner-items py-3'>
            <p className='banner-item'>WEDDING CARICATURES</p>
            <p className='banner-item'>COUPLE CARICATURES</p>
            <p className='banner-item'>BIRTHDAY CARICATURES</p>
        </div>
    </div>
    <div className='caricature-head py-3'>
        <h2>WEDDING CARICATURES</h2>
    </div>

    <section className='caricature-images'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${WeddingCarcatureIllustration1})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${WeddingCarcatureIllustration2})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${WeddingCarcatureIllustration3})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${WeddingCarcatureIllustration4})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${WeddingCarcatureIllustration5})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${WeddingCarcatureIllustration6})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${WeddingCarcatureIllustration7})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${WeddingCarcatureIllustration8})`}}></div>
                </div>
            </div>
            <div className='caricature-btn--wrapper'>
                <a href="https://wa.me/971545679479"><button className='caricature-btn'>WHATSAPP FOR QUOTE</button></a>
            </div>
        </div>
    </section>

    <div className='caricature-head py-3'>
        <h2>COUPLE CARICATURES</h2>
    </div>

    <section className='caricature-images'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${CoupleCarcatureIllustration1})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${CoupleCarcatureIllustration2})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${CoupleCarcatureIllustration3})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${CoupleCarcatureIllustration4})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${CoupleCarcatureIllustration5})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${CoupleCarcatureIllustration6})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${CoupleCarcatureIllustration7})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${CoupleCarcatureIllustration8})`}}></div>
                </div>
            </div>
            <div className='caricature-btn--wrapper'>
                <a href="https://wa.me/971545679479"><button className='caricature-btn'>WHATSAPP FOR QUOTE</button></a>
            </div>
        </div>
    </section>

    <div className='caricature-head py-3'>
        <h2>BIRTHDAY CARICATURES</h2>
    </div>

    <section className='caricature-images'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${BirthdayCarcatureIllustration1})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${BirthdayCarcatureIllustration2})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${BirthdayCarcatureIllustration3})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${BirthdayCarcatureIllustration4})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${BirthdayCarcatureIllustration5})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${BirthdayCarcatureIllustration6})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${BirthdayCarcatureIllustration7})`}}></div>
                </div>
                <div className='col-12 col-md-4 caricature-img--wrapper'>
                <div className='caricature-img' style={{backgroundImage:`url(${BirthdayCarcatureIllustration8})`}}></div>
                </div>
            </div>
            <div className='caricature-btn--wrapper'>
                <a href="https://wa.me/971545679479"><button className='caricature-btn'>WHATSAPP FOR QUOTE</button></a>
            </div>
        </div>
    </section>

    </div>
  )
}

export default Banner