import React from 'react'
import {usePagination, DOTS} from '../../helpers/usePagination';

function CollectionFooter({currentPage, totalCount, changePage}) {

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount: 2,
        pageSize: 50
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        console.log(currentPage,paginationRange.length)
        return null;
    }

    function renderNumberings(){
        return paginationRange.map(pageNumber => {
         
            // If the pageItem is a DOT, render the DOTS unicode character
            if (pageNumber === DOTS) {
              return <li><span class="page-numbers lh__1">…</span></li>
            }
            
            // Render our Page Pills
            return (
                <li><a className={pageNumber === currentPage ? 'page-numbers current' : 'page-numbers'} href="#" onClick={(e)=>{e.preventDefault();changePage(pageNumber)}}>{pageNumber}</a></li>
            );
        })
    }

    let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div class="products-footer tc mt__40 mb__60">
        <nav class="nt-pagination w__100 tc paginate_ajax">
        <ul class="pagination-page page-numbers">
            <li style={{display: currentPage === 1 ? 'none' : 'list-item'}}><a class="page-numbers" href="#" onClick={(e)=>{e.preventDefault();changePage(currentPage - 1)}}>Prev</a></li>
            {renderNumberings()}
            <li style={{display: currentPage === lastPage ? 'none' : 'list-item'}}><a href="#" class="page-numbers" onClick={(e)=>{e.preventDefault();changePage(currentPage + 1)}}>Next</a></li>
        </ul>
        </nav>
    </div>
  )
}

export default CollectionFooter