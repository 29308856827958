import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { useCart } from 'react-use-cart';
import CheckoutForm from './CheckoutForm'
import CheckoutProduct from './CheckoutProduct'
import "./styleCheckoutComponent.scss"

const Checkout = () => {
    const navigate = useNavigate()
    const {isEmpty, cartTotal} = useCart()
    const [showForm, setShowForm] = useState(false);
    const [deliveryCharges, setDeliveryCharges] = useState(0)

    function handleShowForm(event) {
        event.preventDefault();
       event.stopPropagation();
        setShowForm(!showForm);
    }

    useEffect(()=>{
        if(isEmpty) navigate('/')
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    },[])
    
  return (
    <div>
        <div className='mobileview-header' onClick={handleShowForm}>
            <div>
            <span className='icon-cont__mobileview'>
            <i class="fas fa-shopping-cart primary-color"></i>
            </span>  
            <span className='text-cont__mobileview'>
                <span className='mr-2 primery-color'>{showForm ? "Hide Order Summary" : "Show Order Summary"}</span>
                <i class={`fa-solid ${showForm? "fa-chevron-down" : "fa-chevron-up"} primery-color`}></i>
            </span>
            </div>
            <div className='total-price-mobileview'>AED {cartTotal}</div>
            
        </div>
    <div className='page-container__checkout'>
       
         <CheckoutForm deliveryCharges={deliveryCharges} setDeliveryCharges={setDeliveryCharges}/>
        <CheckoutProduct classes={showForm ? '' : 'hidden__mobile'} deliveryCharges={deliveryCharges}/>
       
    </div>
    </div>
  )
}

export default Checkout