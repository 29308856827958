import React, { useContext } from 'react'
import { Outlet } from 'react-router-dom'
import MiniCart from '../components/EComereceStore/MiniCart'
import Footer from '../components/Footer'
import Header from '../components/Header'
import SiteLoader from '../components/Loader/SiteLoader'
import AppContext from '../store/AppContext'

function Main() {
  const {isLoading} = useContext(AppContext)
  if(isLoading) return <SiteLoader/>
  return (
    <>
        <Header/>
            <MiniCart/>
            <Outlet/>
        <Footer/>
    </>
  )
}

export default Main