import React from 'react'
import './SiteLoader.scss'

function SiteLoader() {
  return (
    <div className='site-loader'>
        <div className='site-loader--inner'></div>
    </div>
  )
}

export default SiteLoader