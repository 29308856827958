import React from 'react'
import coverPhoto from '../../../images/images/logo-designing/logo-designing-cover-photo.png'
import "./style.scss"
const Banner = () => {
  return (
    <div className='banner-container'>
        <img src={coverPhoto} alt="coverPhoto"/>
        <h1>JUMP START YOUR <br/>BUSINESS</h1>
        <h2>With a Perfect Logo</h2>
    </div>
  )
}

export default Banner