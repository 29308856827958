import React from 'react'
import cvCoverPhoto from "../../../images/images/cv-templates/cv-templates-cover-photo.png"
import "./style.scss"


const data = [
    {
        _id: 1234,
        title: "CV Tittle",
        isPaid: "boolean",
        price: 12,
        preview: "string(img url)",
        cv: "string(document url) (will be there if its free template)",
    }
]


const Banner = () => {
  return (
    <div className='banner-container__cv-page'>
        <div className='banner-text__cv-page'>
            <h1>Elevate Your Job Search With Our Free and Premium CV Templates</h1>
            <p>Choose from our free samples or paid templates (editable) of well-designed expert approved CVs or get in touch with us to design for you a fully customized CV based on the industry you are hunting for and the profession you would like to get into. </p>
        </div>
        <div className='banner-img__cv-page'><img src={cvCoverPhoto} alt="cv"/></div>
    </div>
  )
}

export default Banner