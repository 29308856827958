import React from 'react'
import "./style.scss"
import brainstrom from "../../../images/images/logo-designing/brainstorming.png"
import drawing from "../../../images/images/logo-designing/sketching.png"

import designing from "../../../images/images/logo-designing/designing.png"

import finalizing from "../../../images/images/logo-designing/finalizing.png"





const iconsData = [
  {
    icon:<img src={brainstrom} alt="brainstrom" className='icon-image' style={{width:"150px"}}/>,
    heading:"BRAINSTORMING",
    text:"A fine log carries key principle of an organization. Our team will perform client and industry research and brainstorm brillient ideas",
    backgroundColor:"#d3d39a"
  },
  {
    icon:<img src={drawing} alt="drawing" className='icon-image' style={{width:"100px"}}/>,
    heading:"SKETCHING",
    text:"Sketching is an effective and easy brainstorming tool, we will present various rough versions of logo, get a feedback, and polish the winning concept.",
    backgroundColor:"#f9d5b0"
  },
  {
    icon:<img src={designing} alt="designing" className='icon-image' style={{width:"120px", height:"90px"}}/>,
    heading:"DESIGNING",
    text:"We then will digitalize the winning concept and create multiple variants of the design, keeping in mind the main theme and purpose.",
    backgroundColor:"#deabad"
  },
  {
    icon:<img src={finalizing} alt="finalizing" className='icon-image' style={{width:"120px", height:"90px", paddingLeft:"15px"}}/>,
    heading:"FINALIZING",
    text:"Upon selection, our teams will submit the design files in proper format.",
    backgroundColor:"#8acbd6"
  }
]




const LogoIcons = () => {
  return (
    <div className='logo-icons-container'>
    <div class="d-flex-logo flex-row-logo ">
    

      {iconsData.map(item=>{
        return(<div  className="icons-container">
        <div class="card rounded-circle d-flex align-items-center m-3" style={{background: `${item.backgroundColor}`}}>
          <div class="card-body">
          {item.icon}
          </div>
        </div>
        <h5 class="card-title text-center mt-3 mx-auto" id="text1">{item.heading}</h5>
        <p>{item.text}</p></div>)
      })}
  
</div></div>



  )
}

export default LogoIcons