import React from 'react'
import BannerImg from '../../images/images/event-photography/event-photography-dubai.jpg';
import DinnerEvenPhotography from '../../images/images/event-photography/dinner-event-photograhy.jpg';
import stageEventDubai from '../../images/images/event-photography/stage-event-dubai.jpg';
import marriageEvent from '../../images/images/event-photography/marriage-contract-event-photography.jpg';
import photoshopOzelu from '../../images/images/event-photography/event-photoshoot-ozelu-studio.jpg';
import corporateEvent from '../../images/images/event-photography/coporate-event-photography.jpg';
import AppReservationForm from '../ReservationForm/AppReservationForm';
import { scroll } from "../../utils"
import "./stylesBanner.scss"
const Banner = () => {
    const scrollToForm = () => {
        const formRef = document.getElementById('reservation-form');
        scroll(formRef);
    }
  return (
    <div className='wrapper-event-photography'>
    <div >
    <div class="banner-photography">
        <div class="row">
        <div class="col-md-6 pl-12">
            <h2 class="re-cover-event">Your Event Our Final</h2>
            <p>Contact us for a quotation for the event you want us to cover and we will make sure we over deliver than
                your expectations.</p>
            <div class="what-center">
                <a href="https://wa.me/971545679479"><button>WhatsApp to Book</button></a>
                <button onClick={()=>scrollToForm()}> Go to Contact Form</button>
            </div>
        </div>
        <div class="col-md-6">
            <img src={BannerImg} alt="BannerImg"/>
        </div>
    </div></div>
</div>

<section class="php-photography">
    <div class="container-photography">
        <div class="row pt-30">
            <div class="col-md-6">
                <h2>Event Photography in Dubai</h2>
                <p>If you have a corporate event or need photographers for a live coverage event, contact us via call or
                    WhatsApp for quotations and bookings.
                    <br/><br/>
                    We have a team of experienced photographers who have covered well known events such as UAE National
                    Day celebrations, major corporate events like conferences and performance reviews, Formula 1 and
                    other medium to large scale events.
                </p>
            </div>
            <div class="col-md-5">
            <img src={DinnerEvenPhotography} alt="DinnerEvenPhotography"/>
            </div>
        </div>
    </div>
</section>

<section class="services-tittle-photography">
    <div class="container-photography">
        <h2>Our Event Photography Portfolio</h2>
    </div>
</section>

<section class="gallery-photography">
    <div class="container-photography">
        <div class="row pb-25">
            <div class="col-md-12">
            <img src={stageEventDubai} alt="stageEventDubai"/>
            </div>

        </div>
        <div class="row pb-25">
            <div class="col-md-4">
            <img src={marriageEvent} alt="marriageEvent"/>
                        </div>
            <div class="col-md-7">
            <img src={photoshopOzelu} alt="stageEventDubai"/>
                        </div>

        </div>
        <div class="row pb-25">
            <div class="col-md-12">
            <img src={corporateEvent} alt="corporateEvent"/>
                        </div>
        </div>
    </div>
</section>

<div>
    <AppReservationForm service="Event Photography" />
</div>

    </div>
  )
}

export default Banner