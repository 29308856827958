import React, { useContext, useEffect, useRef, useState } from "react";
import "./styleCheckoutComponent.scss";
import ErrorIcon from '../../images/images/error.jpeg'
import { Link, useNavigate } from "react-router-dom";
import Checkout from "../../components/Checkout";
import { Formik } from "formik";
import Swal from "sweetalert2";
import useApp from "../../store/useApp";
import {useCart} from 'react-use-cart'
import SearchableSelect from "../../components/SearchableSelect";
import axios from "axios";
import Loading from "../../components/Loading";
import AppContext from "../../store/AppContext";
const CheckoutForm = ({deliveryCharges, setDeliveryCharges}) => {
  const formRef = useRef();
  const {areas,getAreaSet,storePrices} = useContext(AppContext)
  const [areaOptions, setAreaOptions] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0)
  const navigate = useNavigate()
  const {items,cartTotal,totalItems,emptyCart} = useCart()
  const {tryCreateToken} = useApp()
  const [showLoading, setShowLoading] = useState(false);

  const initialValues = {
    email: "",
    firstname: "",
    lastname: "",
    address: "",
    apartment: "",
    city:'UAE',
    area: "",
    phone: ""
  };
  const validator = function (values) {
    const errors = {};
    if (!values.firstname) errors.firstname = "Required";
    if (!values.lastname) errors.lastname = "Required";
    if (!values.phone) errors.phone = "Required";
    if (!values.address) errors.address = "Required";
    if (!values.city) errors.city = "Required";
    if (!values.area) errors.area = "Required";
    if (!values.email) {
      errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Invalid email address";
    }
    return errors;
  };

  const getFormikValues = () => {
    const formikValues = formRef.current?.values;
    return formikValues;
  };

  function onSubmit() {
    const {
      firstname,
      lastname,
      email,
      phone,
      address,
      city,
      area,
      apartment
    } = getFormikValues();
    setShowLoading(true);
    
    tryCreateToken().then((token) => {
      if (token === undefined || token === "") {
        console.log("Error while getting token");
        setShowLoading(false);
      } else {
        const formData = new FormData();

        formData.append("firstname", firstname);
        formData.append("lastname", lastname);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("address", address);
        formData.append("city", city);
        formData.append("area",area)
        formData.append("apartment",apartment)
        formData.append("price", totalPrice);
        formData.append('deliveryCharges', deliveryCharges);
        formData.append('totalItems', totalItems)
        formData.append("items",JSON.stringify(items))
        formData.append("token", token.id);

        axios({
          data: formData,
          method: "post",
          url: process.env.REACT_APP_API_URL + "/api/stripe/store-pay",
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            if (response.data.status) {
              setShowLoading(false);
              Swal.fire("Order Placed!", "Please check Your Email!", "success");
              emptyCart()
              navigate('/')
            } else {
              setShowLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response?.data?.message || "Something went wrong!",
              });
            }
            console.log("Response", response);
          })
          .catch((err) => {
            setShowLoading(false);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: err?.data?.message || "Something went wrong!",
            });
            console.log("Error", err);
          });
      }
    });
  }

  async function setValueCalculatePrice(type, value) {
    formRef.current.setFieldValue(type, value);
    console.log("Value",value, 'type',type)
    await Promise.resolve();

    const {area} = getFormikValues();
    let areaSet = getAreaSet(area);

    let priceObj = storePrices?.filter((item)=>{
        return item.areaset === areaSet
    })
    if(priceObj.length > 0){
        setDeliveryCharges(priceObj[0]?.price);
    }else{
        setDeliveryCharges(0)
    }
  }

  useEffect(() => {
    let options = areas.map((area) => {
      return { value: area.name, label: area.name };
    });
    setAreaOptions(options);
  }, []);

  useEffect(()=>{
    setTotalPrice(cartTotal + deliveryCharges)
  },[cartTotal,deliveryCharges])

  return (
    <>
    {showLoading && <Loading/>}
    <div className="form-container__checkout">
      <div className="form__checkout">
        <div className="heading-cont__checkout mb-2">
          <div>
            <h3 className="mr-0">Contact Information</h3>
          </div>
        </div>
        <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validate={validator}
        onSubmit={(values, errors) => {
          onSubmit();
        }}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          handleSubmit,
          handleChange,
          setFieldValue,
          validateForm,
        }) => (
        <form onSubmit={(event) => {
          event.preventDefault();
          validateForm().then((errors) => {
            if (errors && Object.keys(errors).length !== 0) {
              Swal.fire({
                icon: "error",
                title: "Validation Error",
                text: "Please fill all fields!",
              });
              handleSubmit();
            } else {
              handleSubmit();
            }
          });
        }}>
                <div class="formControll mb-3">
                  <input
                    formControlName="email"
                    value={values.email}
                    onChange={(e) => handleChange(e)}
                    type="email"
                    id="email"
                    placeholder="Email"
                    name="email"
                  />
                  {errors.email && touched.email && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your valid email address
                    </div>
                  )}
                </div>

          <div>
            <h3 className="mr-0 mb-2">Shipping Address</h3>
          </div>
          <div className=" mb-3">
            <div class="row justify-content-between">
              <div class="col-6 ml-z">
              <div class="formControll">
                  <input
                    formControlName="firstname"
                    value={values.firstname}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="First Name"
                    name="firstname"
                    id="firstname"
                  />
                  {errors.firstname && touched.firstname && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your first name
                    </div>
                  )}
                </div>
              </div>
              <div class="col-6 mr-z">

              <div class="formControll">
                  <input
                    formControlName="lastname"
                    type="text"
                    value={values.lastname}
                    onChange={(e) => handleChange(e)}
                    placeholder="Last Name"
                    name="lastname"
                    id="lastname"
                  />
                  {errors.lastname && touched.lastname && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your last name
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="formControll mb-3">
                  <input
                    formControlName="address"
                    value={values.address}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="Address"
                    id="address"
                    name="address"
                  />
                  {errors.address && touched.address && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your address
                    </div>
                  )}
          </div>
          <div class="formControll mb-3">
                  <input
                    formControlName="apartment"
                    value={values.apartment}
                    onChange={(e) => handleChange(e)}
                    type="text"
                    placeholder="Apartment, suit, etc"
                    id="apartment"
                    name="apartment"
                  />
          </div>
          <div className="mb-3">
            <div class="row justify-content-between">
              <div class="col-6 ml-z">
              <div class="formControll mb-3">
                  <select value={values.city} id="city" name="city" onChange={(e)=>handleChange(e)}>
                    <option value={'UAE'}>Dubai</option>
                  </select>
                  {errors.city && touched.city && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your city
                    </div>
                  )}
          </div>
              </div>
              <div class="col-6 mr-z">
              <div class="formControll mb-3">
                  <SearchableSelect
                    type="area"
                    options={areaOptions}
                    value={values.area}
                    placeholder={'Select Area'}
                    onChangeHandler={setValueCalculatePrice}
                    controlBg='#fff'
                    optionBg="#fff"
                  />
                  {errors.area && touched.area && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your area
                    </div>
                  )}
          </div>
              </div>
            </div>
          </div>
          <div class="formControll mb-3">
                  <input
                    formControlName="phone"
                    value={values.phone}
                    onChange={(e) => handleChange(e)}
                    type="tel"
                    id="pnumber"
                    placeholder="Phone Number"
                    name="phone"
                  />
                  {errors.phone && touched.phone && (
                    <div class="inputError">
                      <div class="arrow-up"></div>
                      <span>
                        <img
                          class="errorImage"
                          src={ErrorIcon}
                          alt="errorImg"
                        />
                      </span>{" "}
                      Please enter your phone number
                    </div>
                  )}
          </div>

          <div>
            <h3 className="mr-0 mb-3">Payment</h3>
          </div>
          <Checkout/>
          <div className="mt-3">
            <div className="row justify-content-between flex-box">
              <div className="col-12 text-center width-101">
                <button
                  type="submit"
                  class="aqHkS Mlpfi bAS_j okQ4n wkoOa DmJZe Lot1G EmqTg"
                >
                  <span class="upmlD">
                    <h5>Pay Now</h5>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
        )}
        </Formik>
      </div>
      {/* <div className="footer__checkout primery-color">
        <span className="icon-right">
          <Link>Refund Policy</Link>
        </span>
        <span className="icon-right">
          <Link>Shipping Policy</Link>
        </span>
        <span className="icon-right">
          <Link>Privacy Policy</Link>
        </span>
        <span className="icon-right">
          <Link>Terms of Service Policy</Link>
        </span>
      </div> */}
    </div>
    </>
  );
};

export default CheckoutForm;
