import React from 'react'
import "./styleAppServices.scss"
import photoDelivery from "../../../images/images/home-page/passport-size-photo-delivery.png"
import printingDelivery from "../../../images/images/home-page/photos-printing-delivery.jpg"
import printingDeliveryDubai from "../../../images/images/home-page/documents-printing-dubai.jpg"
import {Link} from "react-router-dom"

const AppServices = () => {
  return (
    <div className='printing-services--wrapper'>
        <section className="services-tittle">
  <div className="container">
    <h2 className="txt-center heading-h2">Photos and Document Printing Services</h2>
  </div>
</section>
<section className="printing-services">
  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <div className="services-box bg-blue">
          <img src={photoDelivery} alt="photoDelivery" />
          <div className="services-box-tittle">
            <Link className="text-decoration-none" to="passport-size-photos">
              <h2 className="txt-center heading-h3">
                Passport Size Photo Delivery or Soft Copy Service
              </h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="services-box bg-green">
        <img src={printingDelivery} alt="printingDelivery" />
          <div className="services-box-tittle">
            <Link className="text-decoration-none" to="photos-printing-delivery">
              <h2 className="txt-center heading-h3">
                Photos Printing <br />&<br />
                Delivery
              </h2>
            </Link>
          </div>
        </div>
      </div>
      <div className="col-md-4">
        <div className="services-box bg-purple">
        <img src={printingDeliveryDubai} alt="printingDeliveryDubai" />
          <div className="services-box-tittle">
            <Link to="documents-printing" className="text-decoration-none"  >
              <div className="txt-center">
                <h2 className="txt-center heading-h3">Documents Printing & Delivery</h2>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </div>
  )
}

export default AppServices