import React from 'react'
import loadingImg from '../images/Rolling.svg';
import './loading.scss'

function LoadingProgress({loaded}) {
  return (
    <div className='loading--wrapper'>
        <img src={loadingImg} style={{width:'auto'}}/>
        <span>Uploading files...</span>
        <div className='progress-bar'>
            <div className='progress-bar--inner' style={{width: loaded + '%'}}>
                {loaded+'%'}
            </div>
        </div>
    </div>
  )
}

export default LoadingProgress