import React, { useRef,useEffect,useState, useContext} from 'react'
import "../../screens/Home/Services/styleEComerecServices.scss";
import "./styleProductPage.scss"
import Counter from '../../components/EComereceStore/Counter';
import VariantSnippet from './VariantSnippet';
import ImageGallery from './ImageGallery';
import { useCart } from 'react-use-cart';
import AppContext from '../../store/AppContext';
import AuthenticIcon from './../../images/ecomerece/authentic.png'
import CreditCardIcon from './../../images/ecomerece/credit-card.png'
import FastDeliveryIcon from './../../images/ecomerece/fast-delivery.png'
import FreeShippingIcon from './../../images/ecomerece/free-shipping.png'
const ProductDescription = ({product}) => {
  const {addItem} = useCart()
  const {setCartOpened} = useContext(AppContext)
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [quantity, setQuantity] = useState(1)
        
  function changeVariant(option1,option2,option3){
    let variantIs = product.variants.find((varinat)=>{
      return varinat.option1 == option1 && varinat.option2 == option2 && varinat.option3 == option3
    })
    console.log("Selected variant is", variantIs)
    setSelectedVariant(variantIs)
  }

  function handleAddToCart(e){
    e.preventDefault()
    let obj = {}
    if(selectedVariant){
      obj.id = selectedVariant._id;
      obj.title = product.title
      obj.title_option = selectedVariant.title;
      obj.price = selectedVariant.price;
      obj.compare_at_price = selectedVariant.compare_at_price;
      obj.sku = selectedVariant.sku;
      obj.type = 'variant';
      obj.image = selectedVariant.featured_image;
    }else{
      obj.id = product._id
      obj.title = product.title
      obj.title_option = null
      obj.price = product.price
      obj.compare_at_price = product.compare_at_price
      obj.sku = product.sku
      obj.type = 'product'
      obj.image = product?.images[0]
    }
    addItem(obj, quantity)
    setCartOpened(true)
  }

  function renderFreeBenefit(){
    let testState = /benefit::delivery::time/g;
    let freetag = product.tags.find((tag)=>{
      if(testState.test(tag)){
        return tag
      }
    })
    if(product.tags.includes('benefit::delivery') && freetag){
    return <div class="BenefitItem">
          <div class="BenefitItem__Icon">
            <img src={FastDeliveryIcon} alt="" loading="lazy"/>
          </div>
          <span class="BenefitItem__Text" _msttexthash="457782" _msthash="118">Delivery within {freetag.split('::').pop()} hours</span>
      </div>
    }
  }

  useEffect(()=>{
    console.log("variants>>>",product.variants[0])
    setSelectedVariant(product?.variants[0])
  },[product])


  return (
    <div>
    <div className="ecomerece-services_wrapper wrapper__product-page">
      <div class="sp-single sp-single-1 des_pr_layout_1 mb__60">
        <div class="bgbl pt__20 pb__20 lh__1 breadcrumb_pr_wrap">
          <div class="container">
            <div class="row al_center">
              <div class="col">
                <nav class="sp-breadcrumb">
                  <a href="/" class="dib">
                    Home
                  </a>
                  <i class="facl facl-angle-right"></i>{product.title}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container container_cat cat_default">
        <div className="row product mt__40">
          <div className="col-md-12 col-12 thumb_left">
            <div className="row mb__50 pr_sticky_content">
              <div className="col-md-6 col-12 pr product-images img_action_zoom pr_sticky_img">
                  <ImageGallery medias={product.images}/>
              </div>
              
              <div class="col-md-6 col-12 product-infors pr_sticky_su">
                <div class="theiaStickySidebar">
                  <div
                    id="shopify-section-pr_summary"
                    class="shopify-section summary entry-summary mt__30"
                  >
                    <h1
                      class="product_title entry-title"
                      style={{ fontSize: "18px" }}
                    >
                      {product.title}
                    </h1>
                    <div class="flex wrap fl_between al_center price-review">
                      {selectedVariant ? (
                        (selectedVariant.compare_at_price > selectedVariant.price) ? (
                          <p class="price_range">
                            <del>AED {selectedVariant.compare_at_price}</del> 
                            <ins>AED {selectedVariant.price}</ins>
                          </p>
                        ) : (
                          <p class="price_range">AED {selectedVariant.price}</p>
                        )
                      ) : (
                        (product.compare_at_price > product.price) ? (
                          <p class="price_range">
                            <del>AED {product.compare_at_price}</del>
                            <ins>AED {product.price}</ins></p>
                        ) : (
                          <p class="price_range">AED {product.price}</p>
                        )
                      )}
                      <a href="#tab_pr_reivew" class="rating_sp_kl dib">
                        <span class="shopify-product-reviews-badge"></span>
                      </a>
                    </div>
                    <div class="product__policies rte cb"></div>
                    {/* <div class="pr_short_des rtet4">
                      <p class="mg__0">
                        "Not today. Or tomorrow. Or even next week. Feeling
                        Lazy? This design will support your Nopeness for as long
                        as you need." &nbsp; &nbsp; &nbsp;...
                      </p>
                    </div> */}
                    <div class="btn-atc atc-slide btn_full_false PR_no_pick_false btn_des_1 btn_txt_3">
                      <div class="nt_natural-beige nt1_38x42-cm nt2_">
                        <form class="nt_cart_form variations_form variations_form_ppr">
                          <input
                            type="hidden"
                            name="form_type"
                            value="product"
                          />
                          <input type="hidden" name="utf8" value="✓" />
                          <div></div>
                          <VariantSnippet product={product} selectedVariant={selectedVariant} changeVariant={changeVariant}/>
                          <div class="nt_pr_js lazypreloadt4s op__0 lazyloadt4sed">
                            <script type="application/json">[]</script>
                            <script type="application/json"></script>
                          </div>
                          <div class="variations_button in_flex column w__100 buy_qv_true">
                            <div class="flex wrap">
                              <Counter quantity={quantity} setQuantity={setQuantity}/>
                              <a
                                rel="nofollow"
                                data-no-instant=""
                                href="#"
                                class="single_stt out_stock button pe_none order-2"
                                id="out_stock_ppr"
                              >
                                Out of stock
                              </a>
                              <button
                                type="submit"
                                data-time="6000"
                                data-ani="shake"
                                class="single_add_to_cart_button button truncate js_frm_cart w__100 mt__20 order-4 animated shake"
                                onClick={handleAddToCart}
                              >
                                <span class="txt_add ">Add to cart</span>
                                <span class="txt_pre dn">Pre-order</span>
                              </button>
                            </div>
                            {/* <div
                              data-shopify="payment-button"
                              data-has-selling-plan="false"
                              data-has-fixed-selling-plan="false"
                              class="shopify-payment-button"
                            >
                              <div>
                                <div>
                                  <div>
                                    <button
                                      type="button"
                                      class="shopify-payment-button__button shopify-payment-button__button--unbranded BUz42FHpSPncCPJ4Pr_f jjzYeefyWpPZLH9pIgyw RWJ0IfBjxIhflh4AIrUw"
                                      data-testid="Checkout-button"
                                    >
                                      Buy it now
                                    </button>
                                    <button
                                      aria-disabled="true"
                                      aria-hidden="true"
                                      class="shopify-payment-button__more-options BUz42FHpSPncCPJ4Pr_f shopify-payment-button__button--hidden"
                                      type="button"
                                      data-testid="sheet-open-button"
                                    >
                                      More payment options
                                    </button>
                                    <div>
                                      <div></div>
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </form>
                        <div class="product--block mt-4">
                          <div class="tab-icon-wrapper">
                            {renderFreeBenefit()}
                            {product.tags.includes('benefit::freeDelivery') && <div class="BenefitItem">
                                <div class="BenefitItem__Icon">
                                  <img src={FreeShippingIcon} alt="" loading="lazy"/>
                                </div>
                                <span class="BenefitItem__Text" _msttexthash="428441" _msthash="119">Free delivery within Dubai</span>
                            </div>}
                            {product.tags.includes('benefit::secured') && <div class="BenefitItem">
                                <div class="BenefitItem__Icon">
                                  <img src={CreditCardIcon} alt="" loading="lazy"/>
                                </div>
                                <span class="BenefitItem__Text" _msttexthash="380705" _msthash="120">Secured payments</span>
                            </div>}
                            {product.tags.includes('benefit::authentic') && <div class="BenefitItem">
                                <div class="BenefitItem__Icon">
                                  <img src={AuthenticIcon} alt="" loading="lazy"/>
                                </div>
                                <span class="BenefitItem__Text" _msttexthash="380705" _msthash="120">100% Authentic</span>
                            </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default ProductDescription