export const BeforeAfterImageCategory = {
    Man: "man",
    Woman: "woman",
    Kid: "kid",
    Baby: "baby",
  }
export const DefaultPassportSizePhotos = [ 
    {
    country: 'UAE',
    type: 'Stamp Size',
    dimensions: '20x25mm',
    background: 'White',
    label:"UAE",
    value:"UAE"
},
{
    country: 'UAE',
    type: 'School Photo',
    dimensions: '35x40mm',
    background: 'White',
    value: 'UAE',
    label: 'UAE',
},
{
    country: 'UAE',
    type: 'Passport',
    dimensions: '35x40mm',
    background: 'White',
    value: 'UAE',
    label: 'UAE',
},
{
    country: 'UAE',
    type: 'Visa',
    dimensions: '35x40mm',
    background: 'White',
    value: 'UAE',
    label: 'UAE',
},

{
    country: 'UAE',
    type: 'ID',
    dimensions: '35x40mm',
    background: 'White',
    value: 'UAE',
    label: 'UAE',
},
{
    country: 'Algeria',
    type: 'Passport',
    dimensions: '35x45mm',
    background: 'White',
    label:"Algeria",
    value:"Algeria"
},
{
    country: 'Algeria',
    type: 'Visa',
    dimensions: '35x45mm',
    background: 'White',
    label:"Algeria",
    value:"Algeria"
},
{
    country: 'Australia',
    type: 'Passport',
    dimensions: '35x45mm',
    background: 'White',
    label:"Australia",
    value:"Australia"
},
{
    country: 'Australia',
    type: 'Visa',
    dimensions: '35x45mm',
    background: 'White',
    label:"Australia",
    value:"Australia"
},]
  export const PassportSizePhotos = [
    // {
    //     country: '-',
    //     type: 'Stamp Size',
    //     dimensions: '20x25mm',
    //     background: 'White',
    //     label:"-",
    //     value:"-"
    // },
    {
        country: 'UAE',
        type: 'Stamp Size',
        dimensions: '20x25mm',
        background: 'White',
        label:"UAE",
        value:"UAE"
    },
    {
        country: 'UAE',
        type: 'School Photo',
        dimensions: '35x40mm',
        background: 'White',
        value: 'UAE',
        label: 'UAE',
    },
    {
        country: 'UAE',
        type: 'ID',
        dimensions: '35x40mm',
        background: 'White',
        value: 'UAE',
        label: 'UAE',
    },
    {
        country: 'UAE',
        type: 'Passport',
        dimensions: '35x40mm',
        background: 'White',
        value: 'UAE',
        label: 'UAE',
    },
    {
        country: 'UAE',
        type: 'Visa',
        dimensions: '35x40mm',
        background: 'White',
        value: 'UAE',
        label: 'UAE',
    },
    {
        country: 'Algeria',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Algeria",
        value:"Algeria"
    },
    {
        country: 'Algeria',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Algeria",
        value:"Algeria"
    },
    {
        country: 'Australia',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Australia",
        value:"Australia"
    },
    {
        country: 'Australia',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Australia",
        value:"Australia"
    },
    {
        country: 'Austria',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Austria",
        value:"Austria"
    },
    {
        country: 'Austria',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Austria",
        value:"Austria"
    },
    {
        country: 'Bangladesh',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Bangladesh",
        value:"Bangladesh"
    },
    {
        country: 'Bangladesh',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Bangladesh",
        value:"Bangladesh"
    },
    {
        country: 'Belarus',
        type: 'Passport',
        dimensions: '40x50mm',
        background: 'White',
        label:"Belarus",
        value:"Belarus"
    },
    {
        country: 'Belarus',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Belarus",
        value:"Belarus"
    },
    {
        country: 'Belgium',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Belgium",
        value:"Belgium"
    },
    {
        country: 'Belgium',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Belgium",
        value:"Belgium"
    },
    {
        country: 'Brazil',
        type: 'Passport',
        dimensions: '30x40mm',
        background: 'White',
        label:"Brazil",
        value:"Brazil"
    },
    {
        country: 'Brazil',
        type: 'Visa',
        dimensions: '30x40mm',
        background: 'White',
        label:"Brazil",
        value:"Brazil"
    },
    {
        country: 'Canada',
        type: 'Passport',
        dimensions: '50x70mm',
        background: 'White',
        label:"Canada",
        value:"Canada"
    },
    {
        country: 'Canada',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Canada",
        value:"Canada"
    },
    {
        country: 'China',
        type: 'Passport',
        dimensions: '33x48mm',
        background: 'White',
        label:"China",
        value:"China"
    },
    {
        country: 'China',
        type: 'Visa',
        dimensions: '33x48mm',
        background: 'White',
        label:"China",
        value:"China"
    },
    {
        country: 'Columbia',
        type: 'Passport',
        dimensions: '51x51mm',
        background: 'White',
        label:"Columbia",
        value:"Columbia"
    },
    {
        country: 'Columbia',
        type: 'Visa',
        dimensions: '51x51mm',
        background: 'White',
        label:"Columbia",
        value:"Columbia"
    },
    {
        country: 'Croatia',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Croatia",
        value:"Croatia"
    },
    {
        country: 'Croatia',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Croatia",
        value:"Croatia"
    },
    {
        country: 'Cyprus',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Cyprus",
        value:"Cyprus"
    },
    {
        country: 'Cyprus',
        type: 'Visa',
        dimensions: '40x50mm',
        background: 'White',
        label:"Cyprus",
        value:"Cyprus"
    },
    {
        country: 'Czech Republic',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Czech Republic",
        value:"Czech Republic"
    },
    {
        country: 'Czech Republic',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Czech Republic",
        value:"Czech Republic"
    },
    {
        country: 'Denmark',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Denmark",
        value:"Denmark"
    },
    {
        country: 'Denmark',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Denmark",
        value:"Denmark"
    },
    {
        country: 'Egypt',
        type: 'Passport',
        dimensions: '40x60mm',
        background: 'White',
        label:"Egypt",
        value:"Egypt"
    },
    {
        country: 'Egypt',
        type: 'Visa',
        dimensions: '40x60mm',
        background: 'White',
        label:"Egypt",
        value:"Egypt"
    },
    {
        country: 'France',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'Grey',
        label:"France",
        value:"France"
    },
    {
        country: 'France',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"France",
        value:"France"
    },
    {
        country: 'Germany',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Germany",
        value:"Germany"
    },
    {
        country: 'Germany',
        type: 'Visa',
        dimensions: '35x40mm',
        background: 'White',
        label:"Germany",
        value:"Germany"
    },
    {
        country: 'Iceland',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Iceland",
        value:"Iceland"
    },
    {
        country: 'Iceland',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Iceland",
        value:"Iceland"
    },
    {
        country: 'Indian',
        type: 'Passport',
        dimensions: '51x51mm',
        background: 'White',
        label:"Indian",
        value:"Indian"
    },
    {
        country: 'Indian',
        type: 'Visa',
        dimensions: '51x51mm',
        background: 'White',
        label:"Indian",
        value:"Indian"
    },
    {
        country: 'Ireland',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Ireland",
        value:"Ireland"
    },
    {
        country: 'Ireland',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Ireland",
        value:"Ireland"
    },
    {
        country: 'Italy',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Italy",
        value:"Italy"
    },
    {
        country: 'Italy',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Italy",
        value:"Italy"
    },
    {
        country: 'Japan',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Japan",
        value:"Japan"
    },
    {
        country: 'Japan',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Japan",
        value:"Japan"
    },
    {
        country: 'Jordan',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Jordan",
        value:"Jordan"
    },
    {
        country: 'Jordan',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Jordan",
        value:"Jordan"
    },
    {
        country: 'Lebanon',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Lebanon",
        value:"Lebanon"
    },
    {
        country: 'Lebanon',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Lebanon",
        value:"Lebanon"
    },
    {
        country: 'Luxembourg',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Luxembourg",
        value:"Luxembourg"
    },
    {
        country: 'Luxembourg',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Luxembourg",
        value:"Luxembourg"
    },
    {
        country: 'Malaysia',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Malaysia",
        value:"Malaysia"
    },
    {
        country: 'Malaysia',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Malaysia",
        value:"Malaysia"
    },
    {
        country: 'Netherlands',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Netherlands",
        value:"Netherlands"
    },
    {
        country: 'Netherlands',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Netherlands",
        value:"Netherlands"
    },
    {
        country: 'New Zealand',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'Grey',
        label:"New Zealand",
        value:"New Zealand"
    },
    {
        country: 'New Zealand',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'Grey',
        label:"New Zealand",
        value:"New Zealand"
    },
    {
        country: 'Nigeria',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Nigeria",
        value:"Nigeria"
    },
    {
        country: 'Nigeria',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Nigeria",
        value:"Nigeria"
    },
    {
        country: 'Pakistan',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Pakistan",
        value:"Pakistan"
    },
    {
        country: 'Pakistan',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Pakistan",
        value:"Pakistan"
    },
    {
        country: 'Palestine',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'Blue',
        label:"Palestine",
        value:"Palestine"
    },
    {
        country: 'Palestine',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'Blue',
        label:"Palestine",
        value:"Palestine"
    },
    {
        country: 'Philippines',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Philippines",
        value:"Philippines"
    },
    {
        country: 'Philippines',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Philippines",
        value:"Philippines"
    },
    {
        country: 'Portugal',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Portugal",
        value:"Portugal"
    },
    {
        country: 'Portugal',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Portugal",
        value:"Portugal"
    },
    {
        country: 'Qatar',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Qatar",
        value:"Qatar"
    },
    {
        country: 'Qatar',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Qatar",
        value:"Qatar"
    },
    {
        country: 'Romania',
        type: 'Passport',
        dimensions: '30x40mm + 7mm white box below photo',
        background: 'White',
        label:"Romania",
        value:"Romania"
    },
    {
        country: 'Romania',
        type: 'Visa',
        dimensions: '30x40mm + 7mm white box below photo',
        background: 'White',
        label:"Romania",
        value:"Romania"
    },
    {
        country: 'Russia',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Russia",
        value:"Russia"
    },
    {
        country: 'Russia',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Russia",
        value:"Russia"
    },
    {
        country: 'Saudi',
        type: 'Passport',
        dimensions: '51x51mm',
        background: 'White',
        label:"Saudi",
        value:"Saudi"
    },
    {
        country: 'Saudi',
        type: 'Visa',
        dimensions: '51x51mm',
        background: 'White',
        label:"Saudi",
        value:"Saudi"
    },
    {
        country: 'Slovakia',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Slovakia",
        value:"Slovakia"
    },
    {
        country: 'Slovakia',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Slovakia",
        value:"Slovakia"
    },
    {
        country: 'Slovenia',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Slovenia",
        value:"Slovenia"
    },
    {
        country: 'Slovenia',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Slovenia",
        value:"Slovenia"
    },
    {
        country: 'South Africa',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"South Africa",
        value:"South Africa"
    },
    {
        country: 'South Africa',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"South Africa",
        value:"South Africa"
    },
    {
        country: 'South Korea',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"South Korea",
        value:"South Korea"
    },
    {
        country: 'South Korea',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"South Korea",
        value:"South Korea"
    },
    {
        country: 'Spain',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Spain",
        value:"Spain"
    },
    {
        country: 'Spain',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Spain",
        value:"Spain"
    },
    {
        country: 'Sri Lanka',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Sri Lanka",
        value:"Sri Lanka"
    },
    {
        country: 'Sri Lanka',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Sri Lanka",
        value:"Sri Lanka"
    },
    {
        country: 'St. Kitts & Nevis',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"St. Kitts & Nevis",
        value:"St. Kitts & Nevis"
    },
    {
        country: 'St. Kitts & Nevis',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"St. Kitts & Nevis",
        value:"St. Kitts & Nevis"
    },
    {
        country: 'Sudan',
        type: 'Passport',
        dimensions: '40x50mm',
        background: 'White',
        label:"Sudan",
        value:"Sudan"
    },
    {
        country: 'Sudan',
        type: 'Visa',
        dimensions: '40x50mm',
        background: 'White',
        label:"Sudan",
        value:"Sudan"
    },
    {
        country: 'Switzerland',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Switzerland",
        value:"Switzerland"
    },
    {
        country: 'Switzerland',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Switzerland",
        value:"Switzerland"
    },
    {
        country: 'Syria',
        type: 'Passport',
        dimensions: '40x40mm',
        background: 'White',
        label:"Syria",
        value:"Syria"
    },
    {
        country: 'Syria',
        type: 'Visa',
        dimensions: '40x40mm',
        background: 'White',
        label:"Syria",
        value:"Syria"
    },
    {
        country: 'Tunisia',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Tunisia",
        value:"Tunisia"
    },
    {
        country: 'Tunisia',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Tunisia",
        value:"Tunisia"
    },
    {
        country: 'Turkey',
        type: 'Passport',
        dimensions: '50x60mm',
        background: 'White',
        label:"Turkey",
        value:"Turkey"
    },
    {
        country: 'Turkey',
        type: 'Visa',
        dimensions: '50x60mm',
        background: 'White',
        label:"Turkey",
        value:"Turkey"
    },
    {
        country: 'Ukraine',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"Ukraine",
        value:"Ukraine"
    },
    {
        country: 'Ukraine',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"Ukraine",
        value:"Ukraine"
    },
    {
        country: 'UK',
        type: 'Passport',
        dimensions: '35x45mm',
        background: 'White',
        label:"UK",
        value:"UK"
    },
    {
        country: 'UK',
        type: 'Visa',
        dimensions: '35x45mm',
        background: 'White',
        label:"UK",
        value:"UK"
    },
    {
        country: 'USA',
        type: 'Passport',
        dimensions: '51x51mm',
        background: 'White',
        label:"USA",
        value:"USA"
    },
    {
        country: 'USA',
        type: 'Visa',
        dimensions: '51x51mm',
        background: 'White',
        label:"USA",
        value:"USA"
    },
    
   
  ]

export const purposes = [
    {
        value: 'Other',
        label: 'Other',
    },{
        value: 'Stamp Size (20x25mm/White)',
        label: 'Stamp Size (20x25mm/White)'
    },{
        value: 'UAE School Photo (35x40mm/White)',
        label: 'UAE School Photo (35x40mm/White)',

    },{
        value: 'Emirates ID (35x40mm/White)',
        label: 'Emirates ID (35x40mm/White)',
    },{
        value: 'UAE Passport (35x40mm/White)',
        label: 'UAE Passport (35x40mm/White)',
    },
    {
        value: 'UAE Visa (35x40mm/White)',
        label: 'UAE Visa (35x40mm/White)',
    },
    {  
        value: 'Algeria Passport (35x45mm/White)',
        label: 'Algeria Passport (35x45mm/White)',
    },
    {  
        value: 'Algeria Visa (35x45mm/White)',
        label: 'Algeria Visa (35x45mm/White)',
    },
    {
        value: 'Australia Passport (35x45mm/White)',
        label: 'Australia Passport (35x45mm/White)',
    },
    {
        value: 'Australia Visa (35x45mm/White)',    
        label: 'Australia Visa (35x45mm/White)',
    },
    {
        value: 'Austria Passport (35x45mm/White)',    
        label: 'Austria Passport (35x45mm/White)',
    },
    {
        value: 'Austria Visa (35x45mm/White)',    
        label: 'Austria Visa (35x45mm/White)',
    },
    {
        value: 'Bangladesh Passport (35x45mm/White)',    
        label: 'Bangladesh Passport (35x45mm/White)',
    },
    {
        value: 'Bangladesh Visa (35x45mm/White)',    
        label: 'Bangladesh Visa (35x45mm/White)',
    },
    {
        value: 'Belarus Passport (40x50mm/White)',    
        label: 'Belarus Passport (40x50mm/White)',
    },
    {
        value: 'Belarus Visa (35x45mm/White)',    
        label: 'Belarus Visa (35x45mm/White)',
    },
    {
        value: 'Belgium Passport (35x45mm/White)',    
        label: 'Belgium Passport (35x45mm/White)',
    },
    {
        value: 'Belgium Visa (35x45mm/White)',    
        label: 'Belgium Visa (35x45mm/White)',
    },
    {
        value: 'Brazil Passport (30x40mm/White)',    
        label: 'Brazil Passport (30x40mm/White)',
    },
    {
        value: 'Brazil Visa (30x40mm/White)',    
        label: 'Brazil Visa (30x40mm/White)',
    },
    {
        value: 'Canada Passport (50x70mm/White)',    
        label: 'Canada Passport (50x70mm/White)',
    },
    {
        value: 'Canada Visa (35x45mm/White)',    
        label: 'Canada Visa (35x45mm/White)',
    },
    {
        value: 'China Passport (33x48mm/White)',    
        label: 'China Passport (33x48mm/White)',
    },
    {
        value: 'China Visa (33x48mm/White)',    
        label: 'China Visa (33x48mm/White)',
    },
    {
        value: 'Columbia Passport (51x51mm/White)',    
        label: 'Columbia Passport (51x51mm/White)',
    },
    {
        value: 'Columbia Visa (51x51mm/White)',    
        label: 'Columbia Visa (51x51mm/White)',
    },
    {
        value: 'Croatia Passport (35x45mm/White)',    
        label: 'Croatia Passport (35x45mm/White)',
    },
    {
        value: 'Croatia Visa (35x45mm/White)',    
        label: 'Croatia Visa (35x45mm/White)',
    },
    {
        value: 'Cyprus Passport (35x45mm/White)',    
        label: 'Cyprus Passport (35x45mm/White)',
    },
    {
        value: 'Cyprus Visa (40x50mm/White)',    
        label: 'Cyprus Visa (40x50mm/White)',
    },
    {
        value: 'Czech Republic Passport (35x45mm/White)',    
        label: 'Czech Republic Passport (35x45mm/White)',
    },
    {
        value: 'Czech Republic Visa (35x45mm/White)',    
        label: 'Czech Republic Visa (35x45mm/White)',
    },
    {
        value: 'Denmark Passport (35x45mm/White)',    
        label: 'Denmark Passport (35x45mm/White)',
    },
    {
        value: 'Denmark Visa (35x45mm/White)',    
        label: 'Denmark Visa (35x45mm/White)',
    },
    {
        value: 'Egypt Passport (40x60mm/White)',    
        label: 'Egypt Passport (40x60mm/White)',
    },
    {
        value: 'Egypt Visa (40x60mm/White)',    
        label: 'Egypt Visa (40x60mm/White)',
    },
    {
        value: 'France Passport (35x45mm/Grey)',    
        label: 'France Passport (35x45mm/Grey)',
    },
    {
        value: 'France Visa (35x45mm/White)',    
        label: 'France Visa (35x45mm/White)',
    },
    {
        value: 'Germany Passport (35x45mm/White)',    
        label: 'Germany Passport (35x45mm/White)',
    },
    {
        value: 'Germany Visa (35x40mm/White)',    
        label: 'Germany Visa (35x40mm/White)',
    },
    {
        value: 'Iceland Passport (35x45mm/White)',    
        label: 'Iceland Passport (35x45mm/White)',
    },
    {
        value: 'Iceland Visa (35x45mm/White),Indian',    
        label: 'Iceland Visa (35x45mm/White),Indian',
    }, 
    {
        value: 'Passport (51x51mm/White)',    
        label: 'Passport (51x51mm/White)',
    },
    {
        value: 'Indian Visa (51x51mm/White)',    
        label: 'Indian Visa (51x51mm/White)',
    },
    {
        value: 'Ireland Passport (35x45mm/White)',    
        label: 'Ireland Passport (35x45mm/White)',
    },
    {
        value: 'Ireland Visa (35x45mm/White)',    
        label: 'Ireland Visa (35x45mm/White)',
    },
    {
        value: 'Italy Passport (35x45mm/White)',    
        label: 'Italy Passport (35x45mm/White)',
    },
    {
        value: 'Italy Visa (35x45mm/White)',    
        label: 'Italy Visa (35x45mm/White)',
    },
    {
        value: 'Japan Passport (35x45mm/White)',    
        label: 'Japan Passport (35x45mm/White)',
    },
    {
        value: 'Japan Visa (35x45mm/White)',    
        label: 'Japan Visa (35x45mm/White)',
    },
    {
        value: 'Jordan Passport (35x45mm/White)',    
        label: 'Jordan Passport (35x45mm/White)',
    },
    {
        value: 'Jordan Visa (35x45mm/White)',    
        label: 'Jordan Visa (35x45mm/White)',
    },
    {
        value: 'Lebanon Passport (35x45mm/White)',    
        label: 'Lebanon Passport (35x45mm/White)',
    },
    {
        value: 'Lebanon Visa (35x45mm/White)',    
        label: 'Lebanon Visa (35x45mm/White)',
    },
    {
        value: 'Luxembourg Passport (35x45mm/White)',    
        label: 'Luxembourg Passport (35x45mm/White)',
    },
    {
        value: 'Luxembourg Visa (35x45mm/White)',    
        label: 'Luxembourg Visa (35x45mm/White)',
    },
    {
        value: 'MMalaysia Passport (35x45mm/White)',    
        label: 'MMalaysia Passport (35x45mm/White)',
    },
    {
        value: 'Malaysia Visa (35x45mm/White)',    
        label: 'Malaysia Visa (35x45mm/White)',
    },
    {
        value: 'Netherlands Passport (35x45mm/White)',    
        label: 'Netherlands Passport (35x45mm/White)',
    },
    {
        value: 'Netherlands Visa (35x45mm/White)',    
        label: 'Netherlands Visa (35x45mm/White)',
    },
    {
        value: 'New Zealand  Passport (35x45mm/Grey)',    
        label: 'New Zealand  Passport (35x45mm/Grey)',
    },
    {
        value: 'New Zealand  Visa (35x45mm/Grey)',    
        label: 'New Zealand  Visa (35x45mm/Grey)',
    },
    {
        value: 'Nigeria Passport (35x45mm/White)',    
        label: 'Nigeria Passport (35x45mm/White)',
    },
    {
        value: 'Nigeria Visa (35x45mm/White)',    
        label: 'Nigeria Visa (35x45mm/White)',
    },
    {
        value: 'Pakistan Passport (35x45mm/White)',    
        label: 'Pakistan Passport (35x45mm/White)',
    },
    {
        value: 'Pakistan Visa (35x45mm/White)',    
        label: 'Pakistan Visa (35x45mm/White)',
    },
    {
        value: 'Palestine Passport (35x45mm/Blue)',    
        label: 'Palestine Passport (35x45mm/Blue)',
    },
    {
        value: 'Palestine Visa (35x45mm/Blue)',    
        label: 'Palestine Visa (35x45mm/Blue)',
    },
    {
        value: 'Philippines Passport (35x45mm/White)',    
        label: 'Philippines Passport (35x45mm/White)',
    },
    {
        value: 'Philippines Visa (35x45mm/White)',    
        label: 'Philippines Visa (35x45mm/White)',
    },
    {
        value: 'Portugal Passport (35x45mm/White)',    
        label: 'Portugal Passport (35x45mm/White)',
    },
    {
        value: 'Portugal Visa (35x45mm/White)',    
        label: 'Portugal Visa (35x45mm/White)',
    },
    {
        value: 'Qatar Passport (35x45mm/White)',    
        label: 'Qatar Passport (35x45mm/White)',
    },
    {
        value: 'Qatar Visa (35x45mm/White)',    
        label: 'Qatar Visa (35x45mm/White)',
    },
    {
        value: 'Romania Passport (30x40mm/White) + 7mm white box below photo',    
        label: 'Romania Passport (30x40mm/White) + 7mm white box below photo',
    },
    {
        value: 'Romania Visa (30x40mm/White) + 7mm white box below photo',    
        label: 'Romania Visa (30x40mm/White) + 7mm white box below photo',
    },
    {
        value: 'Russia Passport (35x45mm/White)',    
        label: 'Russia Passport (35x45mm/White)',
    },
    {
        value: 'Russia Visa (35x45mm/White)',    
        label: 'Russia Visa (35x45mm/White)',
    },
    {
        value: 'Saudi Passport (51x51mm/White)',    
        label: 'Saudi Passport (51x51mm/White)',
    },
    {
        value: 'Saudi Visa (51x51mm/White)',    
        label: 'Saudi Visa (51x51mm/White)',
    },
    {
        value: 'Slovakia Passport (35x45mm/White)',    
        label: 'Slovakia Passport (35x45mm/White)',
    },
    {
        value: 'Slovakia Visa (35x45mm/White)',    
        label: 'Slovakia Visa (35x45mm/White)',
    },
    {
        value: 'Slovenia Passport (35x45mm/White)',    
        label: 'Slovenia Passport (35x45mm/White)',
    },
    {
        value: 'Slovenia Visa (35x45mm/White)',    
        label: 'Slovenia Visa (35x45mm/White)',
    },
    {
        value: 'South Africa Passport (35x45mm/White)',    
        label: 'South Africa Passport (35x45mm/White)',
    },
    {
        value: 'South Africa Visa (35x45mm/White)',    
        label: 'South Africa Visa (35x45mm/White)',
    },
    {
        value: 'South Korea Passport (35x45mm/White)',    
        label: 'South Korea Passport (35x45mm/White)',
    },
    {
        value: 'South Korea Visa (35x45mm/White)',    
        label: 'South Korea Visa (35x45mm/White)',
    },
    {
        value: 'Spain Passport (35x45mm/White)',    
        label: 'Spain Passport (35x45mm/White)',
    },
    {
        value: 'Spain Visa (35x45mm/White)',    
        label: 'Spain Visa (35x45mm/White)',
    },
    {
        value: 'Sri Lanka Passport (35x45mm/White)',    
        label: 'Sri Lanka Passport (35x45mm/White)',
    },
    {
        value: 'Sri Lanka Visa (35x45mm/White)',    
        label: 'Sri Lanka Visa (35x45mm/White)',
    },
    {
        value: 'St. Kitts & Nevis Passport (35x45mm/White)',    
        label: 'St. Kitts & Nevis Passport (35x45mm/White)',
    },
    {
        value: 'St. Kitts & Nevis Visa (35x45mm/White)',    
        label: 'St. Kitts & Nevis Visa (35x45mm/White)',
    },
    {
        value: 'Sudan Passport (40x50mm/White)',    
        label: 'Sudan Passport (40x50mm/White)',
    },
    {
        value: 'Sudan Visa (40x50mm/White)',    
        label: 'Sudan Visa (40x50mm/White)',
    },
    {
        value: 'Switzerland Passport (35x45mm/White)',    
        label: 'Switzerland Passport (35x45mm/White)',
    },
    {
        value: 'Switzerland Visa (35x45mm/White)',    
        label: 'Switzerland Visa (35x45mm/White)',
    },
    {
        value: 'Syria Passport (40x40mm/White)',    
        label: 'Syria Passport (40x40mm/White)',
    },
    {
        value: 'Syria Visa (40x40mm/White)',    
        label: 'Syria Visa (40x40mm/White)',
    },
    {
        value: 'Tunisia Passport (35x45mm/White)',    
        label: 'Tunisia Passport (35x45mm/White)',
    },
    {
        value: 'Tunisia Visa (35x45mm/White)',    
        label: 'Tunisia Visa (35x45mm/White)',
    },
    {
        value: 'Turkey Passport (50x60mm/White)',    
        label: 'Turkey Passport (50x60mm/White)',
    },
    {
        value: 'Turkey Visa (50x60mm/White)',    
        label: 'Turkey Visa (50x60mm/White)',
    },
    {
        value: 'Ukraine Passport (35x45mm/White)',    
        label: 'Ukraine Passport (35x45mm/White)',
    },
    {
        value: 'Ukraine Visa (35x45mm/White)',    
        label: 'Ukraine Visa (35x45mm/White)',
    },
    {
        value: 'UK Passport (35x45mm/White)',    
        label: 'UK Passport (35x45mm/White)',
    },
    {
        value: 'UK Visa (35x45mm/White)',    
        label: 'UK Visa (35x45mm/White)',
    },
    {
        value: 'USA Passport (51x51mm/White)',    
        label: 'USA Passport (51x51mm/White)',
    },
    {
        value: 'USA Visa (51x51mm/White)',
        label: 'USA Visa (51x51mm/White)'
    }
];
export const areas = [

    {
        name: "Other Area Dubai",
        set: 3
    },

    {
        name: "Al Barari",
        set: 2
    },

    {
        name: "Al Barsha 1",
        set: 1
    },

    {
        name: "Al Barsha 2",
        set: 1
    },

    {
        name: "Al Barsha 3",
        set: 1
    },

    {
        name: "Al Barsha South/Arjan",
        set: 1
    },

    {
        name: "Al Furjan",
        set: 2
    },

    {
        name: "Al Habtoor City",
        set: 3
    },

    {
        name: "Al Hamriya",
        set: 3
    },

    {
        name: "Al Hubaida",
        set: 3
    },

    {
        name: "Al Jaddaf",
        set: 3
    },

    {
        name: "Al Khawaneej",
        set: 3
    },

    {
        name: "Al Manara",
        set: 3
    },

    {
        name: "Al Qouz",
        set: 2
    },

    {
        name: "Al Qouz 1",
        set: 2
    },

    {
        name: "Al Qouz 2",
        set: 2
    },

    {
        name: "Al Qouz 3",
        set: 2
    },

    {
        name: "Al Qusais 1",
        set: 3
    },

    {
        name: "Al Qusais 2",
        set: 3
    },

    {
        name: "Al Qusais 3",
        set: 3
    },

    {
        name: "Al Raffa",
        set: 3
    },

    {
        name: "Al Rashidiya",
        set: 3
    },

    {
        name: "Al Safa",
        set: 2
    },

    {
        name: "Al Safa 2",
        set: 2
    },

    {
        name: "Al Sufouh",
        set: 2
    },

    {
        name: "Al Sufouh 2",
        set: 2
    },

    {
        name: "Al Wasl",
        set: 2
    },

    {
        name: "Arabian Ranches",
        set: 2
    },

    {
        name: "Arabian Ranches 2",
        set: 2
    },

    {
        name: "Barsha Heights/Tecom",
        set: 1
    },

    {
        name: "Bluewaters Island",
        set: 3
    },

    {
        name: "Business Bay",
        set: 2
    },

    {
        name: "City Walk",
        set: 2
    },

    {
        name: "Damaac Hills",
        set: 2
    },

    {
        name: "Damaac Hills 2",
        set: 3
    },

    {
        name: "Deira",
        set: 3
    },

    {
        name: "DIFC",
        set: 3
    },

    {
        name: "Discovery Gardens",
        set: 1
    },

    {
        name: "Downtown",
        set: 2
    },

    {
        name: "Dubai Creek Harbour",
        set: 2
    },

    {
        name: "Dubai Design Destrict",
        set: 2
    },

    {
        name: "Dubai Festival City",
        set: 3
    },

    {
        name: "Dubai Healthcare City",
        set: 2
    },

    {
        name: "Dubai Hills",
        set: 1
    },

    {
        name: "Dubai Investment Park",
        set: 2
    },

    {
        name: "Dubai Marina",
        set: 3
    },

    {
        name: "Dubai Media City",
        set: 2
    },

    {
        name: "Dubai Production City/IMPZ",
        set: 1
    },


    {
        name: "Dubai Silicon Oasis",
        set: 2
    },

    {
        name: "Dubai Sports City",
        set: 1
    },

    {
        name: "Dubai Studio City",
        set: 1
    },

    {
        name: "Emirates Hills",
        set: 2
    },

    {
        name: "Garhoud",
        set: 3
    },

    {
        name: "International City",
        set: 3
    },

    {
        name: "Jumeirah 1",
        set: 2
    },

    {
        name: "Jumeirah 2",
        set: 2
    },

    {
        name: "Jumeirah 3",
        set: 2
    },

    {
        name: "Jumeirah Beach Residence (JBR)",
        set: 2
    },

    {
        name: "Jumeirah Golf Estates",
        set: 1
    },

    {
        name: "Jumeirah Hights",
        set: 2
    },

    {
        name: "Jumeirah Lakes Towers (JLT)",
        set: 2
    },

    {
        name: "Jumeirah Park",
        set: 2
    },

    {
        name: "Jumeirah Village Circle (JVC)",
        set: 1
    },

    {
        name: "Jumeirah Village Triangle (JVT)",
        set: 1
    },

    {
        name: "Layan Community",
        set: 2
    },

    {
        name: "Living Legends",
        set: 2
    },

    {
        name: "Majan",
        set: 2
    },

    {
        name: "Mira Oasis",
        set: 2
    },

    {
        name: "Mira Oasis 3",
        set: 2
    },

    {
        name: "Mirdif",
        set: 2
    },

    {
        name: "Motor City",
        set: 1
    },

    {
        name: "Mudon",
        set: 1
    },

    {
        name: "Nad Al Sheba",
        set: 2
    },

    {
        name: "Nad Al Sheba 1",
        set: 2
    },

    {
        name: "Oud Al Muteena",
        set: 3
    },

    {
        name: "Oud Metha",
        set: 3
    },

    {
        name: "Ras Al Khor",
        set: 2
    },

    {
        name: "Remraam",
        set: 1
    },

    {
        name: "Serena Casa Dora",
        set: 2
    },

    {
        name: "Serena Community",
        set: 2
    },

    {
        name: "Springs",
        set: 2
    },

    {
        name: "Springs 7",
        set: 2
    },

    {
        name: "The Gardens",
        set: 3
    },

    {
        name: "The Greens",
        set: 1
    },

    {
        name: "The Lakes",
        set: 2
    },

    {
        name: "The Meadows",
        set: 2
    },

    {
        name: "The Palm Jumeirah",
        set: 3
    },

    {
        name: "The Villa",
        set: 3
    },

    {
        name: "Town Square",
        set: 2
    },

    {
        name: "Trade Centre",
        set: 3
    },

    {
        name: "Trade Centre 1",
        set: 3
    },

    {
        name: "Umm Suqeim 1",
        set: 2
    },

    {
        name: "Umm Suqeim 2",
        set: 2
    },

    {
        name: "Umm Suqeim 3",
        set: 2
    },

];

export const countryList = [
	{
	  name: "Algeria",
	  value: "Algeria"
	},
	{
	  name: "Australia",
	  value: "Australia"
	},
	{
	  name: "Austria",
	  value: "Austria"
	},
	{
	  name: "Bangladesh",
	  value: "Bangladesh"
	},
	{
	  name: "Belarus",
	  value: "Belarus"
	},
	{
	  name: "Belgium",
	  value: "Belgium"
	},
	{
	  name: "Brazil",
	  value: "Brazil"
	},
	{
	  name: "Canada",
	  value: "canada"
	},
	{
	  name: "China",
	  value: "China"
	},
	{
	  name: "Columbia",
	  value: "Columbia"
	},
	{
	  name: "Croatia",
	  value: "Croatia"
	},
	{
	  name: "Cyprus",
	  value: "Cyprus"
	},
	{
	  name: "Czech Republic",
	  value: "Czech Republic"
	},
	{
	  name: "Denmark",
	  value: "Denmark"
	},
	{
	  name: "Egypt",
	  value: "Egypt"
	},
	{
	  name: "France",
	  value: "France"
	},
	{
	  name: "Germany",
	  value: "Germany"
	},
	{
	  name: "Iceland",
	  value: "Iceland"
	},
	{
	  name: "India",
	  value: "india"
	},
	{
	  name: "Ireland",
	  value: "Ireland"
	},
	{
	  name: "Italy",
	  value: "italy"
	},
	{
	  name: "Japan",
	  value: "Japan"
	},
	{
	  name: "Jordan",
	  value: "Jordan"
	},
	{
	  name: "Lebanon",
	  value: "Lebanon"
	},
	{
	  name: "Luxembourg",
	  value: "Luxembourg"
	},
	{
	  name: "Malaysia",
	  value: "Malaysia"
	},
	{
	  name: "Netherlands",
	  value: "Netherlands"
	},
	{
	  name: "New Zealand",
	  value: "New Zealand"
	},
	{
	  name: "Nigeria",
	  value: "Nigeria"
	},
	{
	  name: "Pakistan",
	  value: "Pakistan"
	},
	{
	  name: "Palestine",
	  value: "Palestine"
	},
	{
	  name: "Philippines",
	  value: "Philippines"
	},
	{
	  name: "Portugal",
	  value: "Portugal"
	},
	{
	  name: "Qatar",
	  value: "Qatar"
	},
	{
	  name: "Romania",
	  value: "Romania"
	},
	{
	  name: "Russia",
	  value: "Russia"
	},
	{
	  name: "Saudi",
	  value: "Saudi"
	},
	{
	  name: "Slovakia",
	  value: "Slovakia"
	},
	{
	  name: "Slovenia",
	  value: "Slovenia"
	},
	{
	  name: "South Africa",
	  value: "South Africa"
	},
	{
	  name: "South Korea",
	  value: "South Korea"
	},
	{
	  name: "Spain",
	  value: "Spain"
	},
	{
	  name: "Sri Lanka",
	  value: "Sri Lanka"
	},
	{
	  name: "St. Kitts & Nevis",
	  value: "St. Kitts & Nevis"
	},
	{
	  name: "Sudan",
	  value: "Sudan"
	},
	{
	  name: "Switzerland",
	  value: "Switzerland"
	},
	{
	  name: "Syria",
	  value: "Syria"
	},
	{
	  name: "Tunisia",
	  value: "Tunisia"
	},
	{
	  name: "Turkey",
	  value: "Turkey"
	},
	{
	  name: "Ukraine",
	  value: "Ukraine"
	},
	{
	  name: "United Arab Emirates",
	  value: "uae"
	},
	{
	  name: "United Kingdom",
	  value: "uk"
	},
	{
	  name: "USA",
	  value: "usa"
	},
  ];

export const getAreaSet = function(areaName) {
    var set = 0;
    areas.find(obj => {
        if (obj.name == areaName) {
            set = obj.set;
            return;
        }
    })

    return set;
}

export const toggleAccordian = function(event, index,data,setData) {
    const element = event.target;
    element.classList.toggle("active");
    if (data[index].isActive) {
        const newData = data.map((item,i)=>{
            if(i == index){
                return {...item,isActive: false}
            }else{
                return item;
            }
        })
        setData(newData)
    } else {
        const newData = data.map((item,i)=>{
            if(i == index){
                return {...item,isActive: true}
            }else{
                return item;
            }
        })
        setData(newData)
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

export const scroll = function(elem) {
    if(elem) elem.scrollIntoView({ behavior: "smooth" });
  }