import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PageLoader from "../../components/Loader/PageLoader";
import ProductDescription from "./ProductDescription";
import ProductInfo from "./ProductInfo";
import RecommendedProducts from "./RecommendedProducts";
import { Helmet } from "react-helmet-async";
const ProductPage = () => {
  const {handle} = useParams()
  const [product,setProduct] = useState()
  const [isLoading,setIsLoading] = useState(true)

  useEffect(()=>{
    setIsLoading(true)
      const getProduct =async ()=>{
          const {data} = await axios.get(process.env.REACT_APP_API_URL + '/api/assets/products/'+handle);
          const {product} = data
          setProduct(product)
          setIsLoading(false)
      }
      getProduct()
      window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
      });
  },[handle])

  return (
   <div>
    {isLoading ? (<PageLoader/> ) : (
      <>
        <Helmet>
          <title>{product?.page_title}</title>
          <meta name="description" content={product?.meta_description} />
        </Helmet>
        <ProductDescription product={product}/>
        <ProductInfo product={product}/>
      </>
    )}
    <RecommendedProducts/>
   </div>
  );
};

export default ProductPage;
