import React from 'react'
import dacumentDubai1 from '../../images/images/documets-printing/documents-printing-delivery-dubai.jpg';
import DacumentPrintingForm from './DcumentPrintingForm/DacumentPrintingForm';
import "./styles.scss"

const Banner = () => {
  return (
    <div className='wrapper-dacument-container'>
      <div class="document-container">
    <h2 class="heading-h2">Documents Printing & Delivery</h2>

    <div class="container">
        <div class="inner-container">
            {/* <div  class="img-container">
                <img src={dacumentDubai1} alt="img"/>
            </div> */}
            <div class="p-container">
                <p>If you want quick and easy printing of your A4 documents, delivered to your doorstep, simply use our
                    form
                    below, upload your documents and pay using your credit or debit card.
                </p>
                <p>
                    We’ll deliver same day to you at a location of your choosing, with high quality printing and
                    guaranteed
                    satisfaction.
                </p>
                <p>
                    Kindle note that the minimum order value is <b>40 dirhams only</b>
                </p>
            </div>
            <div  class="img-container">
                <img src={dacumentDubai1} alt="img"/>
            </div>
        </div>
    </div>

    <div className="container margin-bottom" >
      <div className="formControll">
        <h2 className="headingSizeChart playfair-heading-800">
          {" "}
          Documents Printing Prices
        </h2>
      </div>

      <div className="photoPrintingSizesChart">
        <table className="photoSize content">
          <tr>
            <th>Type of print</th>
            <th>Price (AED)</th>
          </tr>

            <tr>
                <td>{'Black & White Print'}</td>
                <td>{'2'}</td>
            </tr>
            <tr>
                <td>{'Color Print'}</td>
                <td>{'3'}</td>
            </tr>
        </table>
      </div>
    </div>


    <DacumentPrintingForm/>
    {/* <app-documents-printing-form></app-documents-printing-form> */}
</div>
    </div>
  )
}

export default Banner