import React from "react";
import "./styleCounter.scss";
const Counter = ({quantity, setQuantity}) => {

  function handleChangeQuantity(e,type){
    e.preventDefault()
    if(type == 'plus'){
      setQuantity(parseInt(quantity) + 1)
    }else if(type == 'minus'){
      if((parseInt(quantity) - 1) < 1) return;
      setQuantity(parseInt(quantity) - 1)
    }
  }
  return (
    
      <div class="quantity pr mr__10 qty__true quantity-wrapper">
        <input
          type="number"
          class="input-text qty text tc qty_cart_js"
          step="1"
          min="0"
          max="9999"
          value={quantity}
          size="4"
          pattern="[0-9]*"
          inputmode="numeric"
          onChange={(e)=>setQuantity(e.currentTarget.value)}
        />
        <div class="qty tc fs__14">
          <button type="button" class="plus db cb pa pd__0 pr__15 tr r__0" onClick={(e)=>handleChangeQuantity(e,'plus')}>
            <i class="facl facl-plus"></i>
          </button>
          <button
            type="button"
            onClick={(e)=>handleChangeQuantity(e,'minus')}
            class="minus db cb pa pd__0 pl__15 tl l__0 qty_1"
          >
            <svg class="dn" viewBox="0 0 24 24">
              {/* <use xlink:href="#scl_remove"></use> */}
            </svg>
            <i class="facl facl-minus"></i>
          </button>
        </div>
      </div>
   
  );
};

export default Counter;
