import React from 'react'
import './PageLoader.scss'

function PageLoader() {
  return (
    <div className='page-loader'>
        <div className='page-loader--inner'></div>
    </div>
  )
}

export default PageLoader