import React from 'react'
import "./Banner.scss"


const Banner = () => {
  return (
    <div className='banner-container-collections'>
        <h2>Collections</h2>
    </div>
  )
}

export default Banner