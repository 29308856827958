import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import CardSection from './CardSection'
import Banner from './logoDesignComponent/Banner'
import BrandTaxt from './logoDesignComponent/BrandTaxt'
import LogoIcons from './logoDesignComponent/LogoIcons'
import WorkSmaple from './logoDesignComponent/WorkSmaple'


const LogoDesign = () => {
  useEffect(()=>{
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  },[])
  return (
    <div>
        <Helmet>
          <title>Custom Logo Designing Dubai UAE – Ozelu Graphic Designers</title>
          <meta name='description' content='If you need a unique logo for your company or start up, Ozelu offers the best custom logo designs in Dubai, UAE, at affordable prices and in-depth focus. Give us a call for a free consultation.' />
        </Helmet>
        <Banner/>
        <LogoIcons/>
        <BrandTaxt/>
        <WorkSmaple/>
        <CardSection/>
    </div>
  )
}

export default LogoDesign