import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Banner from './Banner'

const PhotoPrintingDelivery = () => {
  useEffect(()=>{
    window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
    });
  },[])
  return (
    <div>
      <Helmet>
        <title>Photo Printing with Same Day Free Delivery – High Quality and Online Payment</title>
        <meta name='description' content='Upload photos into our online form in the sizes you want to print and get it delivered to your location within 24 hours. High quality prints, very easy to use and online payment.' />
      </Helmet>
        <Banner/>
    </div>
  )
}


export default PhotoPrintingDelivery