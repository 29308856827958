import React, { useContext } from 'react'
import './footer.scss'
import fbIcon from '../images/images/facebook.svg';
import instaIcon from '../images/images/instagram.svg';
import whatsappIcon from '../images/images/whatsApp.svg';
import cartIcon from '../images/ecomerece/cart-icon.png';
import { Link } from 'react-router-dom';
import AppContext from '../store/AppContext';

import paymentMethod1 from "../images/images/passport-size-photos-delivery/payment-method-1.png";
import paymentMethod2 from "../images/images/passport-size-photos-delivery/payment-method-2.png";
import paymentMethod3 from "../images/images/passport-size-photos-delivery/payment-method-3.png";
import paymentMethod4 from "../images/images/passport-size-photos-delivery/payment-method-4.png";

function Footer() {
    const {servicesMenu,policiesMenu,setCartOpened} = useContext(AppContext)

    function handleCartOpen(e){
        e.preventDefault()
        setCartOpened(true)
    }
  return (
    <>
    <div className="footer">
        <div className="footerLeftContent">


            <div className="footerLeftIcons">
            <h2>
                <strong> Follow Us </strong>
            </h2>
            <div className="icons-container">
                <a href="https://www.facebook.com/ozelustudio" target="_blank" rel="noreferrer">
                    <img className="footerIcons" alt='facebook'
                    src={fbIcon} /></a>
                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/ozelustudio/">
                    <img className="footerIcons" alt='insta'
                    src={instaIcon} /></a>
            </div>


            <h2><strong> Contact Us </strong></h2>
            <div className="contact-info">
                <h4><strong> JVC Branch </strong></h4>
                <p>Jeewar Building, Jumeirah Village Circle, Dubai</p>
                <p><a href="tel:+971545679479">0545679479</a></p>
                <p>10:00AM - 10:00PM</p>
                <p><a href="mailto:care@ozelu.com">care@ozelu.com</a></p>
                <p><a href="mailto:ozelu.studio21@gmail.com">ozelu.studio21@gmail.com</a></p>
            </div>
            </div>
        </div>
        <div className="footerLeftContent">
            <div className="info-2">
            <h4 style={{fontWeight:"900"}}>
                <strong > We deliver to locations like </strong>
            </h4>
            <p>Al Barsha 1, Dubai</p>
            <p>Marina, Dubai</p>
            <p>Al Barsha 3, Dubai</p>
            <p>Tecom, Dubai</p>
            <p>Motor City, Dubai</p>
            <p>Sports City, Dubai</p>
            <p>Mudon, Dubai</p>
            <p>Arjan, Dubai</p>
            <p>Dubai Investment Park, Dubai</p>
            <p>DownTown, Dubai</p>
            <p>Business Bay, Dubai</p>
            <p>Damaac Hills, Dubai</p>
            <p>And Other Areas, Dubai</p>
            </div>

        </div>
        <div className="footerLeftContent">
            <div className="info-2">
            {servicesMenu && (
                <>
                    <h4 style={{fontWeight:"900"}}>
                        <strong > Services </strong>
                    </h4>
                    {servicesMenu.children.map((item)=>{
                        return <p><Link to={item.url}>{item.title}</Link></p>
                    })}
                </> 
            )}
            {policiesMenu && (
                <>
                    <h4 style={{fontWeight:"900"}}>
                        <strong > Policies </strong>
                    </h4>
                    {policiesMenu.children.map((item)=>{
                        return <p><Link to={item.url}>{item.title}</Link></p>
                    })}
                </>
            )}
            </div>

        </div>
        <div className="footerRightContent">
            <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3614.2649539925687!2d55.20740371460065!3d25.059007383960633!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6ddf48d6881d%3A0xc1c9baa2802d0f39!2sOzelu%20Online%20Photo%20Studio!5e0!3m2!1sen!2s!4v1634460303182!5m2!1sen!2s"
            width="100%" height="100%" style={{border: 0}} title="map" allowFullScreen="" loading="lazy"></iframe>
        </div>
        <div className="whatsAppInformation">
            <a target="_blank" rel='noreferrer' href="https://wa.me/971545679479">
                <img src={whatsappIcon} alt='whatsapp'
                className="whatsAppIcon"/></a>
        </div>
        <div className="stickyCart">
            <a target="_blank" rel='noreferrer' href="#" onClick={handleCartOpen}>
                <img src={cartIcon} alt='whatsapp'
                className="stickyCartIcon"/></a>
        </div>
    </div>
    <div className='footer-rights container d-flex justify-content-between py-2'>
            <div><p className='m-0'>© 2023 Ozelu. All Rights Reserved</p></div>
            <div>
                <img src={paymentMethod1} alt={'paymentMethod1'}/>
                <img src={paymentMethod2} alt={'paymentMethod2'}/>
                <img src={paymentMethod3} alt={'paymentMethod3'}/>
                <img src={paymentMethod4} alt={'paymentMethod4'}/>
            </div>
    </div>
    </>
  )
}

export default Footer